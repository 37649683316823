import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, Row, Col, Cascader } from 'antd';
import api from '../../../utils/api';

interface MenuFormData {
  menuId?: number;
  name: string;
  code: string;
  parentId?: number;
  path?: string;
  component?: string;
  icon?: string;
  remark?: string;
  seqNo?: number;
  children?: MenuFormData[];
}

const AddMenuModal = ({ visible, onClose, menuData, parentId, onSuccess, menuList }: { visible: boolean; onClose: () => void; menuData?: MenuFormData | null; parentId?: number; onSuccess: () => void; menuList: MenuFormData[] }) => {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [menuOptions, setMenuOptions] = useState<MenuFormData[]>([]); // 存储菜单选项
  const [parentPath, setParentPath] = useState<number[]>([]); // 存储父菜单路径
  const findParentPath = useCallback((menus: MenuFormData[], id: number, path: number[] = []): boolean => {
    for (const menu of menus) {
      if (menu.menuId === id) {
        path.push(menu.menuId);
        return true;
      }
      if (menu.children && findParentPath(menu.children, id, path)) {
        path.unshift(menu.menuId as number);
        return true;
      }
    }
    return false;
  }, []);

  useEffect(() => {
    setMenuOptions(menuList);
  }, [menuList]);

  useEffect(() => {
    if (visible) {
      if (menuData) {
        setIsEditMode(true);
        form.setFieldsValue(menuData);
        if (menuData.parentId && menuData.parentId !== -1) {
          const path: number[] = [];
          findParentPath(menuOptions, menuData.parentId, path);
          setParentPath(path);
        }
      } else {
        setIsEditMode(false);
        form.resetFields();
        if (parentId) {
          form.setFieldsValue({ parentId: parentId });
          const path: number[] = [];
          findParentPath(menuOptions, parentId, path);
          setParentPath(path);
        } else {
          setParentPath([]);
        }
      }
    }
  }, [menuData, parentId, visible, form, findParentPath, menuOptions]);

  const handleSubmit = async (values: MenuFormData) => {
    const apiUrl = '/menus';
    const method = isEditMode ? 'put' : 'post';
    const body = isEditMode && menuData ? { id: menuData.menuId, ...values } : values;

    try {
      await api[method](apiUrl, body);
      onClose();
      onSuccess();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // 处理级联选择的变化
  const handleCascaderChange = (value: any) => {
    form.setFieldsValue({ parentId: value[value.length - 1] }); // 设置父菜单ID
  };

  return (
    <Modal
      title={isEditMode ? "编辑菜单" : "新增菜单"}
      open={visible}
      width={700}
      maskClosable={false}
      keyboard={false}
      onCancel={onClose}
      footer={null}
    >
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {(menuData?.parentId !== -1 || !isEditMode) && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="parentId"
                label="父级菜单"
              >
                <Cascader
                  options={menuOptions.map(menu => ({
                    value: menu.menuId,
                    label: menu.name,
                    children: menu.children ? menu.children.map(child => ({
                      value: child.menuId,
                      label: child.name,
                    })) : [],
                  }))}
                  changeOnSelect
                  onChange={handleCascaderChange}
                  placeholder="请选择父级菜单"
                  defaultValue={parentPath}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="菜单名称"
              rules={[{ required: true, message: '请输入菜单名称' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="code"
              label="Code"
              rules={[{ required: true, message: '请输入菜单代码' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="path"
              label="路径"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="component"
              label="组件"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="icon"
              label="图标"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="remark"
              label="备注"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="seqNo"
              label="排序"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">提交</Button>
            <Button onClick={onClose} style={{ marginLeft: 8 }}>取消</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddMenuModal;