import React, { useState, useEffect } from 'react';
import { Modal, Table, TimePicker, Button, Select, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import api from '../../../../utils/api';

const { Option } = Select;

interface Anchor {
  id: number;
  name: string;
  avatar: string | null;
}

interface ScheduleForm {
  key: number;
  anchorId?: number;
  startTime?: string;
  endTime?: string;
  id?: number;
}

interface AddScheduleModalProps {
  visible: boolean;
  date: string;
  editData?: any;
  onCancel: () => void;
  onOk: (data: ScheduleForm[]) => void;
  onDelete?: () => void;
}

const AddScheduleModal: React.FC<AddScheduleModalProps> = ({
  visible,
  date,
  editData = {},
  onCancel,
  onOk,
  onDelete,
}) => {
  const [scheduleForm, setScheduleForm] = useState<ScheduleForm[]>([]);
  const [anchors, setAnchors] = useState<Anchor[]>([]);

  useEffect(() => {
    if (visible) {
      if (editData?.id) {
        const formData = {
          key: 1,
          id: editData.id,
          anchorId: editData.anchorId,
          startTime: editData.start,
          endTime: editData.end
        };
        setScheduleForm([formData]);
      } else {
        setScheduleForm([{ key: 1 }]);
      }
      fetchAnchors();
    }
  }, [visible, editData]);

  const fetchAnchors = async () => {
    try {
      const response = await api.get('/anchors');
      if (response.data.code === 1) {
        setAnchors(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch anchors:', error);
    }
  };

  const handleAddRow = () => {
    const newKey = scheduleForm.length + 1;
    setScheduleForm([...scheduleForm, { key: newKey }]);
  };

  const handleDeleteRow = async (key: number, id?: number) => {
    if (editData?.id && id) {
      try {
        const response = await api.delete(`/schedule/anchors/${id}`);
        if (response.data.code === 1) {
          message.success('删除成功');
          onDelete?.();
        }
      } catch (error) {
        message.error('删除失败');
        console.error('Failed to delete schedule:', error);
      }
    } else {
      setScheduleForm(scheduleForm.filter(item => item.key !== key));
    }
  };

  const handleFormChange = (key: number, _obj: any) => {
    setScheduleForm(scheduleForm.map(item => {
      if (item.key === key) {
        return { ...item, ..._obj };
      }
      return item;
    }));
  };

  const columns = [
    {
      title: '班次',
      dataIndex: 'key',
      align: 'center' as const,
      render: (text: number) => `${text}`,
    },
    {
      title: '主播',
      dataIndex: 'anchorId',
      align: 'center' as const,
      render: (_: any, record: ScheduleForm) => (
        <Select
          style={{ width: 120 }}
          placeholder="请选择主播"
          value={record.anchorId}
          disabled={editData?.id}
          onChange={(value) => handleFormChange(record.key, { anchorId: value })}
        >
          {anchors.map(anchor => (
            <Option key={anchor.id} value={anchor.id}>{anchor.name}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: '直播时间',
      dataIndex: 'time',
      align: 'center' as const,
      render: (_: any, record: ScheduleForm) => (
        <TimePicker.RangePicker
          format="HH:mm"
          disabled={editData?.id}
          placeholder={['开始时间', '结束时间']}
          value={record.startTime && record.endTime ? [dayjs(record.startTime), dayjs(record.endTime)] : null}
          onChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              const [start, end] = dates;
              if (end.isBefore(start)) {
                message.error('结束时间不能早于开始时间');
                return;
              }
              handleFormChange(record.key, { startTime: start, endTime: end });
            } else {
              handleFormChange(record.key, { startTime: undefined, endTime: undefined });
            }
          }}
          disabledTime={() => ({
            disabledHours: () => [],
            disabledMinutes: () => []
          })}
        />
      ),
    },
    {
      title: '操作',
      align: 'center' as const,
      render: (_: any, record: ScheduleForm) => (
        <Popconfirm
          title="确定要删除这条排班吗?"
          onConfirm={() => handleDeleteRow(record.key, record.id)}
          okText="确定"
          cancelText="取消"
          disabled={!editData?.id && scheduleForm.length === 1}
        >
          <Button 
            type="link" 
            danger 
            disabled={!editData?.id && scheduleForm.length === 1}
          >
            删除
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Modal
      title={`${dayjs(date).format('YYYY-MM-DD')}`}
      open={visible}
      onCancel={onCancel}
      footer={editData?.id ? [
        <Button key="close" onClick={onCancel}>
          关闭
        </Button>
      ] : undefined}
      onOk={() => !editData?.id && onOk(scheduleForm)}
      width={800}
    >
      <Table
        bordered
        dataSource={scheduleForm}
        columns={columns}
        pagination={false}
        footer={() => (
          editData?.id ? null : <Button type="dashed" block onClick={handleAddRow}>
            <PlusOutlined /> 添加一行
          </Button>
        )}
      />
    </Modal>
  );
};

export default AddScheduleModal;