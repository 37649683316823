import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

interface Shop {
  shopId: number;
  shopName: string;
  selected: number;
  accountIds?: number[];
  shopAvatar?: string;
}

interface User {
  id: number;
  username: string;
  menus: any[];
  shops?: Shop[];
}

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
  refreshUser: () => Promise<void>;
  selectedShop: Shop | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const fetchingRef = useRef(false);

  const fetchUser = async () => {
    if (location.pathname === '/login' || location.pathname === '/' || fetchingRef.current) {
      setLoading(false);
      return;
    }
    setLoading(true);
    fetchingRef.current = true;
    try {
      const response = await api.get('/users/current');
      if (response.data.code === 1) {
        setUser(response.data.data);
        if (response.data.data.shops && response.data.data.shops.length > 0) {
          const selected = response.data.data.shops.find((shop: Shop) => shop.selected === 1);
          if (selected) {
            setSelectedShop(selected);
          } else if (response.data.data.shops.length > 1) {
            navigate('/shops');
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    } finally {
      setLoading(false);
      fetchingRef.current = false;
    }
  };

  const refreshUser = async () => {
    setLoading(true);
    await fetchUser();
  };

  useEffect(() => {
    fetchUser();
  }, [location.pathname]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, refreshUser, selectedShop }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
