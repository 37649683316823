import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Checkbox, Button, message } from 'antd';
import api from '../../utils/api';
import './IndicatorConfigModal.css'; // 添加新的CSS文件引入

interface Indicator {
  transactionAmount?: number;
  returnAmount?: number;
  transOrderCount?: number;
  transAudience?: number;
  productExposureAudience?: number;
  prodClickAudience?: number;
  prodExposTransAmount?: number;
  prodExposCount?: number;
  prodClickCount?: number;
  prodClickRate?: number;
  productClickPayRate?: number;
  perOrderPrice?: number;
  returnOrderCount?: number;
}
interface IndicatorConfigModalProps {
  visible: boolean;
  indicator: Indicator;
  onClose: () => void;
  onSave: (indicators: Record<string, number>) => Promise<void>;
}

const indicatorOptions = [
  { label: '成交金额', value: 'transactionAmount', disabled: true },
  { label: '商品曝光人数', value: 'productExposureAudience' },
  { label: '商品点击支付率', value: 'productClickPayRate' },
  { label: '商品退款金额', value: 'returnAmount' },
  { label: '成交订单数', value: 'transOrderCount' },
  { label: '成交人数', value: 'transAudience' },
  { label: '成交客单价', value: 'perOrderPrice' },
  { label: '商品点击人数', value: 'prodClickAudience' },
  { label: '商品点击率（次数）', value: 'prodClickRate' },
  { label: '退款订单量', value: 'returnOrderCount' },
  { label: '千次曝光成交金额', value: 'prodExposTransAmount' },
  { label: '商品曝光次数', value: 'prodExposCount' },
];

const IndicatorConfigModal: React.FC<IndicatorConfigModalProps> = ({ visible, onClose, onSave, indicator }) => {
  const [selectedIndicators, setSelectedIndicators] = useState<string[]>(['transactionAmount']);

  useEffect(() => {
    if (visible) {
        getIndicatorConfig();
    }
  }, [visible]);

  const getIndicatorConfig = () => {
    const selected = Object.entries(indicator)
          .filter(([_, value]) => value === 1)
          .map(([key, _]) => key);
    setSelectedIndicators(selected);
  };

  const handleSave = async () => {
    if (selectedIndicators.length > 6) {
      message.error('最多只能选择6个指标');
      return;
    }

    const indicatorConfig = indicatorOptions.reduce((acc, option) => {
      acc[option.value] = selectedIndicators.includes(option.value) ? 1 : 0;
      return acc;
    }, {} as Record<string, number>);

    try {
      await onSave(indicatorConfig);
      message.success('保存成功');
      onClose();
    } catch (error) {
      console.error('Failed to save indicator config:', error);
      message.error('保存指标配置失败');
    }
  };

  const handleChange = (checkedValues: string[]) => {
    setSelectedIndicators(checkedValues);
  };

  return (
    <Modal
      title="配置指标"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          取消
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          保存
        </Button>,
      ]}
    >
      <Checkbox.Group
        options={indicatorOptions}
        value={selectedIndicators}
        onChange={handleChange}
      >
        {indicatorOptions.map(option => ( 
          <Checkbox 
            key={option.value} 
            value={option.value} 
            disabled={option.disabled}
          >
            {option.label}
          </Checkbox>
        ))}
      </Checkbox.Group>
      <p style={{ marginTop: 16, color: 'rgba(0, 0, 0, 0.45)' }}>
        注：最多可选择6个指标，成交金额为默认指标
      </p>
    </Modal>
  );
};

export default IndicatorConfigModal;
