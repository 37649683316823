import React from 'react';
import styles from './Loading.module.css';

interface LoadingProps {
  size?: number;
  color?: string;
  fullScreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  size = 40,
  color = '#1890ff',
  fullScreen = false,
}) => {
  const spinnerStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderColor: `${color}20`,
    borderTopColor: color,
  };

  return (
    <div className={`${styles.wrapper} ${fullScreen ? styles.fullScreen : ''}`}>
      
    </div>
  );
};

export default Loading;