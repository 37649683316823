import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Select, Button, Space, Radio } from 'antd';
import { SettingFilled, FundFilled, MessageFilled, AccountBookFilled, WarningFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import api from '../../../utils/api';
import './LiveBroadcastList.scss';
import IndicatorModal from '../components/IndicatorModal/IndicatorModal';

const { RangePicker } = DatePicker;
const { Option } = Select;


interface Account {
  id: number;
  name: string;
}

interface SalesPromotion {
  id: string;
  name: string;
  start: string;
  end: string;
}

const LiveBroadcastList: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs().subtract(1, 'day').endOf('day')
  ]);
  const [isInit, setIsInit] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const [indicatorConfig, setIndicatorConfig] = useState<Record<string, number>>({});
  const [isIndicatorConfigVisible, setIsIndicatorConfigVisible] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      initData();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
    
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchData();
    }
  }, [isInit]);

  const initData = async () => {
    await fetchIndicator();
    await fetchAccounts();
    await fetchSalesPromotions();
    setIsInit(true)
  }

  const fetchData = async (newPage?: number, newPageSize?: number, newIndicatorConfig?: Record<string, number>) => {
    if (!selectedAccount || Object.keys(indicatorConfig).length === 0) {
      console.error('Selected account or indicator config is not set');
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`/livebroadcast/page/${newPage || page}/size/${newPageSize || pageSize}`, {
        start: dateRange[0].format('YYYY-MM-DD'),
        end: dateRange[1].format('YYYY-MM-DD'),
        accountId: selectedAccount,
        indicator: newIndicatorConfig || indicatorConfig,
      });
      if(response.data.code === 1) {
        setData(response.data.data.records);
        setTotal(response.data.data.total);
      }
    } catch (error) {
      console.error('Failed to fetch live broadcast list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveIndicatorConfig = async (newIndicatorConfig: Record<string, number>) => {
    try {
      await fetchData(page, pageSize, newIndicatorConfig);
      fetchIndicator();
    } catch (error) {
      console.error('Failed to save indicator config:', error);
      throw error;
    }
  };


  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const fetchIndicator = async () => {
    try {
      const response = await api.get('/livebroadcast/indicator');
      if (response.data.code === 1) {
        setIndicatorConfig(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch indicator config:', error);
    }
  };

  const onAccountChange = (value: number) => {
    setSelectedAccount(value);
  };
  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const endDate = dayjs().subtract(1, 'day').endOf('day');
    let startDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
    }
    
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const handleReset = () => {
    setDateRange([dayjs().subtract(7, 'day'), dayjs().subtract(1, 'day').endOf('day')]);
    setTimeQuickValue('7');
    setSelectedPromotion(null);
    setPage(1);
    setPageSize(50);
    setSelectedAccount(accounts.length > 0 ? accounts[0].id : null);
    setIsInit(!isInit);
  };

  const getIndicatorColumns = (): ColumnsType<any[]> => {
    const indicatorColumns: ColumnsType<any[]> = [];

    Object.entries(indicatorConfig).forEach(([key, value]) => {
      if (value === 1) {
        let title = '';
        let dataIndex = key;

        // Find the title for the indicator
        Object.values(indicatorMap).some(category => {
          const indicator = category.list.find(item => item.key === key);
          if (indicator) {
            title = indicator.title;
            return true;
          }
          return false;
        });

        indicatorColumns.push({
          title,
          dataIndex,
          key: dataIndex,
          width: 150
        });
      }
    });

    return indicatorColumns;
  };

  const indicatorMap = {
    flow: {
      title: '流量',
      key: 'flow',
      icon:<FundFilled />,
      list: [
        {
          title: '平均在线人数',
          key: 'averageOnlineUsers',
        },
        {
          title: '曝光-观看率（次数）',
          key: 'exposureViewRate',
        },
        {
          title: '累计观看人数',
          key: 'cumulativeViewers',
        },
        {
          title: '曝光次数',
          key: 'exposureCount',
        }
      ]
    },
    interactive: {
      title: '互动',
      key: 'interactive',
      icon: <MessageFilled />,
      list: [
        {
          title: '人均观看时长',
          key: 'averageViewingDuration',
        },
        {
          title: '平均评论次数（分钟）',
          key: 'averageCommentsPerMinute',
        },
        {
          title: '观看-互动率（人数）',
          key: 'viewInteractionRate',
        },{
          title: '新增粉丝数',
          key: 'newFansCount',
        },{
          title: '观看-关注率（人数）',
          key: 'viewFollowRate',
        },{
          title: '新加直播团人数',
          key: 'newLiveGroupMembersCount',
        },{
          title: '观看-加直播团率（人数）',
          key: 'viewJoinLiveGroupRate',
        },{
          title: '新加购物团人数',
          key: 'newShoppingGroupMembersCount',
        },{
          title: '观看-加购物团率（人数）',
          key: 'viewJoinShoppingGroupRate',
        }
      ]
    },
    trade: {
      title: '交易',
      key: 'trade',
      icon: <AccountBookFilled />,
      list: [
          {
            title: '成交人数',
            key: 'transactionUserCount'
          },
          {
            title: '千次观看成交金额',
            key: 'thousandViewsTransactionAmount'
          },
          {
            title: '观看-成交率（人数）',
            key: 'viewTransactionRate'
          },
          {
            title: '商品点击-成交率（人数）',
            key: 'productClickTransactionRate'
          },
          {
            title: '退款金额',
            key: 'refundAmount'
          },
          {
            title: '退款金额占比',
            key: 'refundAmountRatio'
          },
          {
            title: '预售全款金额',
            key: 'preSaleFullAmount'
          },
          {
            title: '成交件数',
            key: 'transactionItemsCount'
          },
          {
            title: '成交老粉占比',
            key: 'transactionOldFansRatio'
          },
          {
            title: '指示器成交金额',
            key: 'indicatorTransactionAmount'
          },
          {
            title: '千川消耗',
            key: 'qcConsumption'
          }
      ]
    },
    compliance: {
      title: '合规',
      key: 'compliance',
      icon: <WarningFilled />,
      list: [{
        title: '违规次数',
        key: 'violationCount'
      }]
    }
  }

  const columns: ColumnsType<any[]> = [
    {
      title: '头像',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar: string) => <img src={avatar} style={{ width: 40, height: 40, borderRadius: '50%' }} />,
      width: 80
    },
    {
      title: '抖音号',
      dataIndex: 'dyAccountName',
      key: 'dyAccountName',
      width: 120
    },
    {
      title: '开播时间',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 120
    },
    {
      title: '主播',
      dataIndex: 'anchor',
      key: 'anchor',
      width: 120
    },
    {
      title: '直播时长',
      dataIndex: 'duration',
      key: 'duration',
      width: 120
    },
    ...getIndicatorColumns()
  ];

  

  return (
    <div className="live-broadcast-list">
      <div className="filters">
        <Space>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ width: 140, minWidth: 140 }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '60px'}}>开播时间</span>
            <RangePicker 
              value={dateRange}
              style={{width: '230px', minWidth: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            options={[
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' },
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
          <Select
            style={{ width: 120 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
        </Space>
      </div>
      <p style={{marginBottom: 10, display: 'flex', justifyContent: 'flex-end'}}>
        <Button type="primary" onClick={() => fetchData()}>
            查询
        </Button> 
        <Button style={{marginLeft: 5}} onClick={handleReset}>重置</Button>
        <Button
          icon={<SettingFilled />}
          style={{marginLeft: 5}}
          onClick={() => setIsIndicatorConfigVisible(true)}
        >
          指标配置
        </Button>
      </p>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        scroll={{ x: 'max-content', y: 450 }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          onChange: (page, pageSize) => fetchData(page, pageSize),
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
        }}
      />
      <IndicatorModal
        isVisible={isIndicatorConfigVisible}
        onClose={() => setIsIndicatorConfigVisible(false)}
        onSave={handleSaveIndicatorConfig}
        indicator={indicatorMap}
        indicatorConfig={indicatorConfig}
      />
    </div>
  );
};

export default LiveBroadcastList;
