// @cursor start
import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Alert } from 'antd';
import { validatePhone } from '../../utils/validate';
import api from '../../utils/api';

interface BindPhoneModalProps {
  open: boolean;
  onClose: () => void;
}

const BindPhoneModal: React.FC<BindPhoneModalProps> = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => timer && clearTimeout(timer);
  }, [countdown]);

  const handleGetCode = async () => {
    try {
      await form.validateFields(['phone']);
      const phone = form.getFieldValue('phone');
      const response = await api.get(`/sms/phone/code?phone=${phone}`);
      if (response.data.code === 1) {
        setCountdown(30);
        message.success('验证码已发送');
      } else {
        message.error(response.data.message || '获取验证码失败');
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const response = await api.post('/login/bound', {
        phone: values.phone,
        code: values.code
      });
      if (response.data.code === 1) {
        message.success('绑定成功');
        onClose();
        window.location.href = '/#/dashboard'
      } else {
        message.error(response.data.message || '绑定失败');
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="绑定手机号"
      open={open}
      maskClosable={false}
      closable={false}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          确定
        </Button>,
      ]}
    >
      <Form form={form} layout="horizontal" style={{ marginTop: '50px',marginBottom: '50px' }}>
        <Form.Item
          name="phone"
          label="手机号"
          rules={[
            {required: true},
            {
              validator: async (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                if (!validatePhone(value)) {
                  return Promise.reject(new Error('请输入正确的手机号'));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="请输入手机号" maxLength={11} />
        </Form.Item>
        <Form.Item
          name="code"
          label="验证码"
          rules={[{ required: true, message: '请输入验证码' }]}
        >
          <Input
            placeholder="请输入验证码"
            maxLength={6}
            suffix={
              <Button
                type="link"
                disabled={countdown > 0}
                onClick={handleGetCode}
              >
                {countdown > 0 ? `${countdown}s后重新获取` : '获取验证码'}
              </Button>
            }
          />
        </Form.Item>
      </Form>
      <Alert
      style={{marginBottom: '50px'}}
      message="历史数据同步中"
      description="首次订购使用的用户，历史订单数据将由近及远同步，预计需要约30-60分钟左右，请稍候。"
      type="info"
      showIcon
    />
    </Modal>
  );
};

export default BindPhoneModal;
// @cursor end