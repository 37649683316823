import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Input, Button, Select, Pagination } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import './UserManagement.css';
import AddUserModal from './AddUserModal'; // 导入新增用户弹窗组件
import api from '../../../utils/api'; 

const { Option } = Select;

const UserManagement = () => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({ username: '', status: 'all' });
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const fetchData = useCallback(async (page: number, size: number) => {
    if (isLoading) return;

    setIsLoading(true);

    if (fetchControllerRef.current) {
      fetchControllerRef.current.abort();
    }

    fetchControllerRef.current = new AbortController();
    if (isMounted.current) {
    try {
      let _data = {...searchParams}
      if(_data.status === 'all'){
        _data.status = ''
      }
      const response = await api.get(`/users/page/${page}/size/${size}`, {
        params: _data
      });
      if (isMounted.current) {
        setData(response.data.data.records);
        setTotal(response.data.data.total);
      }
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        console.error('Failed to fetch data:', error);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }
  }, [searchParams, isLoading]);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      fetchData(currentPage, pageSize);
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [fetchData, currentPage, pageSize]);

  const columns = [
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 140
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      width: 80,
      render: (gender: number) => gender === 0 ? '女' : '男'
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 100
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: 160
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status: number) => status === 0 ? '禁用' : '启用'
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      width: 160
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: 160,
      render: (text: any, record: any) => (
        <span>
          {record.status === 0 ? (
            <Button color="primary" variant="text" onClick={() => toggleStatus(record.id, 1)}>启用</Button>
          ) : (
            <Button color="danger" variant="text"  onClick={() => toggleStatus(record.id, 0)}>禁用</Button>
          )}
          <Button color="primary" variant="text" onClick={() => handleEditUser(record.id)}>编辑</Button>
        </span>
      ),
    },
  ];

  const toggleStatus = async (id: any, status: any) => {
    try {
      await api.put(`/users/id/${id}/status/${status}`);
      setCurrentPage(1);
      fetchData(1, pageSize);
    } catch (error) {
      console.error('Error toggling status:', error);
    }
  };


  const handleSearch = () => {
    setCurrentPage(1);
    fetchedRef.current = false;
    fetchData(1, pageSize);
  };

  const handleEditUser = (id?: string) => {
    setIsModalVisible(true);
    setSelectedUserId(id || null);
  };


  const handleUserModalClose = (isRefresh?: boolean) => {
    setIsModalVisible(false);
    if (isRefresh) {
      fetchData(currentPage, pageSize);
    }
  };

  return (
    <div className='user-management'>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginRight: 8 }}>用户名</span>
        <Input 
          placeholder="支持模糊搜索" 
          allowClear
          style={{ width: 200, marginRight: 8 }} 
          value={searchParams.username}
          onChange={(e) => setSearchParams(prev => ({ ...prev, username: e.target.value }))}
        />
        <span style={{ marginRight: 8, marginLeft: 8 }}>用户状态</span>
        <Select 
          value={searchParams.status} 
          style={{ width: 200, marginRight: 8 }}
          allowClear
          onChange={(value) => setSearchParams(prev => ({ ...prev, status: value }))}
        >
          <Option value="all">所有状态</Option>
          <Option value="0">禁用</Option>
          <Option value="1">启用</Option>
        </Select>
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch} loading={isLoading}>搜索</Button>
        <Button type="primary" icon={<PlusOutlined />} style={{ marginLeft: 8 }} onClick={() => handleEditUser()}>新增</Button>
      </div>
      <Table 
        columns={columns.map(column => ({
          ...column,
          align: column.align as 'left' | 'right' | 'center'
        }))} 
        dataSource={data} 
        rowKey="id" 
        bordered 
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
        <Pagination
          total={total}
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          onChange={(page, size) => {
            setCurrentPage(page);
            setPageSize(size || pageSize);
          }}
          onShowSizeChange={(current, size) => setPageSize(size)}
          showTotal={(total) => `共 ${total} 条`}
          pageSizeOptions={['30', '50', '100']}
        />
      </div>
      <AddUserModal
        visible={isModalVisible}
        onClose={handleUserModalClose} // 更新关闭函数
        userId={selectedUserId || undefined}
      />
    </div>
  );
};

export default UserManagement;