import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { UserProvider } from './context/UserContext';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <UserProvider>
          <AppRoutes />
        </UserProvider>
      </Router>
    </ConfigProvider>
  );
}

export default App;
