import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Input, Button, Select, Pagination } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import './RoleManagement.css';
import AddRoleModal from './AddRoleModal'; // 导入新增用户弹窗组件
import api from '../../../utils/api'; 

const RoleManagement = () => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({ name: '' });
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectRoleId, setSelectRoleId] = useState<string | null>(null);

  const fetchData = useCallback(async (page: number, size: number) => {
    if (isLoading) return;

    setIsLoading(true);

    if (fetchControllerRef.current) {
      fetchControllerRef.current.abort();
    }

    fetchControllerRef.current = new AbortController();
    if (isMounted.current) {
    try {
      let _data = {...searchParams}
      const response = await api.get(`/roles/page/${page}/size/${size}`, {
        params: _data
      });
      if (isMounted.current) {
        setData(response.data.data.records);
        setTotal(response.data.data.total);
      }
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        console.error('Failed to fetch data:', error);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }
  }, [searchParams, isLoading]);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      fetchData(currentPage, pageSize);
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [fetchData, currentPage, pageSize]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 140
    },
    {
      title: '角色名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      minWidth: 180,
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      width: 160
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: 160,
      render: (text: any, record: any) => (
          <Button color="primary" variant="text" onClick={() => handleEditUser(record.id)}>编辑</Button>
      ),
    },
  ];


  const handleSearch = () => {
    setCurrentPage(1);
    fetchedRef.current = false;
    fetchData(1, pageSize);
  };

  const handleEditUser = (id: string) => {
    setIsModalVisible(true);
    setSelectRoleId(id);
  };

  return (
    <div className='user-management'>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginRight: 8 }}>角色名</span>
        <Input 
          placeholder="支持模糊搜索" 
          allowClear
          style={{ width: 200, marginRight: 8 }} 
          value={searchParams.name}
          onChange={(e) => setSearchParams(prev => ({ ...prev, name: e.target.value }))}
        />
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch} loading={isLoading}>搜索</Button>
        <Button type="primary" icon={<PlusOutlined />} style={{ marginLeft: 8 }} onClick={() => setIsModalVisible(true)}>新增</Button>
      </div>
      <Table 
        columns={columns.map(column => ({
          ...column,
          align: column.align as 'left' | 'right' | 'center'
        }))} 
        dataSource={data} 
        rowKey="id" 
        bordered 
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
        <Pagination
          total={total}
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          onChange={(page, size) => {
            setCurrentPage(page);
            setPageSize(size || pageSize);
          }}
          onShowSizeChange={(current, size) => setPageSize(size)}
          showTotal={(total) => `共 ${total} 条`}
          pageSizeOptions={['30', '50', '100']}
        />
      </div>
      <AddRoleModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        roleId={selectRoleId || undefined}
      />
    </div>
  );
};

export default RoleManagement;