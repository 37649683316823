import React from 'react';
import './SimpleLayout.css'; // 新增样式文件

interface LayoutProps {
  children: React.ReactNode;
}
const SimpleLayout = ({ children }: LayoutProps) => {
  return (
    <div className="layout">
      <main>{children}</main>
    </div>
  );
};

export default SimpleLayout;