import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import UserManagement from './views/PermissionManagement/UserManagement/UserManagement';
import RoleManagement from './views/PermissionManagement/RoleManagement/RoleManagement';
import MenuManagement from './views/PermissionManagement/MenuManagement/MenuManagement';
import Roi from './views/Tool/Roi';
import Login from './views/Login/Login';
import Dashboard from './views/Home/Dashboard/Dashboard';
import TargetReview from './views/Home/TargetReview/TargetReview';
import NavLayout from './layout/NavLayout/NavLayout';
import SimpleLayout from './layout/SimpleLayout/SimpleLayout';
import HeaderLayout from './layout/HeaderLayout/HeaderLayout';
import AuthGuard from './components/AuthGuard';
import AnchorAnalysis from './views/LiveBroadcastCenter/AnchorAnalysis/AnchorAnalysis';
import LiveBroadcastDetails from './views/LiveBroadcastCenter/LiveBroadcastDetails/LiveBroadcastDetails';
import LiveBroadcastList from './views/LiveBroadcastCenter/LiveBroadcastList/LiveBroadcastList';
import GoodsList from './views/GoodsCenter/GoodsList/GoodsList';
import ShortVideoAnalysis from './views/ShortVideoCenter/ShortVideoAnalysis/ShortVideoAnalysis';
import Shops from './views/Shops/Shops';
import Ticket from './views/Ticket/Ticket';
import BindPhoneModal from './components/BindPhoneModal/BindPhoneModal';
import { useNavigate } from 'react-router-dom';
import api from './utils/api';

function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showBindModal, setShowBindModal] = useState(false);
  const isCheckingRef = useRef(false);

  useEffect(() => {
    const token = Cookies.get('live_polit_token');
    if(location.pathname === '/'){
      if(window.location.search.includes('code')){ 
        validateTicketAndCompany(window.location.search.split('=')[1]);
      } else{
      if (token) {
        window.location.href= '/#/dashboard';
      }else{
        window.location.href= '/#/login';
      }
    }
  }
  }, []);


  const validateTicketAndCompany = async (code: string) => {
    try {
      if(isCheckingRef.current){
        return;
      }
      isCheckingRef.current = true;
      const response = await api.post('/login/ticket', {
        ticket: code
      });
      
      if (response.data.code === 1) {
        document.cookie = `live_polit_token=${response.data.data.token}; path=/`;
        if(response.data.data.phone){
          window.location.href= '/#/dashboard';
        }else{
          setShowBindModal(true);
        }
      } else {
        window.location.href= '/#/login';
      }
    } catch (error) {
      console.error('验证失败:', error);
      window.location.href= '/#/login';
    }
  };

  return (
    <>
      <Routes>
        <Route path="/login" element={<SimpleLayout><Login /></SimpleLayout>} />
        <Route path="/" element={<SimpleLayout><Ticket /></SimpleLayout>} />
        <Route element={<NavLayout><AuthGuard><Outlet /></AuthGuard></NavLayout>}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/permission-management/user-management" element={<UserManagement />} />
          <Route path="/permission-management/role-management" element={<RoleManagement />} />
          <Route path="/permission-management/menu-management" element={<MenuManagement />} />
          <Route path="/tool/roi" element={<Roi />} />
          <Route path="/target-review" element={<TargetReview />} />
          <Route path="/livebroadcast-center/anchor-analysis" element={<AnchorAnalysis />} />
          <Route path="/livebroadcast-center/livebroadcast-details" element={<LiveBroadcastDetails />} />
          <Route path="/livebroadcast-center/livebroadcast-list" element={<LiveBroadcastList />} />
          <Route path="/goods-center/goods-list" element={<GoodsList />} />
          <Route path="/short-video-center/short-video-analysis" element={<ShortVideoAnalysis />} />
        </Route>
        <Route element={<HeaderLayout><AuthGuard><Outlet /></AuthGuard></HeaderLayout>}>
          <Route path="/shops" element={<Shops />} />
        </Route>
      </Routes>
      <BindPhoneModal 
        open={showBindModal} 
        onClose={() => setShowBindModal(false)} 
      />
    </>
  );
}

export default AppRoutes; 