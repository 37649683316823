import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './MenuManagement.css';
import AddMenuModal from './AddMenuModal';
import api from '../../../utils/api';

interface MenuData {
  menuId: number;
  name: string;
  code: string;
  parentId?: number;
  path?: string;
  component?: string;
  icon?: string;
  remark?: string;
  children?: MenuData[];
}

const MenuManagement: React.FC = () => {
  const [menuList, setMenuList] = useState<MenuData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMenuData, setSelectedMenuData] = useState<MenuData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [parentId, setParentId] = useState<number | null>(null);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);

  const fetchData = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    if (fetchControllerRef.current) {
      fetchControllerRef.current.abort();
    }

    fetchControllerRef.current = new AbortController();
    if (isMounted.current) {
    try {
      const response = await api.get(`/menus`);
      if (isMounted.current) {
        setMenuList(response.data.data);
      }
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        console.error('Failed to fetch data:', error);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }
  }, [isLoading]);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      fetchData();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [fetchData]);

  const columns = [
    {
      title: '菜单名称',
      dataIndex: 'name',
      key: 'name',
      minWidth: 140,
    },{
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 100,
    },
    {
      title: '路径',
      dataIndex: 'path',
      key: 'path',
      minWidth: 200,
    },
    {
      title: '图标',
      dataIndex: 'icon',
      key: 'icon',
      width: 100,
    },
    {
      title: '排序',
      dataIndex: 'seqNo',
      key: 'seqNo',
      width: 100,
    },
    {
      title: '组件',
      dataIndex: 'component',
      key: 'component',
      width: 100,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      minWidth: 120,
    },
    {
      title: '操作',
      key: 'action',
      width: 140,
      render: (_: any, record: MenuData) => (
        <Space>
          <Button color="primary" variant="text" onClick={() => handleEditMenu(record)}>
            编辑
          </Button>
          <Button color="primary" variant="text" onClick={() => handleAddSubMenu(record.menuId || -1)}>
            新增子菜单
          </Button>
        </Space>
      ),
    },
  ];

  const handleEditMenu = (row: MenuData) => {
    setIsModalVisible(true);
    setSelectedMenuData(row);
  };

  const handleAddSubMenu = (parentId: number) => {
    setIsModalVisible(true);
    setSelectedMenuData(null);
    setParentId(parentId);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMenuData(null);
    setParentId(null);
    fetchData();
  };

  return (
    <div className='menu-management'>
      <div style={{ marginBottom: 16 }}>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={() => {
            setIsModalVisible(true);
            setSelectedMenuData(null);
            setParentId(null);
          }}
        >
          新增菜单
        </Button>
      </div>
      
      <Table<MenuData>
        columns={columns}
        dataSource={menuList}
        rowKey="menuId"
        loading={isLoading}
        pagination={false}
        bordered
        scroll={{ x: 'max-content' }}
        expandable={{
          childrenColumnName: 'children',
          rowExpandable: (record) => !!(record.children && record.children.length > 0),
          expandIcon: ({ expanded, onExpand, record }) =>
            record.children && record.children.length > 0 ? (
              <Button
                type="text"
                onClick={e => onExpand(record, e)}
                icon={expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
              />
            ) : null
        }}
      />
      
      <AddMenuModal
        visible={isModalVisible}
        onClose={handleModalClose}
        menuData={selectedMenuData}
        parentId={parentId || undefined}
        onSuccess={fetchData}
        menuList={menuList}
      />
    </div>
  );
};

export default MenuManagement;