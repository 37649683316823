import React from 'react';
import { useUser } from '../context/UserContext';
import Loading from './Loading';

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { loading } = useUser();

  if (loading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default AuthGuard;