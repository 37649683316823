import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, message } from 'antd';

interface IndicatorModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: (newConfig: Record<string, number>) => void;
  indicator: Record<string, any>;
  indicatorConfig: Record<string, number>;
}

const defaultSelectedKeys = [
  'averageOnlineUsers',
  'exposureViewRate',
  'averageViewingDuration',
  'viewInteractionRate',
  'newFansCount',
  'thousandViewsTransactionAmount',
  'transactionUserCount',
  'viewTransactionRate',
  'productClickTransactionRate',
  'transactionItemsCount',
];

const IndicatorModal: React.FC<IndicatorModalProps> = ({
  isVisible,
  onClose,
  onSave,
  indicator,
  indicatorConfig,
}) => {
  const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);

  useEffect(() => {
    if (isVisible) {
      const initialSelected = Object.entries(indicatorConfig)
      .filter(([_, value]) => value === 1)
      .map(([key]) => key);

    setSelectedIndicators(initialSelected.length >= 1 ? initialSelected : defaultSelectedKeys);
    }
  }, [isVisible]);


  const handleIndicatorChange = (checkedValues: any[]) => {
    setSelectedIndicators(checkedValues as string[]);
  };

  const handleRestoreDefaults = () => {
    setSelectedIndicators(defaultSelectedKeys);
  };

  const handleSave = () => {
    if (selectedIndicators.length < 3) {
      message.error('请至少选择3个指标');
      return;
    }
    const newConfig: Record<string, number> = {};
    Object.keys(indicatorConfig).forEach(key => {
      newConfig[key] = selectedIndicators.includes(key) ? 1 : 0;
    });
    onSave(newConfig);
    onClose();
  };

  return (
    <Modal
      title="指标配置"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="restore" onClick={handleRestoreDefaults}>
          恢复默认
        </Button>,
        <Button key="cancel" onClick={onClose}>
          取消
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          保存
        </Button>,
      ]}
      width={900}
    >
      <div className="indicator-config-content">
        <Checkbox.Group value={selectedIndicators} onChange={handleIndicatorChange}>
          {Object.entries(indicator).map(([groupKey, group]) => (
            <div key={groupKey} className="indicator-group" style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}>
              <h3 style={{width: '100px', minWidth: '100px',marginTop: '0', marginRight: '10px', color: '#666'}}>
                {group.title}
              </h3>
              <div>
                {group.list.map((item: any) => (
                  <Checkbox key={item.key} value={item.key} style={{marginRight: '16px', marginBottom: '8px', width: '220px'}}>
                    {item.title}
                  </Checkbox>
                ))}
              </div>
            </div>
          ))}
        </Checkbox.Group>
      </div>
    </Modal>
  );
};

export default IndicatorModal;