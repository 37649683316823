import React from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import api from '../../../../utils/api';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  accounts: {id: string; name: string}[];
}

const CreateAnchorModal: React.FC<Props> = ({
  visible,
  onCancel,
  onSuccess,
  accounts
}) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const response = await api.post('/anchors', values);
      
      if (response.data.code === 1) {
        message.success('创建成功');
        form.resetFields();
        onSuccess();
        onCancel();
      } else {
        message.error(response.data.msg || '创建失败');
      }
    } catch (error) {
      console.error('Failed to create anchor:', error);
    }
  };

  return (
    <Modal
      title="创建主播"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      destroyOnClose
    >
      <Form
        style={{marginTop: '40px'}}
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name="name"
          label="主播名称"
          rules={[
            { required: true, message: '请输入主播名称' },
            { max: 8, message: '主播名称不能超过8个字' }
          ]}
        >
          <Input placeholder="请输入主播名称" maxLength={8} />
        </Form.Item>

        <Form.Item
          name="accountId"
          label="所属抖音号"
          rules={[{ required: true, message: '请选择所属抖音号' }]}
        >
          <Select placeholder="请选择抖音号">
            {accounts.map(account => (
              <Select.Option key={account.id} value={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAnchorModal;