import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, DatePicker, Select, Statistic, Progress, Button, Radio, Popover } from 'antd';
import type { RadioChangeEvent } from 'antd';
import ReactECharts from 'echarts-for-react';
import { CaretDownOutlined,CaretUpOutlined, InfoCircleOutlined, LoginOutlined, SettingFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Dashboard.sass';
import api from '../../../utils/api';
import dayjs from 'dayjs';
import { useUser } from '../../../context/UserContext';
import IndicatorConfigModal from '../../../components/IndicatorConfigModal/IndicatorConfigModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Account {
  id: number;
  name: string;
}

interface SalesPromotion {
  id: string;
  name: string;
  start: string;
  end: string;
}

interface Indicator {
  transactionAmount?: number;
  returnAmount?: number;
  transOrderCount?: number;
  transAudience?: number;
  productExposureAudience?: number;
  prodClickAudience?: number;
  prodExposTransAmount?: number;
  prodExposCount?: number;
  prodClickCount?: number;
  prodClickRate?: number;
  productClickPayRate?: number;
  perOrderPrice?: number;
  returnOrderCount?: number;
}

const Dashboard: React.FC = () => {
  const { selectedShop } = useUser();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<any>({});
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs().subtract(1, 'day').endOf('day')
  ]);
  const [indicatorConfig, setIndicatorConfig] = useState<Indicator>({});
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [compareType, setCompareType] = useState('1');
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const [setChartData] = useState<any>(null);
  const [isIndicatorConfigVisible, setIsIndicatorConfigVisible] = useState(false);
  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      initData();
      if (selectedAccount) {
        fetchDashboardData();
      }
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchDashboardData();
    }
  }, [selectedAccount, dateRange, compareType]);

  const initData = async () => {
    await Promise.all([
      await fetchIndicator(),
      fetchAccounts(),
      fetchSalesPromotions()
    ]);
  }

  const fetchIndicator = async () => {
    try {
      const response = await api.get('/business/overview/indicator');
      if (response.data.code === 1) {
        setIndicatorConfig(response.data.data || {});
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const handleSaveIndicatorConfig = async (newIndicatorConfig: Record<string, number>) => {
    try {
      await fetchDashboardData(newIndicatorConfig);
      fetchIndicator();
    } catch (error) {
      console.error('Failed to save indicator config:', error);
      throw error;
    }
  };

  const fetchDashboardData = async (newIndicatorConfig?: Record<string, number>) => {
    try {
      const [start, end] = dateRange;
      let compareStart: dayjs.Dayjs | undefined;
      let compareEnd: dayjs.Dayjs | undefined;

      switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
      }

      const response = await api.post('/business/overview', {
        accountId: selectedAccount,
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        indicator: newIndicatorConfig || indicatorConfig
      });
      if (response.data.code === 1) {
        setDashboardData(response.data.data);
        setIsIndicatorConfigVisible(false);
      }
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
    }
  };

  const timeOptions = [
    { label: '近1天', value: '1' },
    { label: '近7天', value: '7' },
    { label: '近30天', value: '30' },
  ];

  const onTimeQuickChange = ({ target: { value } }: RadioChangeEvent) => {
    setTimeQuickValue(value);
    const endDate = dayjs().subtract(1, 'day').endOf('day');
    const startDate = endDate.subtract(Number(value) - 1, 'day').startOf('day');
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const onAccountChange = (value: number) => {
    setSelectedAccount(value);
  };

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
  };

  const setIndicatorConfigVisible = (visible: boolean) => {
    setIsIndicatorConfigVisible(visible);
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-header-left" style={{marginTop: 10}}>
          <div className="dashboard-title" style={{marginRight: 10}}>{selectedShop?.shopName}</div>
          <Button color="primary" icon={<LoginOutlined />} variant="text" onClick={() => window.open('https://fxg.jinritemai.com/', '_blank')}>抖音小店</Button>
          <Button color="primary" icon={<LoginOutlined />} variant="text" onClick={() => window.open('https://qianchuan.jinritemai.com/', '_blank')}>巨量千川</Button>
          <Button color="primary" icon={<LoginOutlined />} variant="text" onClick={() => window.open('https://compass.jinritemai.com/', '_blank')}>罗盘经营</Button>
        </div>
        <div className="dashboard-filters" style={{marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: 5, minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ width: 120 }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <span style={{marginRight: 0, minWidth: '46px', width: '46px', marginLeft: 10}}>时间</span>
          <RangePicker 
            value={dateRange}
            style={{ minWidth: 240 }} 
            onChange={(dates) => {
              if (dates) {
                setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                setTimeQuickValue('');
                setSelectedPromotion(null);
              }
            }}
          />
          <Radio.Group 
            style={{minWidth: '210px'}}
            options={timeOptions} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
          <Select
            style={{ width: 120 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
          <div style={{marginLeft: 10, minWidth: '200px'}}>
            <span style={{marginRight: 5}}>对比周期</span>
            <Select
              style={{ width: 120 }}
              placeholder="请选择"
              value={compareType}
              onChange={onCompareTypeChange}
              options={[
                { value: '1', label: '连续周期' },
                { value: '2', label: '上月同期' },
                { value: '3', label: '上季度同期' },
                { value: '4', label: '去年同期' }
              ]}
            />
          </div>
        </div>
      </div>

      {/* 经营概览模块start */}
      <h3>经营概览  <Button style={{marginLeft: 5}} color="primary"  variant="text" icon={<SettingFilled />} onClick={() => setIndicatorConfigVisible(true)}>指标配置</Button></h3>
      <IndicatorConfigModal
        visible={isIndicatorConfigVisible}
        onClose={() => setIsIndicatorConfigVisible(false)}
        onSave={handleSaveIndicatorConfig}
        indicator={indicatorConfig}
      />
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
      <Row gutter={[16, 16]} style={{marginBottom: '20px', width: '40%', minWidth: '600px'}}>
        {indicatorConfig.transactionAmount === 1 && <Col span={8} >
          <Card bodyStyle={{ padding: '10px'}}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  成交金额
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>"支付成功时间"在所选时间范围内，全端消费者实际支付成功（含货到付款）的订单金额汇总，不剔除"退款金额"。</p>
                        <p>注意：</p>
                        <ul>
                          <li>1、包含退款金额</li>
                          <li>2、金额按照用户实际支付数据计算，不包含优惠券，包含运费</li>
                        </ul>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.transactionAmount || ''}
              prefix="¥"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.transactionAmountRate > 0 ? 'red' : dashboardData.transactionAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.transactionAmountRate !== 0 && (dashboardData.transactionAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.transactionAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.productExposureAudience === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品曝光人数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>所选时间范围内，本店全部商品在全端的直播、短视频、商品卡曝光的去重人数。</p>
                        
                        <h4>去重逻辑：</h4>
                        <p>人数指标（UV）建议以固定周期为准</p>
                        <ul>
                          <li>固定周期（自然日/周/月、近1/7/30天）：同一统计周期内按用户ID去重，同一个用户访问多次算作1人</li>
                          <li>自定义周期：单日按用户ID去重后数据累加</li>
                        </ul>
                        
                        <h4>举例：</h4>
                        <p>以商品点击人数举例，同一个用户，1月份内有10天发生店铺商品点击行为。</p>
                        <ul>
                          <li>选择自然月1月，统计为1个人</li>
                          <li>选择自定义31天(1.1-1.31)，统计为10个人</li>
                        </ul>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.productExposureAudience / 10000}
              suffix="万"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.productExposureAudienceRate > 0 ? 'red' : dashboardData.productExposureAudienceRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.productExposureAudienceRate !== 0 && (dashboardData.productExposureAudienceRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.productExposureAudienceRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.productClickPayRate === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品点击支付率
                  <Popover 
                    placement="topRight" 
                    content={
                      <p>商品成交次数/商品点击次数</p>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.productClickPayRate}
              suffix="%"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.productClickPayRateRate > 0 ? 'red' : dashboardData.productClickPayRateRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.productClickPayRateRate !== 0 && (dashboardData.productClickPayRateRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.productClickPayRateRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.returnAmount === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品退款金额
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>“退款成功时间”在所选时间范围内，全端所有退款成功订单产生的退款金额。与订单支付时间或售后发起时间无关。</p>
                        <p>举例：选择9月份（9.1-9.30），代表在9月内走线上售后退款成功累计产生的退款金额。</p>
                        <p>注意：仅包含走售后系统且退款成功的订单（含货到付款），不含线下退款（如先行赔付等），包含发货前和发货后退款</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.returnAmount}
              prefix="¥"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.lastPeriodReturnAmountRate > 0 ? 'red' : dashboardData.lastPeriodReturnAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.lastPeriodReturnAmountRate !== 0 && (dashboardData.lastPeriodReturnAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.lastPeriodReturnAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.transOrderCount === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  成交订单数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>“支付成功时间”在所选时间范围内，全端消费者实际支付成功（含货到付款）的订单数汇总，不剔除“退款订单”。</p>
                        <p>注意：子订单：如一笔订单包含3个sku，则统计为3个子订单</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.transOrderCount}
              prefix="¥"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.lastPeriodTransOrderCountRate > 0 ? 'red' : dashboardData.lastPeriodTransOrderCountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.lastPeriodTransOrderCountRate !== 0 && (dashboardData.lastPeriodTransOrderCountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.lastPeriodTransOrderCountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.transAudience === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  成交人数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>“支付成功时间”在所选时间范围内，全端支付成功（含货到付款）的用户数汇总，包含退款用户。</p>
                        <p>去重逻辑：人数指标（UV）建议以固定周期为准</p>
                        <ul>
                          <li>- 固定周期（自然日/周/月、近1/7/30天）：同一统计周期内按用户ID去重，同一个用户支付多次算作1人</li>
                          <li>- 自定义周期：单日按用户ID去重后数据累加</li>
                        </ul>
                        <p>举例：以商品点击人数举例，同一个用户，1月份内有10天发生店铺商品点击行为。选择自然月1月，统计为1个人；选择自定义31天(1.1-1.31)，统计为10个人。</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.transAudience}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.lastPeriodTransAudienceRate > 0 ? 'red' : dashboardData.lastPeriodTransAudienceRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.lastPeriodTransAudienceRate !== 0 && (dashboardData.lastPeriodTransAudienceRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.lastPeriodTransAudienceRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.perOrderPrice === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  成交客单价
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>成交金额/成交人数</p>
                      </div>
                    } 
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.perOrderPrice}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.perOrderPriceRate > 0 ? 'red' : dashboardData.perOrderPriceRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.perOrderPriceRate !== 0 && (dashboardData.perOrderPriceRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.perOrderPriceRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.prodClickAudience === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品点击人数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>所选时间范围内，本店全部商品在全端的直播、短视频、商品卡点击的去重人数</p>
                        <p>去重逻辑：人数指标（UV）建议以固定周期为准</p>
                        <ul>
                          <li>- 固定周期（自然日/周/月、近1/7/30天）：同一统计周期内按用户ID去重，同一个用户点击多次算作1人</li>
                          <li>- 自定义周期：单日按用户ID去重后数据累加</li>
                        </ul>
                        <p>举例：以商品点击人数举，同一个用户，1月份内有10天发生店铺商品点击行为。选择自然月1月，统计为1个人；选择自定义31天(1.1-1.31)，统计为10个人</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.prodClickAudience}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.prodClickAudienceRate > 0 ? 'red' : dashboardData.prodClickAudienceRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.prodClickAudienceRate !== 0 && (dashboardData.prodClickAudienceRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.prodClickAudienceRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.prodClickRate === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品点击率（次数）
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>商品点击次数/商品曝光次数</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.prodClickRate}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.prodClickRateRate > 0 ? 'red' : dashboardData.prodClickRateRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.prodClickRateRate !== 0 && (dashboardData.prodClickRateRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.prodClickRateRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.returnOrderCount === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  退款订单量
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>「退款成功时间」在所选时间范围内，全端所有退款成的订单量，与订单支付时间或售后发起时间无关。</p>
                        <p>举例：选择近30天（7.26-8.24），代表在这30天内走线上售后退款成功的订单数量汇总。</p>
                        <p>注意：仅包含走售后系统且退款成功的订单（含货到付款），不含线下退款（如先行赔付等），包含发货前和发货后退款</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.returnOrderCount}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.returnOrderCountRate > 0 ? 'red' : dashboardData.returnOrderCountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.returnOrderCountRate !== 0 && (dashboardData.returnOrderCountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.returnOrderCountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.prodExposTransAmount === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  千次曝光成交金额
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>成交金额*1000/商品曝光次数</p>
                      </div>
                    } 
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.prodExposTransAmount}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.prodExposTransAmountRate > 0 ? 'red' : dashboardData.prodExposTransAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.prodExposTransAmountRate !== 0 && (dashboardData.prodExposTransAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.prodExposTransAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
        {indicatorConfig.prodExposCount === 1 && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  商品曝光次数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>所选时间范围内，全部商品在全端的直播、短视频、商品卡曝光的次数</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.prodExposCount}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.prodExposCount > 0 ? 'red' : dashboardData.prodExposCount < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.prodExposCount !== 0 && (dashboardData.prodExposCount > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.prodExposCount)}%
               </span>
            </div>
          </Card>
        </Col>}
      </Row>
      {dashboardData.thisDaySalesAmounts && dashboardData.lastPeriodDaySalesAmounts ? <Card style={{flex: 1, marginLeft: 30, maxWidth: '900px'}} bodyStyle={{ padding: '10px' }}>
        <ReactECharts
          option={{
            title: {
              text: '成交金额'
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params:any) => {
                const [thisWeek, lastWeek] = params;
                return `${thisWeek.axisValue}<br/>
                  今日成交额: ${thisWeek.value} 元<br/>
                  上一周期成交额: ${lastWeek.value} 元`;
              }
            },
            legend: {
              data: ['本周期', '上一周期'],
              show: true
            },
            xAxis: {
              type: 'category',
              data: dashboardData.thisDaySalesAmounts ? (dashboardData.thisDaySalesAmounts as any[]).map((item:any) => item.date) : []
            },
            yAxis: {
              type: 'value',
              name: '',
              axisLabel: {
                formatter: '{value} 元'
              },
              splitNumber: 5
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            series: [
              {
                name: '本周期',
                type: 'line',
                data: dashboardData.thisDaySalesAmounts ? (dashboardData.thisDaySalesAmounts as any[]).map((item:any) => item.salesAmount) : [],
                smooth: true
              },
              {
                name: '上一周期',
                type: 'line',
                data: dashboardData.lastPeriodDaySalesAmounts ? (dashboardData.lastPeriodDaySalesAmounts as any[]).map((item:any) => item.salesAmount) : [],
                smooth: true
              }
            ]
          }}
          style={{ height: '235px', width: '100%' }}
        />
      </Card> : ''}
      </div>
      {/* 经营概览模块end */}


      <h3 style={{ marginTop: '20px' }}>投放效果</h3>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
      {[
        { title: '总消耗', desc: '取店铺关联全部账号的千川【账户整体消耗】字段求和', value: dashboardData.spendAmount, suffix: '¥', diffRate:dashboardData.spendAmountRate },
        { title: '总ROI', desc: '销售额/总消耗', value: dashboardData.roi, suffix: '%',diffRate:dashboardData.roiRate, percent: dashboardData.roi, showProgress: true },
        { title: '点击率', desc: '商品点击人数/商品曝光人数', value: dashboardData.clickRate, suffix: '¥', diffRate: dashboardData.clickRateRate },
        { title: '转化率', desc: '成交订单数/商品点击人数', value: dashboardData.conversionRate, suffix: '%',diffRate:dashboardData.conversionRateRate, percent: dashboardData.conversionRate, showProgress: true }
      ].map((item, index) => (
        <Col span={6} key={index}>
          <Card bodyStyle={{ padding: '10px' }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <Statistic
                  title={
                    <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                      {item.title}
                      <Popover content={item.desc} trigger="hover" style={{position: 'absolute', top: '0', right: '0'}}>
                        <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer', position: 'absolute', top: 12, right: 15 }}  />
                      </Popover>
                    </span>
                  }
                  value={item.value === null ? '--' : item.value}
                  prefix={item.suffix === '¥' && item.value !== null ? '¥' : ''}
                  suffix={item.suffix === '%' && item.value !== null ? '%' : ''}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                  <span style={{ marginRight: 8, fontSize: '12px' }}>较上一周期</span>
                  <span style={{ color: item.diffRate < 0 ? 'green' : item.diffRate > 0 ? 'red' : 'black' }}>
                    {item.diffRate > 0 ? <CaretUpOutlined /> : item.diffRate < 0 ? <CaretDownOutlined /> : null}
                    {item.diffRate === null ? '--' : `${Math.abs(item.diffRate)}%`}
                  </span>
                </div>
              </div>
              <div>
                {item.showProgress && item.value !== null && (
                  <Progress
                    type="circle"
                    percent={item.value}
                    size={70}
                    strokeWidth={16}
                    showInfo={false}
                    strokeColor="#1890ff"
                    style={{ marginTop: 16, marginLeft: 5, flex:1,fontSize: '12px' }}
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
        ))}
      </Row>

      <h3 style={{marginTop: '30px'}}>渠道</h3>
      <Row gutter={[16, 16]} style={{marginBottom: '20px'}}>
      <Col span={6}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px', paddingBottom: '5px'}}>
                  直播
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>用户在全端通过直播间点击商品卡片后进入商品详情页直接成交或加入购物车后成交的金额，不去除退款金额，不包含未知来源和赠品订单</p>
                        <p>注意：成交数据根据支付时间进行归属，跨天直播中，超过0点的数据计入后一日，未超过0点的数据计入前一日</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.liveSalesAmount ? dashboardData.liveSalesAmount / 10000 : 0}
              prefix="¥"
              suffix="万"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.liveSalesAmountRate > 0 ? 'red' : dashboardData.liveSalesAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.liveSalesAmountRate !== 0 && (dashboardData.liveSalesAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.liveSalesAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px', paddingBottom: '5px'}}>
                  短视频
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>用户在全端通过短视频点击商品卡片后进入商品详情页直接成交或加入购物车后成交的金额，不去除退款金额，不包含未知来源和赠品订单</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.shortVideoSalesAmount / 10000}
              prefix="¥"
              suffix="万"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.shortVideoSalesAmountRate > 0 ? 'red' : dashboardData.shortVideoSalesAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.shortVideoSalesAmountRate !== 0 && (dashboardData.shortVideoSalesAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.shortVideoSalesAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px', paddingBottom: '5px'}}>
                  商品卡
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>用户在全端通过非直播、非短视频、非图文页面点击商品卡片后进入商品详情页直接成交或加入购物车后成交的金额，不去除退款金额，不包含未知来源和赠品订单</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.productCardSalesAmount}
              prefix="¥"
              suffix="万"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.productCardSalesAmountRate > 0 ? 'red' : dashboardData.productCardSalesAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.productCardSalesAmountRate !== 0 && (dashboardData.productCardSalesAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.productCardSalesAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px', paddingBottom: '5px'}}>
                  图文
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>用户在全端通过图文点击商品卡片后进入商品详情页直接成交或加入购物车后成交的金额，不去除退款金额，不包含未知来源和赠品订单</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.graphicContentSalesAmount}
              prefix="¥"
              suffix="万"
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: dashboardData.graphicContentSalesAmountRate > 0 ? 'red' : dashboardData.graphicContentSalesAmountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {dashboardData.graphicContentSalesAmountRate !== 0 && (dashboardData.graphicContentSalesAmountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(dashboardData.graphicContentSalesAmountRate)}%
               </span>
            </div>
          </Card>
        </Col>
      </Row>

      <h3 style={{marginTop: '30px'}}>目标达成情况 <Button color="primary"  variant="text" style={{marginLeft: '10px',fontSize: '12px'}} icon={<LoginOutlined />} onClick={() => navigate('/target-review')}>创建目标</Button></h3>
      {dashboardData.targetData ? <Row gutter={16}>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px',height: '120px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  消耗GAP (万) 
                  <Popover content="目标消耗-实际消耗" trigger="hover">
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={(dashboardData.targetData.consumptionGap / 10000).toFixed(2)}
              valueStyle={{ color: '#3f8600' }}
            />
            <div style={{fontSize: '12px', marginTop: 10}}>预估消耗: {(dashboardData.targetData.qcEstimateConsumption / 10000).toFixed(2)} <span style={{marginLeft: 10}}>实际消耗: {(dashboardData.targetData.qcRealConsumption / 10000).toFixed(2)}</span></div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px',height: '120px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  销售GAP (万)
                  <Popover content="目标销售额-实际销售额" trigger="hover">
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={(dashboardData.targetData.salesGap / 10000).toFixed(2)}
              valueStyle={{ color: '#cf1322' }}
            />
            <div style={{fontSize: '12px', marginTop: 10}}>目标销售额: {(dashboardData.targetData.targetSalesAmount / 10000).toFixed(2)} <span style={{marginLeft: 10}}>实际销售额: {(dashboardData.targetData.realSalesAmount / 10000).toFixed(2)}</span></div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px', position: 'relative',height: '120px' }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Statistic
                title={
                  <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    完成率
                    <Popover content="实际销售额/目标销售额" trigger="hover" style={{position: 'absolute', top: '0', right: '0'}}>
                      <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer', position: 'absolute', top: 12, right: 15 }}  />
                    </Popover>
                  </span>
                }
                value={dashboardData.targetData.dayComplementRate || '--'}
                suffix="%"
              />
              <Progress
                type="circle"
                percent={dashboardData.targetData.dayComplementRate}
                size={80}
                strokeWidth={16}
                showInfo={false}
                strokeColor="#1890ff"
                style={{ marginTop: 20, marginLeft: 20,marginRight: 10 }}
              />
            </div>
            
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={{ padding: '10px',height: '120px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  ROI
                  <Popover placement="topRight" content="实际销售额（万）/千川实际消耗（万）" trigger="hover">
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={dashboardData.targetData.roi || '--'}
              precision={2}
            />
            <ReactECharts
              option={{
                grid: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                },
                xAxis: {
                  type: 'category',
                  show: false,
                  boundaryGap: false
                },
                yAxis: {
                  type: 'value',
                  show: false
                },
                series: [{
                  data: dashboardData.targetData.dayRois.map((item: { roi: number }) => item.roi),
                  type: 'line',
                  smooth: true,
                  symbol: 'none',
                  areaStyle: {
                    opacity: 0.3
                  }
                }],
                tooltip: {
                  trigger: 'axis',
                  formatter: (params: any) => {
                    const index = params[0].dataIndex;
                    const date = dashboardData.targetData.dayRois[index].date;
                    const roi = params[0].value;
                    return `${date}: ROI ${roi}`;
                  }
                }
              }}
              style={{ height: '30px', width: '100%' }}
            />
          </Card>
        </Col>
      </Row> : ''}
    </div>
  );
};

export default Dashboard;