import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Typography, Row, Col } from 'antd';
import BigNumber from 'bignumber.js';
import './Roi.css';

const { Title } = Typography;

const Roi: React.FC = () => {
  const [form] = Form.useForm();
  const [salesAmount, setSalesAmount] = useState<BigNumber>(new BigNumber(0));
  const [totalExpenses, setTotalExpenses] = useState<BigNumber>(new BigNumber(0));
  const [roi, setRoi] = useState<BigNumber>(new BigNumber(0));

  const calculateRoi = () => {
    if (salesAmount.isGreaterThan(0) && totalExpenses.isGreaterThan(0)) {
      setRoi(salesAmount.dividedBy(totalExpenses).decimalPlaces(2));
    } else {
      setRoi(new BigNumber(0));
    }
  };

  useEffect(() => {
    calculateRoi();
  }, [salesAmount, totalExpenses]);

  const onInputChange = (value: string, field: string) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    const parts = numericValue.split('.');
    let formattedValue = parts[0];
    if (parts.length > 1) {
      formattedValue += '.' + parts[1].slice(0, 2);
    }
    const amount = new BigNumber(formattedValue || 0);
    if (amount.isGreaterThanOrEqualTo(0)) {
      if (field === 'salesAmount') {
        setSalesAmount(amount);
      } else {
        form.setFieldsValue({ [field]: formattedValue });
        updateTotalExpenses();
      }
    }
  };
  
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      e.preventDefault();
    }
  };

  const calculateExpenseRatio = (expense: BigNumber) => {
    return salesAmount.isGreaterThan(0) 
      ? expense.dividedBy(salesAmount).multipliedBy(100).decimalPlaces(2)
      : new BigNumber(0);
  };

  const updateTotalExpenses = () => {
    const values = form.getFieldsValue();
    const total = Object.entries(values).reduce((acc: BigNumber, [key, val]: [string, unknown]) => {
      if (key !== 'salesAmount') {
        return acc.plus(new BigNumber(val as string || 0));
      }
      return acc;
    }, new BigNumber(0));
    setTotalExpenses(total);
  };

  const calculateCategoryTotal = (fields: string[]) => {
    const values = form.getFieldsValue();
    return fields.reduce((acc: BigNumber, field: string) => {
      return acc.plus(new BigNumber(values[field] || 0));
    }, new BigNumber(0));
  };

  const handleCalculate = () => {
    const values = form.getFieldsValue();
    const totalExpenses = Object.entries(values).reduce((acc: BigNumber, [key, val]: [string, unknown]) => {
      if (key !== 'salesAmount') {
        return acc.plus(new BigNumber(val as string || 0));
      }
      return acc;
    }, new BigNumber(0));
    setTotalExpenses(totalExpenses);
    calculateRoi();
  };

  return (
    <div className="roi-calculator">
      <Card>
        <Title level={2} className="roi-title" style={{fontSize: '19px'}}>投产计算器</Title>
        <Form form={form} name="roi-form" layout="horizontal" labelCol={{ flex: '120px' }} wrapperCol={{ flex: 'auto' }}>
          <h3 className="section-title">销售额</h3>
          <div className="divider">
            <div className="divider__highlight divider__highlight--lg" />
          </div>
          <Form.Item name="salesAmount" className="form-item">
            <Input
              addonBefore="销售额合计"
              addonAfter="元"
              allowClear
              className="form-input"
              onChange={(e) => onInputChange(e.target.value, 'salesAmount')}
              onKeyPress={onKeyPress}
            />
          </Form.Item>
          <h3 className="section-title--with-note">
            费用
            <Typography.Text type="secondary" className="section-note">
              注：百分比为费用项占月度销售额比例
            </Typography.Text>
          </h3>
          <div className="divider divider--sm">
            <div className="divider__highlight divider__highlight--md" />
          </div>
          <h4 className="form-section">
            <p className="text-wrapper">
              <span>日常管理费用：</span>
              <span className="text-value">
                {calculateCategoryTotal(['salary', 'office', 'travel', 'depreciation', 'otherDaily']).toFixed(2)}
              </span> 元
            </p>
            <p className="text-wrapper">
              <span className="text-percentage">
                费比：<i className="text-value">{calculateExpenseRatio(calculateCategoryTotal(['salary', 'office', 'travel', 'depreciation', 'otherDaily'])).toFixed(2)}%</i>
              </span>
            </p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="salary" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="工资及福利费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('salary') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'salary')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="office" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="办公费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('office') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'office')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="travel" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="差旅费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('travel') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'travel')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="depreciation" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="固定资产折旧费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('depreciation') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'depreciation')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="otherDaily" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="其他日常费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('otherDaily') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'otherDaily')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '850px', margin: 0}}>
            <p style={{margin: '5px 0'}}>
              <span>推广费用：</span>
              <span style={{color: '#1890ff',fontSize: '20px'}}>{calculateCategoryTotal(['infoFlow', 'infoFlowService', 'anchor', 'douPlus', 'shopGift', 'orderGift', 'douCoin', 'afterSale']).toFixed(2)}</span> 元
            </p>
            <p style={{margin: '5px 0'}}><span style={{marginLeft: '50px'}}>费比：<i style={{color: '#1890ff',fontSize: '20px'}}>{calculateExpenseRatio(calculateCategoryTotal(['infoFlow', 'infoFlowService', 'anchor', 'douPlus', 'shopGift', 'orderGift', 'douCoin', 'afterSale'])).toFixed(2)}%</i></span></p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="infoFlow" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="信息流费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('infoFlow') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'infoFlow')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="infoFlowService" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="信息流服务费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('infoFlowService') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'infoFlowService')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="anchor" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="主播费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('anchor') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'anchor')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="douPlus" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="DOU+费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('douPlus') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'douPlus')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="shopGift" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="店铺赠品费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('shopGift') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'shopGift')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="orderGift" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="随单赠品费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('orderGift') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'orderGift')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="douCoin" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="抖币红包费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('douCoin') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'douCoin')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="afterSale" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="售后补差费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('afterSale') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'afterSale')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '850px', margin: 0}}>
            <p style={{margin: '5px 0'}}>
              <span>促销优惠折扣：</span>
              <span style={{color: '#1890ff',fontSize: '20px'}}>{calculateCategoryTotal(['promotion']).toFixed(2)}</span> 元
            </p>
            <p style={{margin: '5px 0'}}><span style={{marginLeft: '50px'}}>费比：<i style={{color: '#1890ff',fontSize: '20px'}}>{calculateExpenseRatio(calculateCategoryTotal(['promotion'])).toFixed(2)}%</i></span></p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="promotion" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="优惠促销活动(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('promotion') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'promotion')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '850px', margin: 0}}>
            <p style={{margin: '5px 0'}}>
              <span>物流及包装费用：</span>
              <span style={{color: '#1890ff',fontSize: '20px'}}>{calculateCategoryTotal(['express', 'packaging', 'insurance', 'storage', 'labor']).toFixed(2)}</span> 元
            </p>
            <p style={{margin: '5px 0'}}><span style={{marginLeft: '50px'}}>费比：<i style={{color: '#1890ff',fontSize: '20px'}}>{calculateExpenseRatio(calculateCategoryTotal(['express', 'packaging', 'insurance', 'storage', 'labor'])).toFixed(2)}%</i></span></p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="express" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="快递费用(顺丰+中通)(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('express') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'express')}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="packaging" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="包装物料费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('packaging') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'packaging')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="insurance" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="运费险(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('insurance') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'insurance')}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="storage" style={{marginBottom: '10px'}}>
              <Input
                  addonBefore="异地仓储物流服务费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('storage') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'storage')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="labor" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="打包劳务费(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('labor') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'labor')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '850px', margin: 0}}>
            <p style={{margin: '5px 0'}}>
              <span>平台佣金及平台其他费用：</span>
              <span style={{color: '#1890ff',fontSize: '20px'}}>{calculateCategoryTotal(['platformCommission', 'otherPlatformFees']).toFixed(2)}</span> 元
            </p>
            <p style={{margin: '5px 0'}}><span style={{marginLeft: '50px'}}>费比：<i style={{color: '#1890ff',fontSize: '20px'}}>{calculateExpenseRatio(calculateCategoryTotal(['platformCommission', 'otherPlatformFees'])).toFixed(2)}%</i></span></p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="platformCommission" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="产品平台佣金(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('platformCommission') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'platformCommission')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="otherPlatformFees" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="平台其他费用(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('otherPlatformFees') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'otherPlatformFees')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '850px', margin: 0}}>
            <p style={{margin: '5px 0'}}>
              <span>代运营服务费：</span>
              <span style={{color: '#1890ff',fontSize: '20px'}}>{calculateCategoryTotal(['broadcastingFee', 'commission']).toFixed(2)}</span> 元
            </p>
            <p style={{margin: '5px 0'}}><span style={{marginLeft: '50px'}}>费比：<i style={{color: '#1890ff',fontSize: '20px'}}>{calculateExpenseRatio(calculateCategoryTotal(['broadcastingFee', 'commission'])).toFixed(2)}%</i></span></p>
          </h4>
          <Row gutter={16}>
            <Col style={{width: '450px'}}>
              <Form.Item name="broadcastingFee" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="代播基础服务费(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('broadcastingFee') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'broadcastingFee')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
            <Col style={{width: '450px'}}>
              <Form.Item name="commission" style={{marginBottom: '10px'}}>
                <Input
                  addonBefore="佣金(元)"
                  addonAfter={`${calculateExpenseRatio(new BigNumber(form.getFieldValue('commission') || 0)).toFixed(2)}%`}
                  style={{ width: '400px' }}
                  onChange={(e) => onInputChange(e.target.value, 'commission')}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </Col>
          </Row>
          </Form>
          {/* <Button type="primary" htmlType="submit" onClick={handleCalculate}>
              开始计算
          </Button> */}
          <h2 className="section-title">计算结果</h2>
          <div className="divider">
            <div className="divider__highlight divider__highlight--lg" />
          </div>

          <p className="text-label">
            月度销售额合计：
            <span className="text-value text-value--bold">{salesAmount.toFixed(2)}</span> 元
          </p>
          <p className="text-label">
            月度费用合计：
            <span className="text-value text-value--bold">{totalExpenses.toFixed(2)}</span> 元
          </p>
          <p className="text-label">
            月度整体ROI：
            <span className="text-value text-value--bold">{roi.toFixed(2)}</span>
          </p>
      </Card>
    </div>
  );
};

export default Roi;
