import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Select, Button, Space, Radio, Input, Tooltip, message, Cascader } from 'antd';
import { SettingFilled, QuestionCircleOutlined, CopyOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import IndicatorModal from '../components/IndicatorModal/IndicatorModal';
import dayjs from 'dayjs';
import api from '../../../utils/api';
import './GoodsList.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;


interface Account {
  id: number;
  name: string;
}

interface SalesPromotion {
  id: string;
  name: string;
  start: string;
  end: string;
}

interface IndicatorItem {
  title: string;
  key: string;
  tip?: string;
  isPercentage?: boolean;
  width?: number;
  suffix?: string;
  compare?: string;
}
interface CategoryOption {
  id: string;
  name: string;
  children?: CategoryOption[];
  isLeaf?: boolean;
}

const LiveBroadcastList: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs().subtract(1, 'day').endOf('day')
  ]);
  const [isInit, setIsInit] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [channelCode, setChannelCode] = useState<number | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [sellTypeCode, setSellTypeCode] = useState<string | null>(null);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const [indicatorConfig, setIndicatorConfig] = useState<Record<string, number>>({});
  const [isIndicatorConfigVisible, setIsIndicatorConfigVisible] = useState(false);
  const [compareType, setCompareType] = useState('1');
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      initData();
      fetchCategories();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
    
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchData();
    }
  }, [isInit]);

  const initData = async () => {
    await Promise.all([
      fetchIndicator(),
      fetchAccounts(),
      fetchSalesPromotions()
      
    ]);
    setIsInit(true)
  }

  const fetchData = async (newPage?: number, newPageSize?: number, newIndicatorConfig?: Record<string, number>) => {
    if (!selectedAccount || Object.keys(indicatorConfig).length === 0) {
      console.error('Selected account or indicator config is not set');
      return;
    }

    setLoading(true);
    try {
        const [start, end] = dateRange;
        let compareStart: dayjs.Dayjs | undefined;
        let compareEnd: dayjs.Dayjs | undefined;
        switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
        }
      const response = await api.post(`/product/page/${newPage || page}/size/${newPageSize || pageSize}`, {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),     
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        productId: productId,
        channelCode: channelCode,
        category: category,
        sellTypeCode: sellTypeCode,
        productName: productName,
        indicator: newIndicatorConfig || indicatorConfig,
      });
      if(response.data.code === 1) {
        setData(response.data.data.records);
        setTotal(response.data.data.total);
      }
    } catch (error) {
      console.error('Failed to fetch live broadcast list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveIndicatorConfig = async (newIndicatorConfig: Record<string, number>) => {
    try {
      await fetchData(page, pageSize, newIndicatorConfig);
      fetchIndicator();
    } catch (error) {
      console.error('Failed to save indicator config:', error);
      throw error;
    }
  };


  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const fetchIndicator = async () => {
    try {
      const response = await api.get('/product/indicator');
      if (response.data.code === 1) {
        setIndicatorConfig(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch indicator config:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/product/categories');
      if (response.data.code === 1) {
        setCategoryOptions(response.data.data || []);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
      return [];
    }
  };

  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const endDate = dayjs().subtract(1, 'day').endOf('day');
    let startDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
    }
    
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const downloadData = async () => {
    try {
      const [start, end] = dateRange;
        let compareStart: dayjs.Dayjs | undefined;
        let compareEnd: dayjs.Dayjs | undefined;
        switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
        }
      const response = await api.post('/product/download', {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),     
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        productId: productId,
        channelCode: channelCode,
        category: category,
        sellTypeCode: sellTypeCode,
        productName: productName,
        indicator: indicatorConfig,
      }, {
        responseType: 'blob'
      });

      if (response.headers['content-type']?.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonResponse = JSON.parse(reader.result as string);
          if (jsonResponse.code !== 1) {
            throw new Error(jsonResponse.message || '明细下载失败');
          }
        };
        reader.readAsText(response.data);
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '商品列表明细.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('明细下载成功');
    } catch (error) {
      console.error('Failed to download template:', error);
      message.error('明细下载失败');
    }
  };

  const getIndicatorColumns = (): ColumnsType<any[]> => {
    const indicatorColumns: ColumnsType<any[]> = [];

    // 遍历 indicatorConfig，根据配置添加列
    Object.entries(indicatorConfig).forEach(([key, value]) => {
      if (value === 1) {
        let columnConfig;
        Object.values(indicatorMap).some((category: any) => {
          columnConfig = category.list.find((item: any) => item.key === key);
          return !!columnConfig;
        });

        if (columnConfig) {
          const config = columnConfig as IndicatorItem;
          indicatorColumns.push({
            title: config.tip ? (
              <span>
                {config.title}
                <Tooltip title={config.tip}>
                  <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
              </span>
            ) : config.title,
            dataIndex: key,
            key: key,
            width: config.width || 150,
            render: (text: number, record: any) => {
              const compareValue = config.compare ? record[config.compare as string] : null;
              return (
                <div>
                  <div>{text || '-'}{config.suffix && <span>{config.suffix}</span>}</div>
                  {compareValue !== null && compareValue !== 0 && (
                    <div style={{ 
                      fontSize: '12px', 
                      color: compareValue > 0 ? 'red' : 'green'
                    }}>
                      {compareValue > 0 ? '↑' : '↓'} {Math.abs(compareValue)}%
                    </div>
                  )}
                </div>
              );
            }
          });
        }
      }
    });

    return indicatorColumns;
  };

  const indicatorMap = {
    trade: {
      title: '交易指标',
      key: 'trade',
      list: [
        {
          title: '成交金额',
          key: 'transactionAmount',
          width: 120,
          tip: '全端支付成功或货到付款确认的订单金额汇总，包含退款金额',
          compare: 'transactionAmountRate'
        },
        {
          title: '成交订单数',
          key: 'transactionOrderCount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的成交订单数量',
          compare: 'transactionOrderCountRate'
        },
        {
          title: '成交人数',
          key: 'transactionCustomerCount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品有成交的人数',
          compare: 'transactionCustomerCountRate'
        },
        {
          title: '成交件数',
          key: 'transactionItemCount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的成交件数（多个订单可能包含多件商品）',
          compare: 'transactionItemCountRate'
        },
        {
          title: '成交客单价',
          key: 'averageTransactionPrice',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品成交金额（不剔除退货、退款等）/商品成交人数',
          compare: 'averageTransactionPriceRate'
        },
        {
          title: '成交金额（剔除退款）',
          key: 'transactionAmountExcludingReturns',
          width: 180,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的成交金额（剔除退货、退款等）',
          compare: 'transactionAmountExcludingReturnsRate'
        },
        {
          title: '成交订单数（剔除退款）',
          key: 'transactionOrderCountExcludingReturns',
          width: 200,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的（剔除退货、退款等）',
          compare: 'transactionOrderCountExcludingReturnsRate'
        },
        {
          title: '实际结算金额',
          key: 'actualSettlementAmount',
          width: 150,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，符合结算规则的成交订单在成交后一段时间内的订单金额（订单确认收货+7天，订单没有确认收货的15天+7天），成交金额和实际结算金额是错开统计周期的指标，两者不可直接对比',
          compare: 'actualSettlementAmountRate'
        },
        {
          title: '实际佣金支出',
          key: 'actualCommissionExpenditure',
          width: 150,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品产生的实际佣金支出，实际佣金支出是按结算周期算的，如订单在1号成交，佣金在15号结算，实际佣金支出会记在15号。',
          compare: 'actualCommissionExpenditureRate'
        },
        {
          title: '预售订单数',
          key: 'presaleOrderCount',
          width: 150,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，预售商品的订单数',
          compare: 'presaleOrderCountRate'
        },
        {
          title: '预售创建金额',
          key: 'presaleCreationAmount',
          width: 150,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，预售商品产生的订单金额',
          compare: 'presaleCreationAmountRate'
        },
        {
          title: '上架时间',
          key: 'upDate',
          width: 120
        }
      ]
    },
    refund: {
      title: '退款指标',
      key: 'refund',
      list: [
        {
          title: '退款金额',
          key: 'refundAmount',
          width: 120,
          tip:'所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品为成功退款状态的退款金额，不考虑售后发起时间',
          compare: 'refundAmountRate'
        },
        {
          title: '退款订单数',
          key: 'refundOrderCount',
          width: 120,
          tip:'所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品为成功退款状态的退款订单数，不考虑售后发起时间',
          compare: 'refundOrderCountRate'
        },
        {
          title: '退款人数',
          key: 'refundCustomerCount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品为成功退款状态的退款人数，不考虑售后发起时间',
          compare: 'refundCustomerCountRate'
        },{
          title: '退款件数',
          key: 'refundItemCount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品为成功退款状态的退款件数（一个订单可包含多件商品），不考虑售后发起时间',
          compare: 'refundItemCountRate'
        },{
          title: '成交退款金额',
          key: 'transactionRefundAmount',
          width: 120,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品成功支付且产生的累积退款金额，退款金额按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款金额计入1号',
          compare: 'transactionRefundAmountRate'
        },{
          title: '成交退款订单数',
          key: 'transactionRefundOrderCount',
          width: 140,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品成功支付且产生的退款订单数，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款订单数计入1号',
          compare: 'transactionRefundOrderCountRate'
        },{
          title: '成交退款率',
          key: 'transactionRefundRate',
          width: 120,
          suffix: '%',
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，成交退款订单数/成交订单数',
          compare: 'transactionRefundRateRate'
        },{
          title: '发货前成交退款率',
          key: 'preDeliveryTransactionRefundRate',
          width: 160,
          suffix: '%',
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货前成交退款订单数/成交订单数，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款订单计入1号',
          compare: 'preDeliveryTransactionRefundRateRate'
        },{
          title: '发货后成交退款率',
          key: 'postDeliveryTransactionRefundRate',
          width: 160,
          suffix: '%',
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货后成交退款订单数/成交订单数，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款订单计入1号',
          compare: 'postDeliveryTransactionRefundRateRate'
        },{
          title: '发货前成交退款金额',
          key: 'preDeliveryTransactionRefundAmount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货前成交退款订单金额，退款金额按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款金额计入1号',
          compare: 'preDeliveryTransactionRefundAmountRate'
        },{
          title: '发货前成交退款订单数',
          key: 'preDeliveryTransactionRefundOrderCount',
          width: 180,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货前成交退款订单数，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款订单计入1号',
          compare: 'preDeliveryTransactionRefundOrderCountRate'
        },{
          title: '发货前成交退款人数',
          key: 'preDeliveryTransactionRefundCustomerCount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货前成交后退款的人数，退款订单按【支付时间】计算，如1号某人成交100元商品，10号退款，则成交退款人数计入1号',
          compare: 'preDeliveryTransactionRefundCustomerCountRate'
        },{
          title: '发货前成交退款件数',
          key: 'preDeliveryTransactionRefundItemCount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货前成交退款商品件数，一个订单可包含多件商品，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款件数计入1号',
          compare: 'preDeliveryTransactionRefundItemCountRate'
        },{
          title: '发货后成交退款金额',
          key: 'postDeliveryTransactionRefundAmount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货后成交退款订单金额，退款金额按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款金额计入1号',
          compare: 'postDeliveryTransactionRefundAmountRate'
        },{
          title: '发货后成交退款订单数',
          key: 'postDeliveryTransactionRefundOrderCount',
          width: 180,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货后成交退款订单数，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款订单计入1号',
          compare: 'postDeliveryTransactionRefundOrderCountRate'
        },{
          title: '发货后成交退款人数',
          key: 'postDeliveryTransactionRefundCustomerCount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货后成交后退款的人数，退款订单按【支付时间】计算，如1号某人成交100元商品，10号退款，则成交退款人数计入1号',
          compare: 'postDeliveryTransactionRefundCustomerCountRate'
        },{
          title: '发货后成交退款件数',
          key: 'postDeliveryTransactionRefundItemCount',
          width: 160,
          tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，发货后成交退款商品件数，一个订单可包含多件商品，退款订单按【支付时间】计算，如1号成交100元商品，10号退款，则成交退款件数计入1号',
          compare: 'postDeliveryTransactionRefundItemCountRate'
        }
      ]
    },
    flow: {
      title: '流量指标',
      key: 'flow',
      list: [
          {
            title: '商品曝光人数',
            key: 'productExposurePeopleCount',
            width: 120,
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的曝光人数',
            compare: 'productExposurePeopleCountRate'
          },
          {
            title: '商品曝光次数',
            key: 'productExposureCount',
            width: 120,
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的曝光次数（一个人可以被多次曝光）',
            compare: 'productExposureCountRate'
          },
          {
            title: '商品点击人数',
            key: 'productClickPeopleCount',
            width: 120,
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的点击人数',
            compare: 'productClickPeopleCountRate'
          },
          {
            title: '商品点击次数',
            key: 'productClickCount',
            width: 120,
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品的点击次数（一个人可以多次点击）',
            compare: 'productClickCountRate'
          },
          {
            title: '曝光点击率（人数）',
            key: 'exposureClickRate',
            width: 170,
            suffix: '%',
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品点击人数/商品曝光人数',
            compare: 'exposureClickRateRate'
          },
          {
            title: '点击支付率（人数）',
            key: 'clickPayRate',
            width: 160,
            suffix: '%',
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品支付人数/商品点击人数',
            compare: 'clickPayRateRate'
          },
          {
            title: '曝光支付率（人数）',
            key: 'exposurePayRate',
            width: 160,
            suffix: '%',
            tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品支付人数/商品曝光人数',
            compare: 'exposurePayRateRate'
          }
      ]
    },
    collect: {
      title: '收藏加购',
      key: 'collect',
      list: [{
        title: '加购人数',
        key: 'cartCustomerCount',
        width: 120,
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品加购物车的人数',
        compare: 'cartCustomerCountRate'
      },
      {
        title: '访问加购转化率',
        key: 'visitToCartConversionRate',
        width: 140,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品点击加购人数/商品展现人数',
        compare: 'visitToCartConversionRateRate'
      },{
        title: '加购支付转化率',
        key: 'cartToPaymentConversionRate',
        width: 140,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品支付人数/商品加购人数',
        compare: 'cartToPaymentConversionRateRate'  
      },{
        title: '收藏人数',
        key: 'collectionCustomerCount',
        width: 120,
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品被收藏的人数',
        compare: 'collectionCustomerCountRate'
      },{
        title: '访问收藏转化率',
        key: 'visitToCollectionConversionRate',
        width: 140,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品收藏人数/商品展现人数',
        compare: 'visitToCollectionConversionRateRate'
      },{
        title: '收藏支付转化率',
        key: 'collectionToPaymentConversionRate',
        width: 140,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商品收藏人数/商品支付人数',
        compare: 'collectionToPaymentConversionRateRate'
      }]
    },
    afterSale: {
      title: '售后指标',
      key: 'afterSale',
      list: [{
        title: '评价好评率',
        key: 'evaluationGoodRate',
        width: 120,
        suffix: '%',
        tip: '商品评价结果为【好评】的评价数占比，好评率=好评数/评价总数。不包含申诉后执行剔除的数据',
        compare: 'evaluationGoodRateRate'
      },
      {
        title: '好评数',
        key: 'goodEvaluationCount',
        width: 120,
        tip: '商品评价结果为【好评】的评价数',
        compare: 'goodEvaluationCountRate'
      },{
        title: '商品差评订单数',
        key: 'productBadReviewOrderCount',
        width: 140,
        tip: '商品评分为差评（即1星或2星）的订单数。不计算异常评价的订单。',
        compare: 'productBadReviewOrderCountRate'
      },{
        title: '商品差评率',
        key: 'productBadReviewRate',
        width: 120,
        suffix: '%',
        tip: '商品评分为差评（即1星或2星）的订单数 / 物流签收订单数。不计算异常评价的订单',
        compare: 'productBadReviewRateRate'
      },{
        title: '评价差评率',
        key: 'evaluationBadRate',
        width: 120,
        suffix: '%',
        tip: '商品评价结果为【差评】的评价数占比，差评率=差评数/评价总数。不包含申诉后执行剔除的数据',
        compare: 'evaluationBadRateRate'
      },{
        title: '商品品质退货单量',
        key: 'productQualityReturnOrderCount',
        width: 150,
        tip: '因商品品质原因产生退换货的订单数',
        compare: 'productQualityReturnOrderCountRate'
      },{
        title: '商品品质退货率',
        key: 'productQualityReturnRate',
        width: 140,
        suffix: '%',
        tip: '因商品品质原因产生退换货的订单数/ 物流签收订单数',
        compare: 'productQualityReturnRateRate'
      },{
        title: '发货物流品质退货单量',
        key: 'deliveryLogisticsQualityReturnOrderCount',
        width: 180,
        tip: '支付订单中因发货物流问题产生退换货的订单数',
        compare: 'deliveryLogisticsQualityReturnOrderCountRate'
      },{
        title: '发货物流品质退货件数',
        key: 'deliveryLogisticsQualityReturnItemCount',
        width: 180,
        tip: '支付订单中因发货物流问题产生退换货的商品件数',
        compare: 'deliveryLogisticsQualityReturnItemCountRate'
      },{
        title: '发货物流品质退货率',
        key: 'deliveryLogisticsQualityReturnRate',
        width: 160,
        suffix: '%',
        tip: '支付订单中因发货物流问题产生退换货的订单数/ 支付订单数',
        compare: 'deliveryLogisticsQualityReturnRateRate'
      },{
        title: '投诉工单量',
        key: 'complaintWorkOrderCount',
        width: 120,
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，支付订单中产生投诉的工单总数，不包含不可抗力影响等异常单',
        compare: 'complaintWorkOrderCountRate'
      },{
        title: '投诉率',
        key: 'complaintRate',
        width: 120,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，支付订单中产生投诉的工单总数/支付订单总数，不包含不可抗力影响等异常单',
        compare: 'complaintRateRate'
      },{
        title: '客服不满意会话量',
        key: 'unsatisfactoryCustomerConversationCount',
        width: 140,
        compare: 'unsatisfactoryCustomerConversationCountRate'
      }]
    },
    detail: {
      title: '商详指标',
      key: 'detail',
      list: [{
        title: '商详页曝光人数',
        key: 'productDetailsPageExposurePeopleCount',
        width: 140,
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，用户在抖音/抖音极速版，点击商品卡片后进入的商品详情页的曝光人数',
        compare: 'productDetailsPageExposurePeopleCountRate'
      },
      {
        title: '商详页曝光点击率',
        key: 'productDetailsPageExposureClickRate',
        width: 150,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商详页点击人数/商详页曝光人数',
        compare: 'productDetailsPageExposureClickRateRate'
      },{
        title: '商详页成交转化率',
        key: 'productDetailsPageTransactionConversionRate',
        width: 150,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，商详页成交人数/商详页点击人数',
        compare: 'productDetailsPageTransactionConversionRateRate'
      },{
        title: '商详页成交金额',
        key: 'productDetailsPageTransactionAmount',
        width: 140,
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，用户在抖音/抖音极速版，点击商品卡片后进入的商品详情页的成交金额',
        compare: 'productDetailsPageTransactionAmountRate'
      },{
        title: '商详页跳失率',
        key: 'productDetailsPageBounceRate',
        width: 120,
        suffix: '%',
        tip: '所选时间范围内，所选售卖类型、载体渠道、商品品类下，用户在抖音/抖音极速版，点击商品卡片后进入的商品详情页的跳失人数/商详页曝光人数',
        compare: 'productDetailsPageBounceRateRate'
      }]
    },
  }

  const getColumns: ColumnsType<any[]> = [
    {
      title: '商品名称',
      dataIndex: 'goodsInfo',
      key: 'goodsInfo',
      width: 300,
      fixed: 'left',
      render: (_, record: any) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={record.imageUrl} style={{width: 50, height: 50}}/>
          <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
            <Tooltip title={record.name}>
              <span className="max-w-[180px] truncate">
                {record.name}
              </span>
            </Tooltip>
            <div style={{color: '#999'}}>
              <span className="text-gray-400 text-sm">
                {record.id}
              </span>
              <Button
                type="text"
                style={{marginLeft: 5}}
                icon={<CopyOutlined style={{color: '#999'}} />}
                onClick={() => {
                  navigator.clipboard.writeText(record.id);
                }}
              />
            </div>
          </div>
        </div>
      )
    },
    ...getIndicatorColumns(),
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (_, record:any) => (
          record.url && <Button
            onClick={() => {
              navigator.clipboard.writeText(record.url);
            }}
            className="text-blue-500 hover:text-blue-700"
            color="primary"  variant="text"
          >
            复制链接
          </Button>
        )
      }
  ];

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
  };

  const changeProductName = (value: string) => {
    setProductName(value);
  };

  const changeProductId = (value: string) => {
    setProductId(value);
  };

  

  return (
    <div className="goods-list">
      <div className="filters">
        <Space style={{display: 'flex', flexWrap: 'wrap'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px',width: '70px'}}>商品ID</span>
            <Input 
              value={productId} 
              onChange={(e) => changeProductId(e.target.value)} 
              style={{width: 140, minWidth: 140}} 
              placeholder='精确查询'
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '40px'}}>日期</span>
            <RangePicker 
              value={dateRange}
              allowClear={false}
              style={{minWidth: '230px', width: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            style={{minWidth: 290}}
            options={[
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' },
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
          <Select
            style={{ minWidth: 80, maxWidth: 160 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
          <div style={{marginLeft: '5px', minWidth: '200px'}}>
            <span style={{minWidth: '70px', marginRight: 5}}>对比周期</span>
            <Select
              style={{minWidth: 120}}
              placeholder="请选择"
              value={compareType}
              onChange={onCompareTypeChange}
              options={[
                { value: '1', label: '连续周期' },
                { value: '2', label: '上月同期' },
                { value: '3', label: '上季度同期' },
                { value: '4', label: '去年同期' }
              ]}
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px', width: '70px',display: 'flex', alignItems: 'center'}}>载体渠道</span>
            <Select
            style={{ minWidth: 140, maxWidth: 140 }}
            placeholder="请选择"
            value={channelCode}
            allowClear
            onChange={(value) => setChannelCode(value)}
            options={[
              { value: 1, label: '直播' },
              { value: 2, label: '短视频' },
              { value: 3, label: '商品卡' },
              { value: 4, label: '图文' }
            ]}
          />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px',width: '70px'}}>商品名称</span>
            <Input 
              value={productName} 
              onChange={(e) => changeProductName(e.target.value)} 
              style={{width: 140, minWidth: 140}} 
              placeholder='模糊查询'
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px', width: '70px',display: 'flex', alignItems: 'center'}}>售卖类型</span>
            <Select
            style={{ minWidth: 140, maxWidth: 140 }}
            placeholder="请选择"
            value={sellTypeCode}
            allowClear
            onChange={(value) => setSellTypeCode(value)}
            options={[
              { value: 1, label: '自营' },
              { value: 2, label: '合作' }
            ]}
          />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px', width: '70px',display: 'flex', alignItems: 'center'}}>商品类目</span>
            <Cascader
              style={{ minWidth: 200 }}
              options={categoryOptions}
              fieldNames={{ label: 'name', value: 'id' }}
              onChange={(value) => setCategory(value ? value.join('-') : null)}
              changeOnSelect
              placeholder="请选择"
            />
          </div>
        </Space>
      </div>
      <p style={{marginBottom: 10, display: 'flex', justifyContent: 'flex-end', marginTop: 0}}>
        <Button type="primary" onClick={() => fetchData()}>
            查询
        </Button>
        <Button style={{marginLeft: 5}} onClick={downloadData}>下载明细</Button>
        <Button
          icon={<SettingFilled />}
          style={{marginLeft: 5}}
          onClick={() => setIsIndicatorConfigVisible(true)}
        >
          指标配置
        </Button>
      </p>
      <Table
        style={{display: Object.keys(indicatorConfig).length > 0 ? 'block' : 'none'}}
        bordered
        columns={getColumns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        scroll={{ x: 'max-content', y: 450 }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          onChange: (page, pageSize) => fetchData(page, pageSize),
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          }}
        />
      <IndicatorModal
        isVisible={isIndicatorConfigVisible}
        onClose={() => setIsIndicatorConfigVisible(false)}
        onSave={handleSaveIndicatorConfig}
        indicator={indicatorMap}
        indicatorConfig={indicatorConfig}
      />
    </div>
  );
};

export default LiveBroadcastList;
