// 头部模块组件
import React, { useState, useEffect } from 'react';
import {
    MenuUnfoldOutlined, 
    MenuFoldOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import './HeaderModule.sass';
import { useUser } from '../../context/UserContext'; // 导入 useUser 钩子

interface HeaderModuleProps {
  collapsed?: boolean; 
  onlyHeader?: boolean;
  onToggle?: () => void; // 接收切换侧边栏的函数
}

const HeaderModule: React.FC<HeaderModuleProps> = ({ collapsed, onlyHeader, onToggle }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { user, loading } = useUser(); // 获取用户信息和加载状态

  // 全屏事件
  const handleToggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  };
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // 处理退出登录逻辑
  const handleLogout = () => {
    // 删除cookie
    document.cookie = 'live_polit_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // 重定向到登录页面
    window.location.href = '/#/login';
  };

  if (loading) {
    return <div></div>; // 加载状态
  }

  return (
    <div className="header-module" style={{ width: onlyHeader ? '100vw' : collapsed ? 'calc(100vw - 80px)' : 'calc(100vw - 220px)' }}>
      {onlyHeader ? (<div></div>) : (
        <Button
          className="menu-icon"
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={onToggle}
        />
      )}
      <div className="header-module-right">
      <Tooltip title={isFullscreen ? "退出全屏" : "全屏"}>
        <Button
          className="fullscreen-icon"
          type="text"
          icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={handleToggleFullscreen}
        />
      </Tooltip>
      <span style={{ marginRight: 20, fontSize: 18 }}>{user?.username}</span>
      <Tooltip title="退出登录">
        <Button
          color="danger"
          variant="filled"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          className="logout-btn"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default HeaderModule;