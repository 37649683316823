import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, message } from 'antd';
import './IndicatorModal.scss';

interface IndicatorModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: (newConfig: Record<string, number>) => void;
  indicator: Record<string, any>;
  indicatorConfig: Record<string, number>;
}

const defaultSelectedKeys = [
  'transactionAmount', //成交金额
  'transactionOrderCount', //成交单数
  'transactionCustomerCount', //成交人数
  'productExposurePeopleCount', //商品曝光人数
  'productClickPeopleCount', //商品点击人数
  'exposureClickRate', //曝光点击率（人数）
  'clickPayRate', //点击支付率（人数）
  'upDate'//上架时间
];

const IndicatorModal: React.FC<IndicatorModalProps> = ({
  isVisible,
  onClose,
  onSave,
  indicator,
  indicatorConfig,
}) => {
  const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);

  useEffect(() => {
    if (isVisible) {
      const initialSelected = Object.entries(indicatorConfig)
      .filter(([_, value]) => value === 1)
      .map(([key]) => key);

    setSelectedIndicators(initialSelected.length >= 1 ? initialSelected : defaultSelectedKeys);
    }
  }, [isVisible]);


  const handleIndicatorChange = (checkedValues: any[]) => {
    setSelectedIndicators(checkedValues as string[]);
  };

  const handleRestoreDefaults = () => {
    setSelectedIndicators(defaultSelectedKeys);
  };

  const handleSave = () => {
    if (selectedIndicators.length < 3) {
      message.error('请至少选择3个指标');
      return;
    }
    const newConfig: Record<string, number> = {};
    Object.keys(indicatorConfig).forEach(key => {
      newConfig[key] = selectedIndicators.includes(key) ? 1 : 0;
    });
    onSave(newConfig);
    onClose();
  };

  return (
    <Modal
      title="指标配置"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="restore" onClick={handleRestoreDefaults}>
          恢复默认
        </Button>,
        <Button key="cancel" onClick={onClose}>
          取消
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          保存
        </Button>,
      ]}
      width={900}
    >
      <div className="goods-list-indicator-config-content">
        <Checkbox.Group value={selectedIndicators} onChange={handleIndicatorChange}>
          {Object.entries(indicator).map(([groupKey, group]) => (
            <div key={groupKey} className="indicator-group" style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}>
              <h3 style={{width: '100px', minWidth: '100px',marginTop: '0', marginRight: '10px', color: '#666'}}>
                {group.icon} {group.title}
              </h3>
              <div>
                {group.list.map((item: any) => (
                  <Checkbox key={item.key} value={item.key} style={{marginRight: '16px', marginBottom: '8px', width: '200px'}}>
                    {item.title}
                  </Checkbox>
                ))}
              </div>
            </div>
          ))}
        </Checkbox.Group>
      </div>
    </Modal>
  );
};

export default IndicatorModal;