import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Select, Button, Statistic, Radio, Tabs, Tooltip, message, Popover, Card, Col, Row, Carousel, Checkbox } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, InfoCircleOutlined, CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ReactECharts from 'echarts-for-react';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import api from '../../../utils/api';
import './ShortVideoAnalysis.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;


interface Account {
  id: number;
  name: string;
}
interface SalesPromotion {
    id: string;
    name: string;
    start: string;
    end: string;
}
// 添加指标类型定义
interface Metric {
  name: string;
  total: number;
  rate: number | null;
  dataList: Array<{ date: string; value: number }>;
  isPercentage?: boolean;
  prefix?: string;
}
interface ChannelAnalysisType {
  channelType: number | null;
  channelName: string | null;
  transactionAmount: number | null;
  videoViewCount: number | null;
  transactionOrderCount: number | null;
  productExposureTransactionRate: number | null;
  children?: ChannelAnalysisType[] | null;
}
interface MetricConfig {
  title: string;
  tooltip: string;
  prefix?: string;
  suffix?: string;
  order: {
    bound: number | null;
    unbound: number | null;
  };
}
const METRICS_CONFIG: Record<string, MetricConfig> = {
  transactionAmount: {
    title: '成交金额',
    prefix: '¥',
    tooltip: '所选时间范围内，短视频绑定商品的支付金额（含自然流量和付费流量带来的订单）。下单途径：1、直接从短视频购买的订单支付金额；2、从短视频加入购物车再从购物车结算的订单支付金额。支付后因各种原因取消的订单支付金额（如：退货/退款）不会被剔除，依然会计入本指标中。注意：不包含引流到其他载体的成交金额（如：引流到直播间购买）',
    order: { bound: 1, unbound: 13 }
  },
  transactionOrderCount: {
    title: '成交订单数',
    tooltip: '所选时间范围内，用户通过短视频上的购物入口成交的总订单数（含自然流量和付费流量带来的订单）。下单途径：1、用户点击购物车商品，直接下单购买；2、用户先将短视频挂的商品加入购物车，再去购物车结算（从购物车结算不需要打开短视频，因此可能出现短视频某天有成交但无播放数的情况）',
    order: { bound: 2, unbound: 14 }
  },
  refundAmount: {
    title: '退款金额',
    prefix: '¥',
    tooltip: '所选时间范围内，退款成功的退款金额，仅包含走售后系统且退款成功的订单（含货到付款），不含线下退款（如先行赔付、私下打款等），包含发货前和发货后退款',
    order: { bound: 3, unbound: 15 }
  },
  videoViewCount: {
    title: '短视频观看次数',
    tooltip: '所选时间范围内，作品被观看的次数，包含自然流量和付费流量数据',
    order: { bound: 4, unbound: 1 }
  },
  productExposureCount: {
    title: '商品曝光次数',
    tooltip: '所选时间范围内，短视频上所有商品入口被看到的次数，包含短视频上的购物车、短视频评论区置顶的购物入口。',
    order: { bound: 5, unbound: 9 }
  },
  productClickCount: {
    title: '商品点击次数',
    tooltip: '所选时间范围内，短视频上所有商品入口被点击的次数，包含短视频上的购物车、短视频评论区置顶的购物入口',
    order: { bound: 6, unbound: 10 }
  },
  productThousandExposureTransaction: {
    title: '商品千次曝光成交',
    prefix: '¥',
    tooltip: '所选时间范围内，短视频订单金额 / 购物车曝光次数 * 1000',
    order: { bound: 7, unbound: 11 }
  },
  productExposureTransactionRate: {
    title: '商品曝光成交转化率',
    suffix: '%',
    tooltip: '所选时间范围内，通过短视频成交的订单数 / 短视频商品曝光次数',
    order: { bound: 8, unbound: 12 }
  },
  liveRoomDiversionCount: {
    title: '引流直播间次数',
    tooltip: '所选时间范围内，自然流量、付费流量带来的用户，点击短视频中作者头像呼吸灯进入直播间的次数（只计算为带货直播间引流的次数）;注意：广告视频可能存在多个进入直播间的入口，本指标只统计点击视频上头像呼吸进入直播间的数据',
    order: { bound: 9, unbound: 2 }
  },
  clickFollowCount: {
    title: '点击关注次数',
    tooltip: '所选时间范围内，用户点击短视频上作者头像"+"号的次数（关注后再取消关注，数据不会被剔除，依然会计入本指标中）',
    order: { bound: 10, unbound: 5 }
  },
  likeCount: {
    title: '点赞次数',
    tooltip: '所选时间范围内，短视频新增的点赞数（点赞后再取消点赞，数据不会被剔除，依然会计入本指标中）',
    order: { bound: 11, unbound: 6 }
  },
  commentCount: {
    title: '评论次数',
    tooltip: '所选时间范围内，短视频被评论的次数',
    order: { bound: 12, unbound: 7 }
  },
  platformSupportedTraffic: {
    title: '平台扶持流量',
    tooltip: '对积极开播的商家,平台给予一定的流量扶持',
    order: { bound: 13, unbound: 8 }
  },
  estimatedCommissionExpenditure: {
    title: '预估佣金支出',
    prefix: '¥',
    tooltip: '所选时间范围内，短视频支付订单中预估支付给达人的佣金，该数据仅做参考使用，不作为最终结算金额',
    order: { bound: 14, unbound: 16 }
  },
  productCardTransactionAmount: {
    title: '引流商品卡成交金额',
    prefix: '¥',
    tooltip: '通过商品卡引流成交的金额',
    order: { bound: null, unbound: 3 }
  },
  productCardTransactionOrderCount: {
    title: '引流商品卡成交订单数',
    tooltip: '通过商品卡引流成交的订单数',
    order: { bound: null, unbound: 4 }
  }
};


const ShortVideoCenter: React.FC = () => {
  const [allData, setAllData] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs().subtract(1, 'day').endOf('day')
  ]);
  const [isInit, setIsInit] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const [compareType, setCompareType] = useState('1');
  const carouselRef = useRef<any>();
  const [selectedCards, setSelectedCards] = useState<Set<string>>(new Set(['transactionAmount']));
  const [videoRange, setVideoRange] = useState(0);
  // 添加排序状态
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [channelVideoRange, setChannelVideoRange] = useState(0);
  const [boundProduct, setBoundProduct] = useState<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      initData();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
    
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchData();
    }
  }, [selectedAccount, dateRange, compareType, sortField, sortOrder, videoRange, channelVideoRange, boundProduct]);

  const initData = async () => {
    await Promise.all([
      fetchAccounts(),
      fetchSalesPromotions()
      
    ]);
    setIsInit(true)
  }

  const fetchData = async (newPage?: number, newPageSize?: number) => {
    if (!selectedAccount) {
      console.error('Selected account or indicator config is not set');
      return;
    }

    setLoading(true);
    try {
        const [start, end] = dateRange;
        let compareStart: dayjs.Dayjs | undefined;
        let compareEnd: dayjs.Dayjs | undefined;
        switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
        }
      const response = await api.post(`/shortVideo/stats`, {
        accountId: selectedAccount,
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),     
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        sort: sortField || null,
        order: sortOrder || null,
        videoRange: videoRange || null,
        channelVideoRange: channelVideoRange || null,
        boundProduct: boundProduct
      });
      if(response.data.code === 1) {
        setAllData(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch live broadcast list:', error);
    } finally {
      setLoading(false);
    }
  };


  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const endDate = dayjs().subtract(1, 'day').endOf('day');
    let startDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
    }
    
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const downloadData = async () => {
    try {
      const [start, end] = dateRange;
        let compareStart: dayjs.Dayjs | undefined;
        let compareEnd: dayjs.Dayjs | undefined;
        switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
        }
      const response = await api.post('/shortVideo/download', {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),     
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        videoRange: videoRange || 0,
        channelVideoRange: channelVideoRange || 0,
        accountId: selectedAccount,
        sort: sortField || null,
        order: sortOrder || null,
      }, {
        responseType: 'blob'
      });

      if (response.headers['content-type']?.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonResponse = JSON.parse(reader.result as string);
          if (jsonResponse.code !== 1) {
            throw new Error(jsonResponse.message || '明细下载失败');
          }
        };
        reader.readAsText(response.data);
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '流量来源分析明细.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('明细下载成功');
    } catch (error) {
      console.error('Failed to download template:', error);
      message.error('明细下载失败');
    }
  };

  const onVideoRangeChange = (e: any) => {
    setVideoRange(e);
  };


  //排序处理函数
  const handleTableSort = (
    sorter: any
  ) => {
    // 获取排序字段和顺序
    const field = sorter.field;
    const order = sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : '';
  
    // 更新排序状态
    setSortField(field);
    setSortOrder(order);
  };
const channelAnalysisColumns: ColumnsType<ChannelAnalysisType> = [
  {
    title: '渠道名称',
    dataIndex: 'channelName',
    key: 'channelName',
    fixed: 'left',
    width: 200,
  },
  {
    title: '观看次数（占比）',
    dataIndex: 'videoViewCount',
    key: 'videoViewCount',
    width: 150,
    sorter: true,
    render: (value: number | null, record: ChannelAnalysisType) => {
      if (!value) return '-';
      return (
        <p>
          {value.toLocaleString()}
        </p>
      );
    },
  },
  {
    title: (
      <span>
        成交金额
        <Tooltip title="所选时间范围内，短视频绑定商品的支付金额（含自然流量和付费流量带来的订单）。下单途径：1、直接从短视频购买的订单支付金额；2、从短视频加入购物车，再从购物车结算的订单支付金额。支付后因各种原因取消的订单支付金额（如：退货/退款）不会被剔除，依然会计入本指标中。注意：不包含引流到其他载体的成交金额（如：引流到直播间购买）">
          <InfoCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'transactionAmount',
    key: 'transactionAmount',
    width: 150,
    sorter: true,
    render: (value: number | null) => {
      return value ? `¥${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '-';
    },
  },
  {
    title: (
      <span>
        成交订单数
        <Tooltip title="所选时间范围内，用户通过短视频上的购物入口成交的总订单数（含自然流量和付费流量带来的订单）。下单途径：1、用户点击购物车商品，直接下单购买；2、用户先将短视频挂的商品加入购物车，再去购物车结算（从购物车结算不需要打开短视频，因此可能出现短视频某天有成交但无播放数的情况）">
          <InfoCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'transactionOrderCount',
    key: 'transactionOrderCount',
    width: 150,
    sorter: true,
    render: (value: number | null) => value?.toLocaleString() || '-',
  },
  {
    title: (
      <span>
        商品曝光成交转化率
        <Tooltip title="所选时间范围内，通过短视频成交的订单数 / 短视频商品曝光次数">
          <InfoCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'productExposureTransactionRate',
    key: 'productExposureTransactionRate',
    width: 200,
    sorter: true,
    render: (value: number | null) => value ? `${value.toFixed(2)}%` : '-',
  },
];

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
  };

  const onAccountChange = (value: number) => {
    setSelectedAccount(value);
  };

  const onChannelVideoRangeChange = (e: any) => {
    setChannelVideoRange(e);
  };

  // 获取当前 tab 下的统计数据
  const getStatisticsData = () => {
    const tabType = boundProduct ? 'bound' : 'unbound';
    
    return Object.entries(METRICS_CONFIG)
      .filter(([key, config]) => config.order[tabType] !== null)
      .sort(([keyA, configA], [keyB, configB]) => 
        (configA.order[tabType] || 0) - (configB.order[tabType] || 0))
      .map(([key, config]) => ({
        title: config.title,
        value: key,
        tooltip: config.tooltip,
        prefix: config.prefix,
        suffix: config.suffix
      }));
  };

// 添加辅助函数
const isPercentageMetric = (metricKey: string): boolean => {
  // 根据实际指标判断是否为百分比类型
  return ['productExposureTransactionRate'].includes(metricKey);
};
const getMetricPrefix = (metricKey: string): string => {
  // 根据实际指标返回前缀
  const prefixMap: { [key: string]: string } = {
    transactionAmount: '¥',
    refundAmount: '¥',
    productThousandExposureTransaction: '¥',
    estimatedCommissionExpenditure: '¥',
    productExposureTransactionRate: '%'
  };
  return prefixMap[metricKey] || '';
};
const calculateAxisInterval = (maxValue: number): number => {
  // 计算合适的刻度间隔
  const baseInterval = maxValue / 6;
  
  // 获取数量级
  const magnitude = Math.pow(10, Math.floor(Math.log10(baseInterval)));
  
  // 向上取整到合适的刻度
  let roundedInterval = Math.ceil(baseInterval / magnitude) * magnitude;
  
  // 如果是千级别，按百位取整
  if (roundedInterval >= 1000) {
    roundedInterval = Math.ceil(roundedInterval / 100) * 100;
  }
  
  return roundedInterval;
};


const getChartOptions = () => {
  const selectedMetrics = Array.from(selectedCards);
  if (selectedMetrics.length === 0 || !allData.coreData) return {};

  const metrics = selectedMetrics.map(metric => ({
    key: metric,
    data: allData.coreData[metric],
    isPercentage: isPercentageMetric(metric),
    prefix: getMetricPrefix(metric)
  }));

  // 判断是否需要双Y轴
  const needDualY = metrics.length === 2 && 
    metrics[0].isPercentage !== metrics[1].isPercentage;

  // 计算Y轴配置

  const getYAxisConfig = () => {
    if (needDualY) {
      // 双Y轴配置
      return [
        {
          type: 'value',
          name: metrics[0].prefix,
          position: 'left',
          axisLabel: {
            formatter: (value: number) => `${metrics[0].prefix}${value}`
          },
          interval: calculateAxisInterval(Math.max(...metrics[0].data.dataList.map((item: any) => item.value))),
          min: 0
        },
        {
          type: 'value',
          name: metrics[1].prefix,
          position: 'right',
          axisLabel: {
            formatter: (value: number) => `${metrics[1].prefix}${value}`
          },
          interval: calculateAxisInterval(Math.max(...metrics[1].data.dataList.map((item: any) => item.value))),
          min: 0
        }
      ];
    } else {
      // 单Y轴配置
      const maxValue = Math.max(
        ...metrics.flatMap((m: any) => m.data.dataList.map((item: any) => item.value))
      );
      const interval = calculateAxisInterval(maxValue);
      const prefix = metrics[0].prefix;
      
      return {
        type: 'value',
        name: prefix,
        axisLabel: {
          formatter: (value: number) => `${prefix}${value}`
        },
        interval,
        min: 0,
        max: interval * 6
      };
    }
  };
// 构建系列数据
const series = metrics.map((metric, index) => ({
  name: metric.data.name,
  type: 'line',
  data: metric.data.dataList.map((item: any) => item.value),
  smooth: true,
  yAxisIndex: needDualY ? index : 0
}));

const xAxisData = metrics[0].data.dataList.map((item: any) => item.date);
return {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    },
    formatter: (params: any[]) => {
      return params.map(param => {
        const metric = metrics[param.seriesIndex];
        return `${param.seriesName}: ${metric.prefix}${param.value}${metric.isPercentage ? '%' : ''}`;
      }).join('<br/>');
    }
  },
  legend: {
    data: metrics.map(m => m.data.name)
  },
  grid: {
    left: '3%',
    right: needDualY ? '8%' : '4%',
    bottom: '0',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: xAxisData,
    axisLabel: {
      interval: 0,  // 强制显示所有标签
      rotate: 0,    // 如果日期显示拥挤可以设置旋转角度，比如 rotate: 45
      formatter: (value: string) => {
        // 将日期格式化为 MM-DD 格式
        const date = new Date(value);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      }
    },
    axisTick: {
      alignWithLabel: true
    }
  },
  yAxis: getYAxisConfig(),
  series
};
};


const handleCardSelect = (value: string) => {
  setSelectedCards(prev => {
    const newSet = new Set(prev);
    
    if (newSet.has(value)) {
      // 如果已选中，则移除
      newSet.delete(value);
    } else {
      // 如果未选中，检查是否已达到最大选择数量
      if (newSet.size >= 2) {
        // 超过2个时，移除第一个元素
        const firstValue = Array.from(newSet)[0];
        newSet.delete(firstValue);
      }
      // 添加新选项
      newSet.add(value);
    }
    return newSet;
  });
};


  // 渲染统计卡片
  const renderStatisticCard = (stat: any) => (
    <Col span={12} key={stat.value}>
      <Card 
        bodyStyle={{ padding: '10px' }}
        style={{ 
          backgroundColor: selectedCards.has(stat.value) ? '#E7F3FF' : 'white',
          transition: 'background-color 0.3s'
        }}
      >
        <Checkbox
          checked={selectedCards.has(stat.value)}
          onChange={() => handleCardSelect(stat.value)}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1
          }}
        />
        <Statistic
          title={
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              {stat.title}
              <Popover
                placement="topRight"
                content={stat.tooltip}
                overlayStyle={{ width: 300 }}
                trigger="hover"
              >
                <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </Popover>
            </div>
          }
          value={allData.coreData ? allData.coreData[stat.value]?.total || '-' : '-'}
          prefix={stat.prefix}
          suffix={stat.suffix}
        />
        {allData.coreData && allData.coreData[stat.value] && (
          <div style={{ fontSize: '12px', marginTop: '15px' }}>
            较前30天
            <span style={{
              color: allData.coreData[stat.value].rate > 0 ? 'red' : 
                     allData.coreData[stat.value].rate < 0 ? 'green' : 'black',
              marginLeft: 5
            }}>
              {allData.coreData[stat.value].rate !== null && 
               allData.coreData[stat.value].rate !== 0 && 
               (allData.coreData[stat.value].rate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)}
              {Math.abs(allData.coreData[stat.value].rate)}%
            </span>
          </div>
        )}
      </Card>
    </Col>
  );
  // 辅助函数：将数组分块
  const chunk = <T,>(arr: T[], size: number): T[][] => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  return (
    <div className="short-video-center">
      <Tabs
        activeKey={boundProduct ? "bound" : "unbound"}
        onChange={(key) => setBoundProduct(key === "bound")}
        type="card"
        style={{ marginBottom: '16px' }}
      >
        <TabPane tab="挂车短视频" key="bound" />
        <TabPane tab="非挂车短视频" key="unbound" />
      </Tabs>
      <div className="filters">
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
            <span style={{marginRight: 5, minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ minWidth: 120 }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '40px', width: '40px'}}>日期</span>
            <RangePicker 
              value={dateRange}
              allowClear={false}
              style={{minWidth: '230px', width: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            style={{minWidth: 290}}
            options={[
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' },
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
          <Select
            style={{ minWidth: 80, maxWidth: 160 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
          <div style={{marginLeft: '5px', minWidth: '200px'}}>
            <span style={{minWidth: '70px', marginRight: 5}}>对比周期</span>
            <Select
              style={{minWidth: 120}}
              placeholder="请选择"
              value={compareType}
              onChange={onCompareTypeChange}
              options={[
                { value: '1', label: '连续周期' },
                { value: '2', label: '上月同期' },
                { value: '3', label: '上季度同期' },
                { value: '4', label: '去年同期' }
              ]}
            />
          </div>
        </div>
      </div>

      <Row gutter={[16, 16]} style={{marginTop: 20,marginBottom: '20px', width: '40%', minWidth: '600px'}}>
        <Col span={8} >
          <Card bodyStyle={{ padding: '10px'}}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  新发布视频数
                  <Popover 
                    placement="topRight" 
                    content={
                      <div>
                        <p>所选时间范围内发布的带货短视频数量。</p>
                        <p>注意：是“发布时间”而非“创建时间”，定时发布的视频取发布时间</p>
                      </div>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={allData.newPublishedVideoCount || ''}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: allData.newPublishedVideoCountRate > 0 ? 'red' : allData.newPublishedVideoCountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {allData.newPublishedVideoCountRate !== null && allData.newPublishedVideoCountRate !== 0 && (allData.newPublishedVideoCountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(allData.newPublishedVideoCountRate)}%
               </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  被访问视频数
                  <Popover 
                    placement="topRight" 
                    content={
                        <p>所选时间范围内被观看过的带货短视频数量。</p>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={allData.viewedVideoCount}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: allData.viewedVideoCountRate > 0 ? 'red' : allData.viewedVideoCountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {allData.viewedVideoCountRate !== null && allData.viewedVideoCountRate !== 0 && (allData.viewedVideoCountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(allData.viewedVideoCountRate)}%
               </span>
            </div>
          </Card>
        </Col>
        {boundProduct && <Col span={8}>
          <Card bodyStyle={{ padding: '10px' }}>
            <Statistic
              title={
                <span style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between', paddingRight: '5px'}}>
                  带货商品数
                  <Popover 
                    placement="topRight" 
                    content={
                      <p>所选时间范围内，被绑定到短视频中的本店商品数量；去重逻辑：按商品ID进行去重</p>
                    } 
                    overlayStyle={{width: 300}}
                    trigger="hover"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }}  />
                  </Popover>
                </span>
              }
              value={allData.boundProductsCount}
            />
            <div style={{  fontSize: '12px', marginTop: '15px' }}>
               较上一周期<span style={{ color: allData.boundProductsCountRate > 0 ? 'red' : allData.boundProductsCountRate < 0 ? 'green' : 'black', marginLeft: 5 }}>
                 {allData.boundProductsCountRate !== null && allData.boundProductsCountRate !== 0 && (allData.boundProductsCountRate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />)} 
                 {Math.abs(allData.boundProductsCountRate)}%
               </span>
            </div>
          </Card>
        </Col>}
      </Row>

      {/* 核心数据 */}
      <Card bodyStyle={{padding: '10px 15px'}}>  
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
          <h3 style={{margin: 0}}>核心数据</h3>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{display: 'flex', alignItems: 'center'}}>
              视频范围
              <Popover
                placement="topRight"
                content={
                  <div>
                    <p>1. 近30天内发布视频：近30天内发布的视频（30天为T-31日~T-1日)</p>
                    <p>2. 历史视频：30天前发布的视频</p>
                  </div>
                }
                overlayStyle={{width: 300}}
                trigger="hover"
              >
                <QuestionCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </Popover>
            </span>
            <Select
              style={{minWidth: 120,marginLeft: 10}}
              placeholder="请选择"
              value={videoRange}
              onChange={onVideoRangeChange}
              options={[
                { value: 0, label: '全部' },
                { value: 1, label: '新发布视频' },
                { value: 2, label: '历史视频' }
              ]}
            />
          </div>
        </div>

        {/* 核心主数据 */}
        <div className="statistics-carousel-container" style={{display: 'flex', justifyContent: 'space-between'}}>
        {/* 左侧数据 */}
        <div style={{width: '40%' }}>
          <Carousel
            ref={carouselRef}
            autoplay={false}
            dots={true}
            dotPosition="bottom"
          >
            {chunk(getStatisticsData(), 6).map((pageData, index) => (
                <div key={index} className="carousel-page">
                  <Row gutter={[16, 16]} style={{marginTop: 20, marginBottom: '20px', width: '100%'}}>
                    {pageData.map(renderStatisticCard)}
                  </Row>
                </div>
              ))}
          </Carousel>
          </div>
          {/* 右侧图表 */}
          <div style={{ flex: 1, minWidth: '600px', minHeight: '400px' }}>
            {selectedCards.size > 0 && allData.coreData ? (
              <ReactECharts
                option={getChartOptions()}
                style={{ height: '400px', width: '100%' }}
              />
            ) : (
              <div style={{ 
                height: '400px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderRadius: '8px'
              }}>
              </div>
        )}
          </div>
        </div>

      </Card>

      {boundProduct && <Card bodyStyle={{padding: '10px 15px'}}>  
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
          <h3 style={{margin: 0}}>流量来源分析</h3>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{display: 'flex', alignItems: 'center'}}>
              视频范围
              <Popover
                placement="topRight"
                content={
                  <div>
                    <p>1. 近30天内发布视频：近30天内发布的视频（30天为T-31日~T-1日)</p>
                    <p>2. 历史视频：30天前发布的视频</p>
                  </div>
                }
                overlayStyle={{width: 300}}
                trigger="hover"
              >
                <QuestionCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </Popover>
            </span>
            <Select
              style={{minWidth: 120,marginLeft: 10}}
              placeholder="请选择"
              value={channelVideoRange}
              onChange={onChannelVideoRangeChange}
              options={[
                { value: 0, label: '全部' },
                { value: 1, label: '新发布视频' },
                { value: 2, label: '历史视频' }
              ]}
            />
            <Button type="primary" style={{marginLeft: 5}} onClick={downloadData}>下载明细</Button>
          </div>
        </div>
        <Table
          bordered
          columns={channelAnalysisColumns}
          dataSource={allData.channelAnalysis || []}
          loading={loading}
          rowKey="channelType"
          scroll={{ x: 'max-content' }}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
          }}
          onChange={(pagination, filters, sorter) => handleTableSort(sorter)}
        />
      </Card>}
      
    </div>
  );
};

export default ShortVideoCenter;
