import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Select, Button, message, Radio, Tooltip } from 'antd';
import { QuestionCircleOutlined, SettingFilled, DownloadOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { EChartsOption, SeriesOption } from 'echarts';
import api from '../../../utils/api';
import './LiveBroadcastDetails.scss';
import ChannelIndicatorModal from '../components/ChannelIndicatorModal/ChannelIndicatorModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Account {
  id: number;
  name: string;
}

interface SalesPromotion {
  id: string;
  name: string;
  start: string;
  end: string;
}
interface globalCoreColumnsType {
  timespan: string;
  consumption: number;
  consumptionRate: number;
  totalROI: number;
  totalROIRate: number;
  totalGMV: number;
  totalGMVRate: number;
  totalOrderCount: number;
  totalOrderCountRate: number;
  totalOrderCost: number;
  totalOrderCostRate: number;
  totalPreOrderCount: number;
  totalPreOrderCountRate: number;
  totalPreOrderAmount: number;
  totalPreOrderAmountRate: number;
  transactionCouponAmount: number;
  transactionCouponAmountRate: number;
  totalUncompletedPreOrderAmount: number;
  totalUncompletedPreOrderAmountRate: number;
}
// ... existing interfaces ...

interface standardCoreColumnsType {
  timespan: string;
  consumption: number;
  consumptionRate: number;
  payROI: number;
  payROIRate: number;
  showTimes: number;
  showTimesRate: number;
  clickCount: number;
  clickCountRate: number;
  transOrderCount: number;
  transOrderCountRate: number;
  sevenDayGMV: number;
  sevenDayGMVRate: number;
  sevenDayPayROI: number;
  sevenDayPayROIRate: number;
  clickRate: number;
  clickRateRate: number;
  clickPrice: number;
  clickPriceRate: number;
  thousandShowAverageCost: number;
  thousandShowAverageCostRate: number;
  conversionCount: number;
  conversionCountRate: number;
  conversionRate: number;
  conversionRateRate: number;
  conversionCost: number;
  conversionCostRate: number;
  settleAmount14Days: number;
  settleAmount14DaysRate: number;
  settleROI14Days: number;
  settleROI14DaysRate: number;
  newFansCount: number;
  newFansCountRate: number;
}
interface coreColumnsType {
  timespan: string;
  consumption: number;
  consumptionRate: number;
  totalROI: number;
  totalROIRate: number;
  totalGMV: number;
  totalGMVRate: number;
  totalOrderCount: number;
  totalOrderCountRate: number;
  totalOrderCost: number;
  totalOrderCostRate: number;
  overallPromotionExpenditureRatio: number;
  overallPromotionExpenditureRatioRate: number;
}
interface ChannelAnalysisType {
  channelType: number;
  channelName: string;
  liveDuration: number;
  averageViewingDuration: number;
  exposureCount: number;
  viewCount: number;
  exposureViewRate: number;
  productExposureCount: number;
  productClickCount: number;
  productExposureClickRate: number;
  productClickTransactionRate: number;
  transactionOrderCount: number;
  transactionUserCount: number;
  transactionAmount: number;
  transactionItemsCount: number;
  thousandViewsTransactionAmount: number;
  averageTransactionAmountPerUser: number;
  qcConsumption: number;
  children?: ChannelAnalysisType[];
}

interface IndicatorItem {
  title: string;
  key: string;
  tooltip?: string;
  isPercentage?: boolean;
  width?: number;
  suffix?: string;
}

const LiveBroadcastDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs().subtract(1, 'day').endOf('day')
  ]);
  const [isInit, setIsInit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const [indicatorConfig, setIndicatorConfig] = useState<Record<string, number>>({});
  const [isIndicatorConfigVisible, setIsIndicatorConfigVisible] = useState(false);
  const [compareType, setCompareType] = useState('1');
  const [accountLiveData, setAccountLiveData] = useState<any>({});
  const [coreIndicators, setCoreIndicators] = useState<any[]>([]);
  const [liveOverviews, setLiveOverviews] = useState<any>();
  const [channelAnalysis, setChannelAnalysis] = useState<any[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>(['viewUserCounts']);
  const [promotionType, setPromotionType] = useState<string>('');

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      initData();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchData();
    }
  }, [selectedAccount, dateRange, compareType]);

  const initData = async () => {
    await fetchIndicator();
    await fetchAccounts();
    await fetchSalesPromotions();
    setIsInit(true);
  };

  const fetchData = async (newIndicatorConfig?: Record<string, number>) => {
    if (!selectedAccount || Object.keys(indicatorConfig).length === 0) {
      console.error('Selected account or indicator config is not set');
      return;
    }

    setLoading(true);
    try {
      const [start, end] = dateRange;
      let compareStart: dayjs.Dayjs | undefined;
      let compareEnd: dayjs.Dayjs | undefined;
      switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
      }
      const response = await api.post(`/livebroadcast/detail`, {
        start: dateRange[0].format('YYYY-MM-DD'),
        end: dateRange[1].format('YYYY-MM-DD'),
        dyAccountId: selectedAccount,
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        indicator: newIndicatorConfig || indicatorConfig,
      });
      if (response.data.code === 1) {
        setAccountLiveData(response.data.data.accountLiveData);
        setCoreIndicators(response.data.data.coreIndicators);
        setLiveOverviews(response.data.data.liveOverviews);
        setChannelAnalysis(response.data.data.channelAnalysis);
        setPromotionType(response.data.data.promotionType);
      }
    } catch (error) {
      console.error('Failed to fetch live broadcast list:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadData = async () => {
    try {
      const [start, end] = dateRange;
      let compareStart: dayjs.Dayjs | undefined;
      let compareEnd: dayjs.Dayjs | undefined;
      switch (compareType) {
        case '1': // 连续周期
          const duration = end.diff(start, 'day');
          compareStart = start.subtract(duration + 1, 'day');
          compareEnd = end.subtract(duration + 1, 'day');
          break;
        case '2': // 上月同期
          compareStart = start.subtract(1, 'month');
          compareEnd = end.subtract(1, 'month');
          break;
        case '3': // 上季度同期
          compareStart = start.subtract(3, 'month');
          compareEnd = end.subtract(3, 'month');
          break;
        case '4': // 去年同期
          compareStart = start.subtract(1, 'year');
          compareEnd = end.subtract(1, 'year');
          break;
      }
      const response = await api.post('/livebroadcast/stats/download', {
        liveId: null,
        dyAccountId: selectedAccount,
        start: dateRange[0].format('YYYY-MM-DD'),
        end: dateRange[1].format('YYYY-MM-DD'),
        compareStart: compareStart?.format('YYYY-MM-DD'),
        compareEnd: compareEnd?.format('YYYY-MM-DD'),
        indicator: indicatorConfig,
      }, {
        responseType: 'blob'
      });

      if (response.headers['content-type']?.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonResponse = JSON.parse(reader.result as string);
          if (jsonResponse.code !== 1) {
            throw new Error(jsonResponse.message || '明细下载失败');
          }
        };
        reader.readAsText(response.data);
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '渠道分析明细.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('明细下载成功');
    } catch (error) {
      console.error('Failed to download template:', error);
      message.error('明细下载失败');
    }
  }

  const handleSaveIndicatorConfig = async (newIndicatorConfig: Record<string, number>) => {
    try {
      await fetchData(newIndicatorConfig);
      fetchIndicator();
    } catch (error) {
      console.error('Failed to save indicator config:', error);
      throw error;
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const fetchIndicator = async () => {
    try {
      const response = await api.get('/livebroadcast/detail/indicator');
      if (response.data.code === 1) {
        setIndicatorConfig(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch indicator config:', error);
    }
  };

  const onAccountChange = (value: number) => {
    setSelectedAccount(value);
  };

  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const endDate = dayjs().subtract(1, 'day').endOf('day');
    let startDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
    }
    
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const getChartOption = (): EChartsOption => {
    if (!liveOverviews) return {};

    const colors = [
      '#1890FF', // Blue
      '#52C41A', // Green
      '#FAAD14', // Gold
      '#F5222D', // Red
      '#722ED1'  // Purple
    ];

    // 计算所有数据中的最大值
    const allValues = selectedTypes.reduce((acc: number[], type) => {
      const values = liveOverviews[type].map((item: any) => Number(item.value));
      return [...acc, ...values];
    }, []);
    const maxValue = Math.max(...allValues);

    // 计算刻度间隔和最大刻度值
    const getAxisInterval = (max: number) => {
      // 初始间隔 = 最大值/5（因为要显示6个刻度，其中第一个是0）
      let interval = max / 5;
      
      // 获取数量级
      const magnitude = Math.pow(10, Math.floor(Math.log10(interval)));
      
      // 向上取整到合适的刻度
      if (interval / magnitude < 1) {
        interval = magnitude;
      } else if (interval / magnitude < 2.5) {
        interval = 2.5 * magnitude;
      } else if (interval / magnitude < 5) {
        interval = 5 * magnitude;
      } else {
        interval = 10 * magnitude;
      }
      
      // 计算最大刻度值（向上取整到interval的倍数）
      const maxAxisValue = Math.ceil(max / interval) * interval;
      
      return {
        interval,
        maxAxisValue
      };
    };

    const { interval, maxAxisValue } = getAxisInterval(maxValue);

    const series = selectedTypes.map((type, index) => ({
      name: liveViewTypeData.find(item => item.value === type)?.name || '',
      type: 'line' as const,
      data: liveOverviews[type],
      smooth: true,
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        width: 2,
        type: 'solid' as const,
        color: colors[index % colors.length]
      },
      itemStyle: {
        color: colors[index % colors.length],
        borderWidth: 2,
        borderColor: '#fff'
      },
      emphasis: {
        focus: 'series' as const,
        itemStyle: {
          symbolSize: 8
        }
      }
    }));
  
    return {
      color: colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: '#808080',
            width: 1,
            type: 'dashed'
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#333'
        },
        formatter: (params: any) => {
          let result = `${params[0].axisValue}<br/>`;
          params.forEach((param: any) => {
            result += `${param.marker} ${param.seriesName}: ${param.value}<br/>`;
          });
          return result;
        }
      },
      legend: {
        data: selectedTypes.map(type => 
          liveViewTypeData.find(item => item.value === type)?.name || ''
        ),
        top: 0,
        textStyle: {
          color: '#666'
        },
        itemWidth: 15,
        itemHeight: 10,
        itemGap: 25
      },
      grid: {
        left: '3%',
        right: '6%',
        bottom: '3%',
        containLabel: true,
        top: 40
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: liveOverviews[selectedTypes[0]].map((item: any) => item.dateTime) || [],
        axisLine: {lineStyle: {color: '#E5E7EB'}},
        axisTick: {show: false},
        axisLabel: {
          color: '#666',
          fontSize: 10,
          interval: 0,
          formatter: (value: string) => value,
          hideOverlap: false,
          margin: 8,
          align: 'center'
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            color: '#E5E7EB',
            type: 'dashed'
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#666',
          formatter: (value: number) => {
            if (value >= 10000) {
              return (value / 10000).toString() + 'w';
            }
            return value.toString();
          }
        },
        min: 0,
        max: maxAxisValue,
        interval: interval,
        splitNumber: 5 // 改为5，这样加上0刚好是6个刻度
      },
      series: series as SeriesOption[]
    };
  };

  const handleTypeClick = (value: string) => {
    setSelectedTypes(prev => {
      if (prev.includes(value)) {
        // 如果已选中的只有一个且要取消选中，则不执行取消操作
        if (prev.length === 1) {
          return prev;
        }
        return prev.filter(type => type !== value);
      }
      return [...prev, value];
    });
  };

  const accountLiveMainData = [
    {
      name: '成交金额',
      value: 'transactionAmount',
      tip: '直播间成交的订单金额汇总，包含从本场直播先加到购物车，关播后再支付的订单。不去除退款订单金额',
      prefix: '¥'
    },
    {
      name: '直播时长',
      value: 'liveDuration',
      tip: '直播持续时长，精确到小时，不计算暂停的时间'
    },
    {
      name: '单位小时曝光次数',
      value: 'exposureCountPerHour',
      tip: '曝光次数/直播时长',
      suffix: '万'
    },
    {
      name: '曝光-观看率（次数）',
      value: 'exposureViewRate',
      tip: '观看次数/曝光次数',
      suffix: '%'
    },
    {
      name: '千次观看成交金额',
      value: 'thousandViewsTransactionAmount',
      tip: '直播间成交金额/观看次数*1000',
      prefix: '¥'
    }
  ];

  const liveViewTypeData = [
    {
      name: '观看人数',
      value: 'viewUserCounts'
    },
    {
      name: '成交人数',
      value: 'transUserCounts'
    },
    {
      name: 'GMV',
      value: 'gmv'
    },
    {
      name: '单小时观看人数',
      value: 'viewUserCountsPerHour'
    },
    {
      name: '单小时GMV',
      value: 'gmvPerHour'
    }
  ];

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
  };

  const getChannelAnalysisColumns = (): ColumnsType<ChannelAnalysisType> => {
    const columns: ColumnsType<ChannelAnalysisType> = [
      {
        title: '渠道名称',
        dataIndex: 'channelName',
        key: 'channelName',
        width: 150,
        fixed: 'left'
      }
    ];
    // 遍历 indicatorConfig，根据配置添加列
    Object.entries(indicatorConfig).forEach(([key, value]) => {
      if (value === 1) {
        let columnConfig;
        Object.values(indicatorMap).some((category: any) => {
          columnConfig = category.list.find((item: any) => item.key === key);
          return !!columnConfig;
        });

        if (columnConfig) {
          const config = columnConfig as IndicatorItem;
          columns.push({
            title: config.tooltip ? (
              <span>
                {config.title}
                <Tooltip title={config.tooltip}>
                  <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
              </span>
            ) : config.title,
            dataIndex: key,
            key: key,
            width: config.width || 150,
            render: (text: number, record: any) => (
              <p>{text || '-'}{config.suffix && <span>{config.suffix}</span>}</p>
            )
          });
        }
      }
    });

    return columns;
  };
  

  const indicatorMap = {
    flow: {
      title: '基础数据',
      key: 'flow',
      list: [
        {
          title: '直播时长',
          key: 'liveDuration',
          tooltip: '直播持续时长，精确到小时，不计算暂停的时间'
        },
        {
          title: '人均观看时长',
          key: 'averageViewingDuration',
          tooltip: '直播间内平均每位用户的观看时长'
        }
      ]
    },
    interactive: {
      title: '互动',
      key: 'interactive',
      list: [
        {
          title: '直播间曝光次数',
          key: 'exposureCount',
        },
        {
          title: '直播间观看次数',
          key: 'viewCount',
        },
        {
          title: '直播间曝光-观看率（次数）',
          key: 'exposureViewRate',
          tooltip: '直播间观看次数/直播间曝光次数',
          width: 220,
          suffix: '%'
        },
        {
          title: '直播间互动次数',
          key: 'interactionCount',
          tooltip: '直播间互动次数（互动包括：点赞、评论、分享）'
        },
        {
          title: '直播间观看-互动率(次数)',
          key: 'viewInteractionRate',
          tooltip: '直播间互动次数/直播间观看次数（互动包括：点赞、评论、分享）',
          width: 200,
          suffix: '%'
        },
        {
          title: '新增粉丝数',
          key: 'newFansCount',
          tooltip: '在直播间内点击关注达人的人数，不去除后续取关用户，注意：若一个人点击关注后取消关注，依然计算为一个关注人数'
        }
      ]
    },
    goods: {
      title: '商品',
      key: 'goods',
      list: [
        {
          title: '商品曝光次数',
          key: 'productExposureCount',
          tooltip: '直播间内看到商品展示的次数，商品展示包含讲解商品卡、闪购商品卡、购物车内商品列表的商品卡片'
        },
        {
          title: '商品点击次数',
          key: 'productClickCount',
          tooltip: '直播间内点击商品的次数，包含点击闪购商品卡、点击讲解商品卡、点击商品列表的商品'
        },
        {
          title: '商品曝光-点击率(次数)',
          key: 'productExposureClickRate',
          tooltip: '商品点击次数/商品曝光次数',
          width: 200,
          suffix: '%'
        },
        {
          title: '商品点击-成交率(次数)',
          key: 'productClickTransactionRate',
          tooltip: '指定渠道下的成交订单数/商品点击次数',
          width: 200,
          suffix: '%'
        },
      ]
    },
    trade: {
      title: '交易',
      key: 'trade',
      list: [
        {
          title: '成交订单数',
          key: 'transactionOrderCount',
          tooltip: '直播间成交的订单数，包含本场直播间仅加购物车结束后成交的订单，不去除退款订单数'
        },
        {
          title: '成交人数',
          key: 'transactionUserCount',
          tooltip: '所选时间范围内，直播间下单并成功支付的人数。含直接在直播间下单和先从直播间加入购物车，后续再从购物车结算付款的订单。（支付后再取消订单/退款，数据不会被剔除，依然会计入本指标中）'
        },
        {
          title: '成交金额',
          key: 'transactionAmount',
          tooltip: '直播间成交的订单金额汇总，包含从本场直播先加到购物车，关播后再支付的订单。不去除退款订单金额'
        },
        {
          title: '成交件数',
          key: 'transactionItemsCount',
          tooltip: '直播间成交订单的总商品件数，包含本场直播间仅加购物车结束后成交的订单，不去除退款的订单'
        },
        {
          title: '千次观看成交金额',
          key: 'thousandViewsTransactionAmount',
          tooltip: '直播间成交金额/观看次数*1000'
        },
        {
          title: '客单价',
          key: 'averageTransactionAmountPerUser',
          tooltip: '直播间成交金额/直播间成交人数'
        }
      ]
    }
  };
  const globalCoreIndicatorsColumns: ColumnsType<globalCoreColumnsType> = [
  {
    title: '时段',
    dataIndex: 'timespan',
    key: 'timespan',
    sorter: (a, b) => a.timespan.localeCompare(b.timespan),
    width: 110
  },
  {
    title: (
      <span>
        消耗
        <Tooltip title="全域推广产品投放期间产生的消耗">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'consumption',
    key: 'consumption', 
    sorter: (a, b) => a.consumption - b.consumption,
    width: 100,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.consumptionRate && <div style={{ color: record.consumptionRate > 0 ? '#f5222d' : record.consumptionRate < 0 ? '#52c41a' : '#000000' }}>
          {record.consumptionRate > 0 ? '↑' : record.consumptionRate < 0 ? '↓' : ''} {Math.abs(record.consumptionRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体支付ROI
        <Tooltip title="1、整体支付ROI=（整体成交金额+整体成交智能优惠券金额）/消耗； 2、特殊情况（存在预售订单），整体支付ROI=（整体成交金额+整体未完结预售订单预估金额+整体成交智能优惠金额）/消耗">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalROI',
    key: 'totalROI',
    sorter: (a, b) => a.totalROI - b.totalROI,
    width: 130,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalROIRate && <div style={{ color: record.totalROIRate > 0 ? '#f5222d' : record.totalROIRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalROIRate > 0 ? '↑' : record.totalROIRate < 0 ? '↓' : ''} {Math.abs(record.totalROIRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体成交金额(元)
        <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的全部成交订单金额（包含全部订单的实际成交金额，如果预售订单已完成尾款支付，包含预售订单的实际成交金额；如果已过尾款支付日期，仅包含预售订单的定金）；不包含整体成交智能优惠券金额">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalGMV',
    key: 'totalGMV', 
    sorter: (a, b) => a.totalGMV - b.totalGMV,
    width: 160,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalGMVRate && <div style={{ color: record.totalGMVRate > 0 ? '#f5222d' : record.totalGMVRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalGMVRate > 0 ? '↑' : record.totalGMVRate < 0 ? '↓' : ''} {Math.abs(record.totalGMVRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体成交订单数
        <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的全部成交订单数（包含非预售订单的成交单数和预售订单已完成尾款支付单数，不包含预售订单未完成尾款支付单数）">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalOrderCount',
    key: 'totalOrderCount',
    sorter: (a, b) => a.totalOrderCount - b.totalOrderCount,
    width: 150,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalOrderCountRate && <div style={{ color: record.totalOrderCountRate > 0 ? '#f5222d' : record.totalOrderCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalOrderCountRate > 0 ? '↑' : record.totalOrderCountRate < 0 ? '↓' : ''} {Math.abs(record.totalOrderCountRate)}&
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体成交订单成本(元)
        <Tooltip title="全域推广产品投放期间，全域推广消耗/整体成交订单数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalOrderCost',
    key: 'totalOrderCost',
    sorter: (a, b) => a.totalOrderCost - b.totalOrderCost,
    width: 190,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalOrderCostRate && <div style={{ color: record.totalOrderCostRate > 0 ? '#f5222d' : record.totalOrderCostRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalOrderCostRate > 0 ? '↑' : record.totalOrderCostRate < 0 ? '↓' : ''} {Math.abs(record.totalOrderCostRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体预售订单数
        <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的已支付预售类定金的预售订单数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalPreOrderCount',
    key: 'totalPreOrderCount',
    sorter: (a, b) => a.totalPreOrderCount - b.totalPreOrderCount,
    width: 150,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalPreOrderCountRate && <div style={{ color: record.totalPreOrderCountRate > 0 ? '#f5222d' : record.totalPreOrderCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalPreOrderCountRate > 0 ? '↑' : record.totalPreOrderCountRate < 0 ? '↓' : ''} {Math.abs(record.totalPreOrderCountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体预售订单金额(元)
        <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的已支付预售类定金的预售金额，包含定金和尾款（只包含预售类订单的金额，不判断尾款支付状态）">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalPreOrderAmount',
    key: 'totalPreOrderAmount',
    sorter: (a, b) => a.totalPreOrderAmount - b.totalPreOrderAmount,
    width: 190,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalPreOrderAmountRate && <div style={{ color: record.totalPreOrderAmountRate > 0 ? '#f5222d' : record.totalPreOrderAmountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalPreOrderAmountRate > 0 ? '↑' : record.totalPreOrderAmountRate < 0 ? '↓' : ''} {Math.abs(record.totalPreOrderAmountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体成交智能优惠券金额(元)
        <Tooltip title="在全域推广中启用「智能优惠券」功能后，成交订单中被智能优惠券抵扣掉的金额">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'transactionCouponAmount',
    key: 'transactionCouponAmount',
    sorter: (a, b) => a.transactionCouponAmount - b.transactionCouponAmount,
    width: 230,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.transactionCouponAmountRate && <div  style={{ color: record.transactionCouponAmountRate > 0 ? '#f5222d' : record.transactionCouponAmountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.transactionCouponAmountRate > 0 ? '↑' : record.transactionCouponAmountRate < 0 ? '↓' : ''} {Math.abs(record.transactionCouponAmountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        整体未完结预售订单预估金额(元)
        <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的已支付预售类订单，在预售活动定金期的预估成交金额，该指标在预售活动定金期为系统预估的订单成交金额；在尾款期由于预售类订单完结，预售类订单会计入整体成交金额，则该指标会变更为0">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'totalUncompletedPreOrderAmount',
    key: 'totalUncompletedPreOrderAmount',
    sorter: (a, b) => a.totalUncompletedPreOrderAmount - b.totalUncompletedPreOrderAmount,
    width: 260,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.totalUncompletedPreOrderAmountRate && <div style={{ color: record.totalUncompletedPreOrderAmountRate > 0 ? '#f5222d' : record.totalUncompletedPreOrderAmountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.totalUncompletedPreOrderAmountRate > 0 ? '↑' : record.totalUncompletedPreOrderAmountRate < 0 ? '↓' : ''} {Math.abs(record.totalUncompletedPreOrderAmountRate)}
        </div>}
      </div>
    )
  }
  ];

  const standardCoreIndicatorsColumns: ColumnsType<standardCoreColumnsType> = [
  {
    title: '时段',
    dataIndex: 'timespan',
    key: 'timespan',
    width: 120
  },
  {
    title: (
      <span>
        消耗
        <Tooltip title="广告投放期间的消耗">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'consumption',
    key: 'consumption',
    sorter: (a, b) => a.consumption - b.consumption,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.consumptionRate && <div style={{ color: record.consumptionRate > 0 ? '#f5222d' : record.consumptionRate < 0 ? '#52c41a' : '#000000' }}>
          {record.consumptionRate > 0 ? '↑' : record.consumptionRate < 0 ? '↓' : ''} {Math.abs(record.consumptionRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        直接支付ROI
        <Tooltip title="1、直接支付ROI=（直接成交金额+成交智能优惠券金额）/消耗； 2、特殊情况：若存在预售订单，订单未支付尾款且未过尾款支付期下，直接支付ROI=（非预售订单直接成交金额+直接预售金额+成交智能优惠券金额）/消耗，直接预售金额包括定金和尾款（不判断尾款支付状态）。">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'payROI',
    key: 'payROI', 
    sorter: (a, b) => a.payROI - b.payROI,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.payROIRate && <div style={{ color: record.payROIRate > 0 ? '#f5222d' : record.payROIRate < 0 ? '#52c41a' : '#000000' }}>
          {record.payROIRate > 0 ? '↑' : record.payROIRate < 0 ? '↓' : ''} {Math.abs(record.payROIRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        展示次数
        <Tooltip title="广告展示给用户的次数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'showTimes',
    key: 'showTimes',
    sorter: (a, b) => a.showTimes - b.showTimes,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.showTimesRate && <div style={{ color: record.showTimesRate > 0 ? '#f5222d' : record.showTimesRate < 0 ? '#52c41a' : '#000000' }}>
          {record.showTimesRate > 0 ? '↑' : record.showTimesRate < 0 ? '↓' : ''} {Math.abs(record.showTimesRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        点击次数
        <Tooltip title="点击广告进入商品落地页或点击进入直播间的次数；">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'clickCount',
    key: 'clickCount',
    sorter: (a, b) => a.clickCount - b.clickCount,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.clickCountRate && <div style={{ color: record.clickCountRate > 0 ? '#f5222d' : record.clickCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.clickCountRate > 0 ? '↑' : record.clickCountRate < 0 ? '↓' : ''} {Math.abs(record.clickCountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        直接成交订单数
        <Tooltip title="用户点击广告后直接转化的成交订单数； 特殊情况：1、推商品计划包含商品卡推广7日内的成交单数；2、若存在预售订单，包含预售订单已完成尾款支付单数，不包含预售订单未完成尾款支付单数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'transOrderCount',
    key: 'transOrderCount',
    sorter: (a, b) => a.transOrderCount - b.transOrderCount,
    width: 140,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.transOrderCountRate && <div style={{ color: record.transOrderCountRate > 0 ? '#f5222d' : record.transOrderCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.transOrderCountRate > 0 ? '↑' : record.transOrderCountRate < 0 ? '↓' : ''} {Math.abs(record.transOrderCountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        7日总成交金额
        <Tooltip title="7日总成交金额=直接成交金额+7日间接成交金额； 7日间接成交金额即用户点击广告后未直接发生订单转化就退出，然后在7天内用户通过其他流量渠道再次触达同店铺或同抖音号商品且成交的订单金额；其他流量渠道举例：关注、搜索、购物车、个人主页、橱窗等。">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'sevenDayGMV',
    key: 'sevenDayGMV',
    sorter: (a, b) => a.sevenDayGMV - b.sevenDayGMV,
    width: 160,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.sevenDayGMVRate && <div style={{ color: record.sevenDayGMVRate > 0 ? '#f5222d' : record.sevenDayGMVRate < 0 ? '#52c41a' : '#000000' }}>
          {record.sevenDayGMVRate > 0 ? '↑' : record.sevenDayGMVRate < 0 ? '↓' : ''} {Math.abs(record.sevenDayGMVRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        7日总支付ROI
        <Tooltip title="1、7日总支付ROI=（7日总成交金额+成交智能优惠券金额）/消耗； 2、特殊情况：若存在预售订单，订单未支付尾款且未过尾款支付期下，7日总支付ROI=（非预售订单7日成交金额+7日预售金额+成交智能优惠券金额）/消耗，7日预售金额包括定金和尾款（不判断尾款支付状态）。">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'sevenDayPayROI',
    key: 'sevenDayPayROI',
    sorter: (a, b) => a.sevenDayPayROI - b.sevenDayPayROI,
    width: 140,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.sevenDayPayROIRate && <div style={{ color: record.sevenDayPayROIRate > 0 ? '#f5222d' : record.sevenDayPayROIRate < 0 ? '#52c41a' : '#000000' }}>
          {record.sevenDayPayROIRate > 0 ? '↑' : record.sevenDayPayROIRate < 0 ? '↓' : ''} {Math.abs(record.sevenDayPayROIRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        点击率
        <Tooltip title="点击次数/展示次数*100%">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'clickRate',
    key: 'clickRate',
    sorter: (a, b) => a.clickRate - b.clickRate,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}{text && <span>%</span>}</div>
        {record.clickRateRate && <div style={{ color: record.clickRateRate > 0 ? '#f5222d' : record.clickRateRate < 0 ? '#52c41a' : '#000000' }}>
          {record.clickRateRate > 0 ? '↑' : record.clickRateRate < 0 ? '↓' : ''} {Math.abs(record.clickRateRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        点击单价
        <Tooltip title="广告主为每次点击付出的费用成本，计算公式是：总花费/点击数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'clickPrice',
    key: 'clickPrice',
    sorter: (a, b) => a.clickPrice - b.clickPrice,
    width: 140,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.clickPriceRate && <div style={{ color: record.clickPriceRate > 0 ? '#f5222d' : record.clickPriceRate < 0 ? '#52c41a' : '#000000' }}>
          {record.clickPriceRate > 0 ? '↑' : record.clickPriceRate < 0 ? '↓' : ''} {Math.abs(record.clickPriceRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        平均千次展现费用
        <Tooltip title="广告平均每一千次展现的费用，计算公式是：总花费/展示数*1000。">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'thousandShowAverageCost',
    key: 'thousandShowAverageCost',
    sorter: (a, b) => a.thousandShowAverageCost - b.thousandShowAverageCost,
    width: 180,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-' }</div>
        {record.thousandShowAverageCostRate && <div style={{ color: record.thousandShowAverageCostRate > 0 ? '#f5222d' : record.thousandShowAverageCostRate < 0 ? '#52c41a' : '#000000' }}>
          {record.thousandShowAverageCostRate > 0 ? '↑' : record.thousandShowAverageCostRate < 0 ? '↓' : ''} {Math.abs(record.thousandShowAverageCostRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        转化数
        <Tooltip title="您设置的广告优化目标的转化数，如优化目标为直播间商品点击，转化数则为直播间商品点击人数；优化目标为直播间下单，转化数则为直播间下单人数；优化目标为直播间成交，转化数则为直播间成交人数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'conversionCount',
    key: 'conversionCount',
    sorter: (a, b) => a.conversionCount - b.conversionCount,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.conversionCountRate && <div style={{ color: record.conversionCountRate > 0 ? '#f5222d' : record.conversionCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.conversionCountRate > 0 ? '↑' : record.conversionCountRate < 0 ? '↓' : ''} {Math.abs(record.conversionCountRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        转化率
        <Tooltip title="优化目标的转化数/点击次数*100%(注：退款订单会导致转化率上下波动;投放【直播间结算】时，用户支付且订单未退款均记为一次转化，支持实时观测数据;7天后为最终转化数据。)">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'conversionRate',
    key: 'conversionRate',
    sorter: (a, b) => a.conversionRate - b.conversionRate,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}{text && <span>%</span>}</div>
        {record.conversionRateRate && <div style={{ color: record.conversionRateRate > 0 ? '#f5222d' : record.conversionRateRate < 0 ? '#52c41a' : '#000000' }}>
          {record.conversionRateRate > 0 ? '↑' : record.conversionRateRate < 0 ? '↓' : ''} {Math.abs(record.conversionRateRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        转化成本
        <Tooltip title="消耗/广告转化数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'conversionCost',
    key: 'conversionCost',
    sorter: (a, b) => a.conversionCost - b.conversionCost,
    width: 140,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.conversionCostRate && <div style={{ color: record.conversionCostRate > 0 ? '#f5222d' : record.conversionCostRate < 0 ? '#52c41a' : '#000000' }}>
          {record.conversionCostRate > 0 ? '↑' : record.conversionCostRate < 0 ? '↓' : ''} {Math.abs(record.conversionCostRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        直接结算金额（14天）
        <Tooltip title="广告有效触达用户产生直接成交金额后，且未来14天内未退款的结算金额（直接成交金额-未来7天内的退款金额）">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'settleAmount14Days',
    key: 'settleAmount14Days',
    sorter: (a, b) => a.settleAmount14Days - b.settleAmount14Days,
    width: 180,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.settleAmount14DaysRate && <div style={{ color: record.settleAmount14DaysRate > 0 ? '#f5222d' : record.settleAmount14DaysRate < 0 ? '#52c41a' : '#000000' }}>
          {record.settleAmount14DaysRate > 0 ? '↑' : record.settleAmount14DaysRate < 0 ? '↓' : ''} {Math.abs(record.settleAmount14DaysRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        直接结算ROI(14天）
        <Tooltip title="直接结算ROI（14天）=（未完结直接预售订单预估金额 + 智能成交优惠券金额 + 直接结算金额（14天）- 智能优惠券退款金额）/消耗，注：在最近14日内，该数据会存在波动情况，14日后该数据稳定不变； 直接结算金额（14天）为广告有效触达用户直接成交后，且未来14天内未退款的结算金额。">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'settleROI14Days',
    key: 'settleROI14Days',
    sorter: (a, b) => a.settleROI14Days - b.settleROI14Days,
    width: 160,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.settleROI14DaysRate && <div style={{ color: record.settleROI14DaysRate > 0 ? '#f5222d' : record.settleROI14DaysRate < 0 ? '#52c41a' : '#000000' }}>
          {record.settleROI14DaysRate > 0 ? '↑' : record.settleROI14DaysRate < 0 ? '↓' : ''} {Math.abs(record.settleROI14DaysRate)}%
        </div>}
      </div>
    )
  },
  {
    title: (
      <span>
        新增粉丝数
        <Tooltip title="广告带来的新增粉丝数">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'newFansCount',
    key: 'newFansCount',
    sorter: (a, b) => a.newFansCount - b.newFansCount,
    width: 120,
    render: (text: number, record: any) => (
      <div>
        <div>{text || '-'}</div>
        {record.newFansCountRate && <div style={{ color: record.newFansCountRate > 0 ? '#f5222d' : record.newFansCountRate < 0 ? '#52c41a' : '#000000' }}>
          {record.newFansCountRate > 0 ? '↑' : record.newFansCountRate < 0 ? '↓' : ''} {Math.abs(record.newFansCountRate)}%
        </div>}
      </div>
    )
  }
  ];
  const coreIndicatorsColumns: ColumnsType<coreColumnsType> = [
    {
      title: '时段',
      dataIndex: 'timespan',
      key: 'timespan',
      sorter: (a, b) => a.timespan.localeCompare(b.timespan),
      width: 110
    },
    {
      title: (
        <span>
          消耗
          <Tooltip title="全域推广产品投放期间产生的消耗">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'consumption',
      key: 'consumption',
      sorter: (a, b) => a.consumption - b.consumption,
      width: 100,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.consumptionRate && <div style={{ color: record.consumptionRate > 0 ? '#f5222d' : record.consumptionRate < 0 ? '#52c41a' : '#000000' }}>
            {record.consumptionRate > 0 ? '↑' : record.consumptionRate < 0 ? '↓' : ''} {Math.abs(record.consumptionRate)}%
          </div>}
        </div>
      )
    },
    {
      title: (
        <span>
          整体支付ROI
          <Tooltip title="1、整体支付ROI=（整体成交金额+整体成交智能优惠券金额）/消耗； 2、特殊情况（存在预售订单），整体支付ROI=（整体成交金额+整体未完结预售订单预估金额+整体成交智能优惠金额）/消耗">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'totalROI',
      key: 'totalROI',
      sorter: (a, b) => a.totalROI - b.totalROI,
      width: 130,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.totalROIRate && <div style={{ color: record.totalROIRate > 0 ? '#f5222d' : record.totalROIRate < 0 ? '#52c41a' : '#000000' }}>
            {record.totalROIRate > 0 ? '↑' : record.totalROIRate < 0 ? '↓' : ''} {Math.abs(record.totalROIRate)}%
          </div>}
        </div>
      )
    },
    {
      title: (
        <span>
          整体成交金额(元)
          <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的全部成交订单金额（包含全部订单的实际成交金额，如果预售订单已完成尾款支付，包含预售订单的实际成交金额；如果已过尾款支付日期，仅包含预售订单的定金）；不包含整体成交智能优惠券金额">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'totalGMV',
      key: 'totalGMV', 
      sorter: (a, b) => a.totalGMV - b.totalGMV,
      width: 160,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.totalGMVRate && <div style={{ color: record.totalGMVRate > 0 ? '#f5222d' : record.totalGMVRate < 0 ? '#52c41a' : '#000000' }}>
            {record.totalGMVRate > 0 ? '↑' : record.totalGMVRate < 0 ? '↓' : ''} {Math.abs(record.totalGMVRate)}%
          </div>}
        </div>
      )
    },
    {
      title: (
        <span>
          整体成交订单数
          <Tooltip title="全域推广产品投放期间，对应的抖音号下相关直播间产生的全部成交订单数（包含非预售订单的成交单数和预售订单已完成尾款支付单数，不包含预售订单未完成尾款支付单数）">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'totalOrderCount',
      key: 'totalOrderCount',
      sorter: (a, b) => a.totalOrderCount - b.totalOrderCount,
      width: 150,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.totalOrderCountRate && <div style={{ color: record.totalOrderCountRate > 0 ? '#f5222d' : record.totalOrderCountRate < 0 ? '#52c41a' : '#000000' }}>
            {record.totalOrderCountRate > 0 ? '↑' : record.totalOrderCountRate < 0 ? '↓' : ''} {Math.abs(record.totalOrderCountRate)}%
          </div>}
        </div>
      )
    },
    {
      title: (
        <span>
          整体成交订单成本(元)
          <Tooltip title="全域推广产品投放期间，全域推广消耗/整体成交订单数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'totalOrderCost',
      key: 'totalOrderCost',
      sorter: (a, b) => a.totalOrderCost - b.totalOrderCost,
      width: 190,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.totalOrderCostRate && <div style={{ color: record.totalOrderCostRate > 0 ? '#f5222d' : record.totalOrderCostRate < 0 ? '#52c41a' : '#000000' }}>
            {record.totalOrderCostRate > 0 ? '↑' : record.totalOrderCostRate < 0 ? '↓' : ''} {Math.abs(record.totalOrderCostRate)}%
          </div>}
        </div>
      )
    },
    {
      title: (
        <span>
          全域推广消耗占比
          <Tooltip title="全域推广消耗占比=全域推广产品投放期间产生的消耗/巨量千川直播竞价推广投放期间产生的消耗">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'overallPromotionExpenditureRatio',
      key: 'overallPromotionExpenditureRatio',
      sorter: (a, b) => a.overallPromotionExpenditureRatio - b.overallPromotionExpenditureRatio,
      width: 190,
      render: (text: number, record: any) => (
        <div>
          <div>{text || '-'}</div>
          {record.overallPromotionExpenditureRatioRate && <div style={{ color: record.overallPromotionExpenditureRatioRate > 0 ? '#f5222d' : record.overallPromotionExpenditureRatioRate < 0 ? '#52c41a' : '#000000' }}>
            {record.overallPromotionExpenditureRatioRate > 0 ? '↑' : record.overallPromotionExpenditureRatioRate < 0 ? '↓' : ''} {Math.abs(record.overallPromotionExpenditureRatioRate)}%
          </div>}
        </div>
      )
    }
  ];

  const channelAnalysisColumns: ColumnsType<ChannelAnalysisType> = [
    {
      title: '渠道名称',
      dataIndex: 'channelName',
      key: 'channelName',
      width: 150,
    },
    {
      title: (
        <span>
          直播时长
          <Tooltip title="直播持续时长，精确到小时，不计算暂停的时间">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'liveDuration',
      key: 'liveDuration',
      sorter: (a, b) => a.liveDuration - b.liveDuration,
      width: 120,
    },
    {
      title: (
        <span>
          人均观看时长
          <Tooltip title="直播间内平均每位用户的观看时长">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'averageViewingDuration',
      key: 'averageViewingDuration',
      sorter: (a, b) => a.averageViewingDuration - b.averageViewingDuration,
      width: 140,
    },
    {
      title: '直播间曝光次数',
      dataIndex: 'exposureCount',
      key: 'exposureCount',
      sorter: (a, b) => a.exposureCount - b.exposureCount,
      width: 140,
    },
    {
      title: '直播间观看次数',
      dataIndex: 'viewCount',
      key: 'viewCount',
      sorter: (a, b) => a.viewCount - b.viewCount,
      width: 140,
    },
    {
      title: (
        <span>
          直播间曝光-观看率（次数）
          <Tooltip title="直播间观看次数/直播间曝光次数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'exposureViewRate',
      key: 'exposureViewRate',
      sorter: (a, b) => a.exposureViewRate - b.exposureViewRate,
      width: 250,
      render: (text) => `${(text * 100).toFixed(2)}%`,
    },
    {
      title: (
        <span>
          商品曝光次数
          <Tooltip title="直播间内看到商品展示的次数，商品展示包含讲解商品卡、闪购商品卡、购物车内商品列表的商品卡片">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'productExposureCount',
      key: 'productExposureCount',
      sorter: (a, b) => a.productExposureCount - b.productExposureCount,
      width: 140,
    },
    {
      title: (
        <span>
          商品点击次数
          <Tooltip title="直播间内点击商品的次数，包含点击闪购商品卡、点击讲解商品卡、点击商品列表的商品">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'productClickCount',
      key: 'productClickCount',
      sorter: (a, b) => a.productClickCount - b.productClickCount,
      width: 140,
    },{
      title: (
        <span>
          商品曝光-点击率(次数)
          <Tooltip title="商品点击次数/商品曝光次数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'productExposureClickRate',
      key: 'productExposureClickRate',
      sorter: (a, b) => a.productExposureClickRate - b.productExposureClickRate,
      width: 210,
      render: (text) => `${(text * 100).toFixed(2)}%`,
    },{
      title: (
        <span>
          商品点击-成交率(次数)
          <Tooltip title="指定渠道下的成交订单数/商品点击次数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'productClickTransactionRate',
      key: 'productClickTransactionRate',
      sorter: (a, b) => a.productClickTransactionRate - b.productClickTransactionRate,
      width: 210,
      render: (text) => `${(text * 100).toFixed(2)}%`,
    },{
      title: (
        <span>
          成交订单数
          <Tooltip title="直播间成交订单数，包含本场直播间仅加购物车结束后成交的订单，不去除退款订单数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'transactionOrderCount',
      key: 'transactionOrderCount',
      sorter: (a, b) => a.transactionOrderCount - b.transactionOrderCount,
      width: 140,
    },{
      title: (
        <span>
          成交人数
          <Tooltip title="所选时间范围内，直播间下单并成功支付的人数。含直接在直播间下单和先从直播间加入购物车，后续再从购物车结算付款的订单。（支付后再取消订单/退款，数据不会被剔除，依然会计入本指标中）">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'transactionUserCount',
      key: 'transactionUserCount',
      sorter: (a, b) => a.transactionUserCount - b.transactionUserCount,
      width: 120,
    },{
      title: (
        <span>
          成交金额
          <Tooltip title="直播间成交的订单金额汇总，包含从本场直播先加到购物车，关播后再支付的订单。不去除退款订单金额">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      width: 120,
    },{
      title: (
        <span>
          成交件数
          <Tooltip title="直播间成交订单的总商品件数，包含本场直播间仅加购物车结束后成交的订单，不去除退款的订单">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'transactionItemsCount',
      key: 'transactionItemsCount',
      sorter: (a, b) => a.transactionItemsCount - b.transactionItemsCount,
      width: 120,
    },{
      title: (
        <span>
          千次观看成交金额
          <Tooltip title="直播间成交金额/观看次数*1000">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'thousandViewsTransactionAmount',
      key: 'thousandViewsTransactionAmount',
      sorter: (a, b) => a.thousandViewsTransactionAmount - b.thousandViewsTransactionAmount,
      width: 160,
    },{
      title: (
        <span>
          客单价
          <Tooltip title="直播间成交金额/直播间成交人数">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'averageTransactionAmountPerUser',
      key: 'averageTransactionAmountPerUser',
      sorter: (a, b) => a.averageTransactionAmountPerUser - b.averageTransactionAmountPerUser,
      width: 120,
    }
  ];

  return (
    <div className="live-broadcast-details">
      <div className="filters">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ width: '10vw' }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '70px', marginLeft: '5px'}}>开播时间</span>
            <RangePicker 
              value={dateRange}
              style={{width: '230px', minWidth: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            style={{minWidth: 290}}
            options={[
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' },
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
          <Select
            style={{ minWidth: 80, maxWidth: 160 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
          <div style={{marginLeft: '5px', minWidth: '200px'}}>
            <span style={{minWidth: '70px', marginRight: 5}}>对比周期</span>
            <Select
              style={{ minWidth: 90, maxWidth: 160 }}
              placeholder="请选择"
              value={compareType}
              onChange={onCompareTypeChange}
              options={[
                { value: '1', label: '连续周期' },
                { value: '2', label: '上月同期' },
                { value: '3', label: '上季度同期' },
                { value: '4', label: '去年同期' }
              ]}
            />
          </div>
        </div>
      </div>

      {/* 店铺详情 */}
      <div className="shop-details">
        <div className="shop-details-header">
          <img src={accountLiveData.avatar} alt="店铺logo" />
          <div className="shop-details-header-right">
            <h3>{accountLiveData.name}</h3>
            <p><span>账号类型：{accountLiveData.type}</span> <span>所属行业：{accountLiveData.industry}</span></p>
          </div>
        </div>
        <div className="shop-details-content">
          <ul>
            {accountLiveMainData.map((item) => (
              <li key={item.value}>
                <div className="data-title">
                  {item.name}
                  <Tooltip title={item.tip}>
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
                </div>
                <p className="data-value">{item.prefix}{accountLiveData[item.value]}{item.suffix}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* 主要数据模块 */}
      <div className="main-data-module">
        <h2>场次概览</h2>
        <ul className="live-view-type-btn">
          {liveViewTypeData.map((item, index) => (
            <li key={index} onClick={() => handleTypeClick(item.value)}
            className={selectedTypes.includes(item.value) ? 'active' : ''}>
              {item.name}
            </li>
          ))}
        </ul>
        {liveOverviews && (
          <div style={{ marginTop: '20px', height: '400px' }}>
            <ReactECharts 
              style={{height: '100%'}}
              option={getChartOption()}
              notMerge={true}
            />
          </div>
        )}

        <h2 style={{marginTop: '10px'}}>核心指标 <span style={{color: '#999', marginLeft: '10px', fontSize: '14px'}}>{promotionType}</span></h2>
        {coreIndicators && (
          <Table
            bordered
            columns={promotionType === '全域推广' ? globalCoreIndicatorsColumns : promotionType === '标准推广' ? standardCoreIndicatorsColumns : coreIndicatorsColumns}
            dataSource={coreIndicators}
            loading={loading}
            rowKey={(record) => `${record.timespan}_${record.consumption}`}  // 使用多个字段组合生成唯一key
            scroll={{ x: 'max-content', y: 670 }}
            pagination={false}
          />
        )}

        <h2 style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between'}}>渠道分析<span><Button style={{marginLeft: 5}} type="primary" icon={<DownloadOutlined />} onClick={() => downloadData()}>下载明细</Button><Button style={{marginRight: 5}} color="primary"  variant="text" icon={<SettingFilled />} onClick={() => setIsIndicatorConfigVisible(true)}>指标配置</Button></span></h2>

        <Table
          bordered
          columns={getChannelAnalysisColumns()}
          dataSource={channelAnalysis}
          loading={loading}
          rowKey="channelType"
          scroll={{ x: 'max-content' }}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
          }}
        />
        
      </div>

      
      
      <ChannelIndicatorModal
        isVisible={isIndicatorConfigVisible}
        onClose={() => setIsIndicatorConfigVisible(false)}
        onSave={handleSaveIndicatorConfig}
        indicator={indicatorMap}
        indicatorConfig={indicatorConfig}
      />
    </div>
  );
};

export default LiveBroadcastDetails;
