import React, { useState } from 'react';
import SlideMenu from '../../components/SlideMenu/SlideMenu'; // 导入 SlideMenu 组件
import HeaderModule from '../../components/HeaderModule/HeaderModule'; // 导入 HeaderModule
import './NavLayout.css'; // 新增样式文件

interface LayoutProps {
  children: React.ReactNode;
}

const NavLayout = ({ children }: LayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="layout">
      <nav className="sidebar" style={{ width: collapsed ? 80 : 220 }}>
        <SlideMenu collapsed={collapsed} /> {/* 传递 collapsed 状态 */}
      </nav>
      <div className="right-content" style={{ width: collapsed ? 'calc(100vw - 80px)' : 'calc(100vw - 220px)' }}>
        <HeaderModule onToggle={toggleCollapsed} collapsed={collapsed} />
        <main className='main-content'>{children}</main>
      </div>
    </div>
  );
};

export default NavLayout;