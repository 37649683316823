// 侧边栏组件
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import * as Icons from '@ant-design/icons';
import logo from '../../assets/imgs/logo-whitefont.png';
import logoSmall from '../../assets/imgs/logo-small.png';
import './SlideMenu.sass';
import { useUser } from '../../context/UserContext';
import api from '../../utils/api';

type MenuItem = Required<MenuProps>['items'][number] & {
  menucode?: string;
  children?: MenuItem[];
};

interface MenuData {
  menuId: number;
  code: string;
  name: string;
  parentId: number;
  path: string;
  component: string;
  icon: string;
  children?: MenuData[];
}

interface UserMenu {
  menuId: number;
}

const SlideMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [allMenus, setAllMenus] = useState<MenuData[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const menuFetchedRef = useRef(false);
  const isMounted = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);

  const fetchData = useCallback(async () => {
    if (location.pathname === '/login' || !user) {
      return;
    }

    if (fetchControllerRef.current) {
      fetchControllerRef.current.abort();
    }

    fetchControllerRef.current = new AbortController();
    if (isMounted.current) {
      try {
        const response = await api.get(`/menus`);
        if (isMounted.current) {
          setAllMenus(response.data.data);
          menuFetchedRef.current = true;
        }
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error('Failed to fetch data:', error);
        }
      }
    }
  }, [location.pathname, user]);

  useEffect(() => {
    isMounted.current = true;
    if (!menuFetchedRef.current && user) {
      fetchData();
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [fetchData, user]);

  const userMenuIds = useMemo(() => {
    return user?.menus?.map((menu: UserMenu) => menu.menuId) || [];
  }, [user]);

  const filterMenus = (menus: MenuData[]): MenuData[] => {
    return menus.filter(menu => userMenuIds.includes(menu.menuId)).map(menu => ({
      ...menu,
      children: menu.children ? filterMenus(menu.children) : undefined
    }));
  };

  const filteredMenus = useMemo(() => filterMenus(allMenus), [allMenus, userMenuIds]);

  const convertToAntdMenuItems = (menuData: MenuData[]): MenuItem[] => {
    return menuData.map(item => {
      const IconComponent = (Icons as any)[item.icon];
      const hasChildren = item.children && item.children.length > 0;
      return {
        key: item.path,
        icon: IconComponent ? <IconComponent /> : null,
        label: item.name,
        menucode: item.code,
        children: hasChildren ? convertToAntdMenuItems(item.children || []) : undefined,
        onClick: hasChildren ? undefined : () => navigate(item.path)
      };
    });
  };

  const items = useMemo(() => convertToAntdMenuItems(filteredMenus), [filteredMenus, navigate]);

  useEffect(() => {
    const path = location.pathname;
    const matchingItem = items.find((item: MenuItem) => 
      item.key === path || item.children?.some((child: MenuItem) => child.key === path)
    );
    setSelectedKeys([path]);
    if (matchingItem && matchingItem.key) {
      setOpenKeys(matchingItem.children ? [matchingItem.key.toString()] : []);
      document.title = (matchingItem as any).label as string;
    }
  }, [location, items]);

  const handleMenuClick: MenuProps['onClick'] = e => {
    const item = items.find((i: MenuItem) => i.key === e.key);
    if (item && !item.children) {
      navigate(e.key);
    }
  };

  return (
    // 如果是登录页面，不渲染菜单
    location.pathname === '/login' ? null : (
      <div className="slide-menu" style={{ width: collapsed ? 80 : 220 }}>
        <img className="logo" src={collapsed ? logoSmall : logo} alt="Logo" />
        <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={items}
          onClick={handleMenuClick}
        />
      </div>
    )
  );
};

export default SlideMenu;
