import React, { useState, useEffect, useRef } from 'react';
import { Select, DatePicker, Button, Space, Radio, Modal, Table, message, Card, Avatar, Tooltip, Progress } from 'antd';
import { PlusCircleOutlined, PlusOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import api from '../../../utils/api';
import './AnchorAnalysis.scss';
import AddScheduleModal from '../components/AddScheduleModal/AddScheduleModal';
import CreateAnchorModal from '../components/CreateAnchorModal/CreateAnchorModal';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { Option } = Select;
const { RangePicker } = DatePicker;

interface ScheduleData {
  date: string;
  schedules: {
    name: string;
    startTime: string;
    endTime: string;
    type: string;
  }[];
}

interface Account {
  id: string;
  name: string;
}

interface Anchor {
  id: number;
  name: string;
  avatar: string | null;
}

interface ScheduleForm {
  key: number;
  anchorId?: number;
  startTime?: string;
  endTime?: string;
}

interface AnchorAnalysis {
  anchorName: string;
  liveDuration: number;
  totalTransactionAmount: number;
  transactionAmountPerHour: number;
  highestOnlineViewers: number;
  averageOnlineViewers: number;
  averageUnitPrice: number;
  exposureViewRate: number;
  retentionRate: number;
  viewInteractionRate: number;
  newFansCount: number;
  newGroupMembersCount: number;
  uvValue: number;
  totalTransactions: number;
  top3StrategyGroups: Array<{name: string; ratio: number}>;
  gender: Array<{name: string; ratio: number}>;
  top3AgeGroups: Array<{name: string; ratio: number}>;
  top3CityLevels: Array<{name: string; ratio: number}>;
  top3GeographicalDistributions: Array<{name: string; ratio: number}>;
}

const generateDateRange = (start: Dayjs, end: Dayjs) => {
  const dates: ScheduleData[] = [];
  let current = start;

  while (current.isSameOrBefore(end, 'day')) {
    dates.push({
      date: current.format('YYYY-MM-DD'),
      schedules: []
    });
    current = current.add(1, 'day');
  }
  return dates;
};

const timeToIndex = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 2 + (minutes >= 30 ? 1 : 0);
};

const AnchorAnalysis: React.FC = () => {
  const [scheduleData, setScheduleData] = useState<ScheduleData[]>([]);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs(),
    dayjs().add(7, 'day').endOf('day')
  ]);
  const [selectedAnchor, setSelectedAnchor] = useState<string>('all');
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [timeQuickValue, setTimeQuickValue] = useState<string>('future7');
  const [accounts, setAccounts] = useState<Account[]>([]);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);
  const scheduleContentRef = useRef<HTMLDivElement>(null);
  const [columnWidth, setColumnWidth] = useState(120);
  const containerRef = useRef<HTMLDivElement>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [scheduleForm, setScheduleForm] = useState<ScheduleForm[]>([]);
  const [anchors, setAnchors] = useState<Anchor[]>([]);
  const [editSchedule, setEditSchedule] = useState<any>(null);
  const [createAnchorVisible, setCreateAnchorVisible] = useState(false);
  const [anchorAnalyses, setAnchorAnalyses] = useState<{[key: string]: AnchorAnalysis}>({});
  const [selectedAnchors, setSelectedAnchors] = useState<string[]>([]);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      fetchAccounts();
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, []);
  useEffect(() => {
    if (selectedAccount) {
      const dates = generateDateRange(dateRange[0], dateRange[1]);
      setScheduleData(dates);
      fetchData();
    }
  }, [selectedAccount, dateRange]);

  useEffect(() => {
    if (scheduleContentRef.current) {
      const scrollTop = 16 * 2 * 30;
      scheduleContentRef.current.scrollTop = scrollTop;
    }
  }, []);

  const onAccountChange = (value: string) => {
    setSelectedAccount(value);
  };

  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const today = dayjs();
    let startDate;
    let endDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day'); 
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;
      case 'future7':
        startDate = today;
        endDate = today.add(7, 'day').endOf('day');
        break;
      case 'future14':  
        startDate = today;
        endDate = today.add(14, 'day').endOf('day');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
        endDate = dayjs().subtract(1, 'day').endOf('day');
    }
    
    setDateRange([startDate, endDate]);
  };
  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`/schedule/anchors/account/${selectedAccount}`, {
        params: {
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD')
        },
      });
      
      const dates = generateDateRange(dateRange[0], dateRange[1]);
      const schedules = response.data.data.schedules || [];
      const analyses = response.data.data.anchorLiveAnalyses || [];
      
      // 处理排班数据
      const schedulesByDay = schedules.reduce((acc: {[key: string]: any[]}, curr: any) => {
        if (!acc[curr.day]) {
          acc[curr.day] = [];
        }
        acc[curr.day].push({
          name: curr.anchorName,
          startTime: curr.start.split(' ')[1],
          endTime: curr.end.split(' ')[1],
          type: 'live',
          ...curr
        });
        return acc;
      }, {});

      const mergedData = dates.map(date => ({
        ...date,
        schedules: schedulesByDay[date.date] || []
      }));
      
      // 处理主播分析数据
      const analysesMap = analyses.reduce((acc: any, curr: AnchorAnalysis) => {
        acc[curr.anchorName] = curr;
        return acc;
      }, {});
      
      setScheduleData(mergedData);
      setAnchorAnalyses(analysesMap);
      
      // 如果还没有选中的主播，默认选中前三个
      if (selectedAnchors.length === 0 && analyses.length > 0) {
        setSelectedAnchors(analyses.slice(0, 3).map((a: AnchorAnalysis) => a.anchorName));
      }
    } catch (error) {
      console.error('Failed to fetch anchor schedule data:', error);
    }
  };
  
  const renderTimeLabels = () => {
    const labels = [];
    for (let i = 0; i < 48; i++) {
      const hour = Math.floor(i / 2);
      const minute = i % 2 === 0;
      const isFullHour = minute;
      const timeText = isFullHour ? `${hour.toString().padStart(2, '0')}:00` : '';
      
      labels.push(
        <div 
          key={i} 
          className="time-label"
          data-hour-type={isFullHour ? 'full' : 'half'}
        >
          {isFullHour && <span className="time-text">{timeText}</span>}
        </div>
      );
    }
    return labels;
  };

  const isCurrentOrFutureDate = (date: string) => {
    return dayjs(date).startOf('day').isSameOrAfter(dayjs().startOf('day'));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const dateHeader = document.querySelector('.date-header') as HTMLDivElement;
    const dateHeaderContent = document.querySelector('.date-header-content') as HTMLDivElement;
    
    if (dateHeaderContent) {
      dateHeaderContent.style.transform = `translateX(-${target.scrollLeft}px)`;
    }
  };

  const calculateColumnWidth = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const timeAxisWidth = 60; // 时间轴宽度
      const availableWidth = containerWidth - timeAxisWidth;
      const daysCount = scheduleData.length;
      
      if (daysCount > 0) {
        // 计算每列宽度，最小 120px
        const width = Math.max(120, Math.floor(availableWidth / daysCount));
        setColumnWidth(width);
      }
    }
  };

  useEffect(() => {
    calculateColumnWidth();
    const resizeObserver = new ResizeObserver(calculateColumnWidth);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [scheduleData.length]);

  const WEEKDAYS = ['日', '一', '二', '三', '四', '五', '六'];

  const getWeekDay = (date: string) => {
    const day = dayjs(date).day();
    return `周${WEEKDAYS[day]}`;
  };

  const generatePastelColor = (seed: string) => {
    const hash = seed.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 90%)`; 
  };

  const handleScheduleClick = (schedule: any) => {
    setSelectedDate(schedule.day);
    setEditSchedule(schedule);
    setModalVisible(true);
  };

  const handleAnchorSelect = (anchorName: string) => {
    setSelectedAnchors(prev => {
      if (prev.includes(anchorName)) {
        return prev.filter(name => name !== anchorName);
      }
      return [...prev, anchorName];
    });
  };

  const formatValue = (value: number | string, type: 'money' | 'percent' | 'number' = 'number') => {
    if (typeof value === 'string') return value;
    switch (type) {
      case 'money':
        return `¥${value.toFixed(2)}`;
      case 'percent':
        return `${value.toFixed(2)}%`;
      default:
        return value.toLocaleString();
    }
  };

  const formatArrayData = (data: Array<{name: string; ratio: number}>) => {
    const colors = ['#1890ff', '#52c41a', '#faad14']; // 蓝、绿、黄

    return (
      <div>
        {data.map((item, index) => (
          <div key={item.name} style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: index < data.length - 1 ? '8px' : 0
          }}>
            <span style={{ minWidth: '60px' }}>{item.name}</span>
            <Progress 
              percent={item.ratio} 
              strokeColor={colors[index]}
              size="small"
              format={() => ''} // 不显示进度条内的文字
              style={{ flex: 1, margin: '0 8px' }}
            />
            <span style={{ minWidth: '50px' }}>
              {formatValue(item.ratio, 'percent')}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const dataSource = [
    {
      metric: '概要数据',
      children: [
        { metric: '直播时长', key: 'liveDuration' },
        { metric: '直播间成交金额', key: 'totalTransactionAmount', type: 'money' },
        { metric: '单小时成交金额', key: 'transactionAmountPerHour', type: 'money' },
        { metric: '最高在线人数', key: 'highestOnlineViewers' },
        { metric: '平均在线人数', key: 'averageOnlineViewers' },
        { metric: '平均件单价', key: 'averageUnitPrice', type: 'money' }
      ]
    },
    {
      metric: '能力分析',
      children: [
        { metric: '引流能力(曝光-观看率)', key: 'exposureViewRate', type: 'percent' },
        { metric: '留人能力(留存率)', key: 'retentionRate', type: 'percent' },
        { metric: '互动能力(观看-互动率)', key: 'viewInteractionRate', type: 'percent' },
        { metric: '吸粉能力(新增粉丝数)', key: 'newFansCount' },
        { metric: '吸粉能力(新加团人数)', key: 'newGroupMembersCount' },
        { metric: '转化能力(UV价值)', key: 'uvValue', type: 'money' }
      ]
    },
    {
      metric: '购买用户分析',
      children: [
        { metric: '成交人数', key: 'totalTransactions' }
      ]
    },
    {
      metric: '用户画像分析',
      children: [
        { metric: '策略人群Top3', key: 'top3StrategyGroups', isArray: true },
        { metric: '性别分布', key: 'gender', isArray: true },
        { metric: '年龄段分布', key: 'top3AgeGroups', isArray: true },
        { metric: '城市等级分布', key: 'top3CityLevels', isArray: true },
        { metric: '地域分布', key: 'top3GeographicalDistributions', isArray: true }
      ]
    }
  ].map(group => ({
    ...group,
    children: group.children.map((item: any) => ({
      ...item,
      ...selectedAnchors.reduce((acc, anchorName) => {
        const analysis = anchorAnalyses[anchorName];
        if (!analysis) return acc;
        
        return {
          ...acc,
          [anchorName]: item.isArray 
            ? formatArrayData(analysis[item.key as keyof AnchorAnalysis] as any)
            : formatValue(analysis[item.key as keyof AnchorAnalysis] as any, item.type)
        };
      }, {})
    }))
  }));

  const tooltipMap: {[key: string]: string} = {
    'totalTransactionAmount': '直播间成交的订单金额汇总（含本场直播间仅加购物车结束后成交的订单），不去除退款订单金额',
    'transactionAmountPerHour': '主播上播时间范围内，平均每小时直播间的成交金额',
    'highestOnlineViewers': '主播上播时间范围内，直播间最高同时在线人数',
    'averageOnlineViewers': '主播上播时间范围内，直播间平均每分钟同时在线人数',
    'exposureViewRate': '主播上播期间，直播间进入人次/直播间曝光人次。主播引流能力越强，用户越愿意进入直播间观看',
    'retentionRate': '主播上播时间范围内，1-离开人次 /（起始在线人数+进入人次）',
    'viewInteractionRate': '主播上播期间，直播间的互动人数/直播间观看人数。互动行为包括：点赞、评论、分享',
    'newFansCount': '主播上播时间范围内，新增的粉丝数（不剔除取消关注）',
    'newGroupMembersCount': '主播上播时间范围内，新加入粉丝团的人数（不剔除取消关注）',
    'uvValue': '主播上播时间范围内，每分钟（直播成交金额/在线总人数）的平均值',
    'totalTransactions': '主播上播时间范围内，成交用户总数（分钟用户数去重后加和）',
    'top3StrategyGroups': `小镇青年：四线及以下城市小于35岁的群体<br>
小镇中老年：四线及以下城市大于35岁的群体<br>
Z世代: 三线及以上城市小于24岁的年轻群体<br>
精致妈妈：三线及以上城市，25-35岁备孕或已生育白领女性<br>
新锐白领：三线及以上城市，25-35岁白领、IT、金融群体<br>
资深中产：三线及以上城市，36-50岁白领、IT、金融群体<br>
都市蓝领：三线及以上城市，25-35岁消费能力中下群体<br>
都市银发：三线及以上城市，大于50岁群体`
  };

  const columns = [
    {
      title: '对比主播',
      dataIndex: 'metric',
      fixed: 'left' as const,
      width: 90,
      render: (text: string, record: any) => {
        const tooltip = tooltipMap[record.key];
        return (
          <span>
            {text}
            {tooltip && (
              <Tooltip 
                title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
                overlayStyle={{ maxWidth: 500 }}
                overlayInnerStyle={{ 
                  whiteSpace: 'pre-line',
                  textAlign: 'left'
                }}
              >
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            )}
          </span>
        );
      }
    },
    ...selectedAnchors.map(anchorName => ({
      title: anchorName,
      dataIndex: anchorName,
      width: 180,
      align: 'left' as const
    }))
  ];

  const renderScheduleBlocks = (day: ScheduleData) => {
    return day.schedules.map((schedule, index) => {
      const startIndex = timeToIndex(schedule.startTime);
      const endIndex = timeToIndex(schedule.endTime);
      const duration = endIndex - startIndex;
      
      const blockColor = generatePastelColor(schedule.name);
      
      return (
        <div
          key={index}
          className={`schedule-block ${schedule.type}`}
          style={{
            top: `${startIndex * 30 + 1}px`,
            height: `${duration * 30 - 9}px`,
            width: `${columnWidth - 10}px`,
            '--schedule-block-color': blockColor,
            cursor: 'pointer'
          } as React.CSSProperties}
          title={`${schedule.name}\n${schedule.startTime}-${schedule.endTime}`}
          onClick={() => handleScheduleClick(schedule)}
        >
          <span className="anchor-name">{schedule.name}</span>
        </div>
      );
    });
  };

  const handleAddSchedule = (date: string) => {
    setSelectedDate(date);
    setEditSchedule(null);
    setModalVisible(true);
  };

  const fetchAnchors = async () => {
    try {
      const response = await api.get('/anchors');
      if (response.data.code === 1) {
        setAnchors(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch anchors:', error);
    }
  };

  const handleModalOk = async (data: ScheduleForm[]) => {
    try {
      if(data.some(item => !item.startTime || !item.endTime || !item.anchorId)) {
        message.error('主播和时间不能为空');
        return;
      }
      const schedules = data.map(item => ({
        anchorId: item.anchorId,
        start: `${selectedDate} ${dayjs(item.startTime).format('HH:mm:ss')}`,
        end: `${selectedDate} ${dayjs(item.endTime).format('HH:mm:ss')}`
      }));

      const params = {
        day: selectedDate,
        dyAccountId: selectedAccount,
        lives: [{
          liveId: null,
          liveName: null,
          schedules
        }]
      };

      const response = await api.post('/schedule/anchors', params);
      if (response.data.code === 1) {
        fetchData(); // 刷新数据
        setModalVisible(false);
      }
    } catch (error) {
      console.error('Failed to add schedule:', error);
    }
  };

  return (
    <div className="anchor-analysis">
      <Card title="主播排班" style={{paddingBottom: '60px'}}>
        <Space className="filters" size="middle">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ width: 140, minWidth: 140 }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{minWidth: '60px'}}>日期</span>
            <RangePicker 
              value={dateRange}
              style={{width: '230px', minWidth: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            options={[
              { label: '未来两周', value: 'future14' },
              { label: '未来一周', value: 'future7' },
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' }
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
          />
        </Space>

        <div className="schedule-grid-container" ref={containerRef}>
          <div className="date-header">
            <div 
              className="date-header-content"
              style={{ '--column-width': `${columnWidth}px` } as React.CSSProperties}
            >
              {scheduleData.map((day) => (
                <div key={day.date} className="date-column">
                  <div className="date-text">
                    <span>{dayjs(day.date).format('MM/DD')}</span>
                    <span className="weekday">{getWeekDay(day.date)}</span>
                  </div>
                  {isCurrentOrFutureDate(day.date) && (
                    <PlusCircleOutlined 
                      className="add-schedule"
                      style={{ fontSize: '16px', color: '#1890ff', cursor: 'pointer' }}
                      onClick={() => handleAddSchedule(day.date)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          
          <div 
            className="schedule-content" 
            ref={scheduleContentRef}
            onScroll={handleScroll}
          >
            <div className="time-axis">
              <div className="time-labels">
                {renderTimeLabels()}
              </div>
            </div>
            
            <div className="schedule-main">
              <div 
                className="grid-content"
                style={{ '--column-width': `${columnWidth}px` } as React.CSSProperties}
              >
                {scheduleData.map((day) => (
                  <div key={day.date} className="date-column">
                    {Array.from({ length: 48 }).map((_, index) => (
                      <div key={index} className="time-block" />
                    ))}
                    {renderScheduleBlocks(day)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card 
        title={
          <Space style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            主播对比
            <Button
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => setCreateAnchorVisible(true)}
            >
              创建主播
            </Button>
          </Space>
        } 
        style={{marginTop: '20px'}}
      >
        <div className="anchor-list" style={{ overflowX: 'auto', marginBottom: '20px' }}>
          <Space size="middle">
            {Object.keys(anchorAnalyses).map(anchorName => (
              <div
                key={anchorName}
                className={`anchor-item ${selectedAnchors.includes(anchorName) ? 'selected' : ''}`}
                onClick={() => handleAnchorSelect(anchorName)}
                style={{ textAlign: 'center', cursor: 'pointer' }}
              >
                <Avatar 
                  size={64}
                  style={{ 
                    backgroundColor: generatePastelColor(anchorName)
                  }}
                >
                  {anchorName[0]}
                </Avatar>
                <div style={{ marginTop: '8px' }}>{anchorName}</div>
              </div>
            ))}
          </Space>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: 'max-content' }}
          bordered
          rowKey="metric"
          rowClassName={(record) => record.children ? 'group-row' : ''}
          defaultExpandAllRows={true}
          expandable={{
            showExpandColumn: false
          }}
        />
      </Card>

      {/* 添加排班弹窗 */}
      <AddScheduleModal
        visible={modalVisible}
        date={selectedDate}
        onCancel={() => {
          setModalVisible(false);
          setEditSchedule(null);
        }}
        onOk={handleModalOk}
        editData={editSchedule}
      />
      {/* 创建主播弹窗 */}
      <CreateAnchorModal
        visible={createAnchorVisible}
        onCancel={() => setCreateAnchorVisible(false)}
        onSuccess={fetchAnchors}
        accounts={accounts}
      />
    </div>
  );
};

export default AnchorAnalysis;
