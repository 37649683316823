import React, { useState, Key, useEffect } from 'react';
import { Modal, Form, Input, Button, Transfer, Select, Row, Col, message } from 'antd';
import type { TransferProps } from 'antd';
import api from '../../../utils/api';
import './UserManagement.css';

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
  shopId?: number;
}

interface ShopData {
  shopId: number;
  accountIds: number[];
}

const AddUserModal: React.FC<{ visible: boolean; onClose: (isRefresh?: boolean) => void; userId?: string | number }> = ({ visible, onClose, userId }) => {
  const [form] = Form.useForm();
  const [roleTargetKeys, setRoleTargetKeys] = useState<Key[]>([]);
  const [shopTargetKeys, setShopTargetKeys] = useState<Key[]>([]);
  const [accountTargetKeys, setAccountTargetKeys] = useState<Key[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [roleDataSource, setRoleDataSource] = useState<RecordType[]>([]);
  const [shopDataSource, setShopDataSource] = useState<RecordType[]>([]);
  const [accountDataSource, setAccountDataSource] = useState<RecordType[]>([]);
  const [shopAccountMap, setShopAccountMap] = useState<{ [key: number]: number[] }>({});

  useEffect(() => {
    if (visible) {
      fetchRoles();
      fetchShops();
      fetchAccounts();
      if (userId) {
        setIsEditMode(true);
        fetchUserDetails();
      } else {
        setIsEditMode(false);
        resetForm();
      }
    }
  }, [userId, visible]);

  const resetForm = () => {
    form.resetFields();
    setRoleTargetKeys([]);
    setShopTargetKeys([]);
    setAccountTargetKeys([]);
    setShopAccountMap({});
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get('/roles');
      const roles = response.data.data.map((role: any) => ({
        key: role.id,
        title: role.name,
        description: role.description,
        chosen: false,
      }));
      setRoleDataSource(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchShops = async () => {
    try {
      const response = await api.get('/shops');
      const shops = response.data.data.map((shop: any) => ({
        key: shop.id,
        title: shop.name,
        description: shop.description,
        chosen: false,
      }));
      setShopDataSource(shops);
    } catch (error) {
      console.error('Error fetching shops:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts');
      const accounts = response.data.data.map((account: any) => ({
        key: account.id,
        title: account.name,
        description: account.description,
        chosen: false,
        shopId: account.shopId,
      }));
      setAccountDataSource(accounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/users/${userId}`);
      const userData = response.data.data;
      form.setFieldsValue({
        username: userData.username,
        phone: userData.phone,
        gender: userData.gender,
      });
      setRoleTargetKeys(userData.roles);
      
      const shopIds: number[] = [];
      const accountIds: number[] = [];
      const newShopAccountMap: { [key: number]: number[] } = {};
      
      userData.shops.forEach((shop: ShopData) => {
        shopIds.push(shop.shopId);
        newShopAccountMap[shop.shopId] = shop.accountIds;
        accountIds.push(...shop.accountIds);
      });
      
      setShopTargetKeys(shopIds);
      setAccountTargetKeys(accountIds);
      setShopAccountMap(newShopAccountMap);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleSubmit = async (values: any) => {
    if (roleTargetKeys.length === 0) {
      message.error('请选择至少一个角色');
      return;
    }
    if (shopTargetKeys.length === 0) {
      message.error('请选择至少一个店铺');
      return;
    }
    if (accountTargetKeys.length === 0) {
      message.error('请选择至少一个抖音号');
      return;
    }
    if(values.password !== values.confirmPwd) {
      message.error('两次密码不一致');
      return;
    }

    const apiUrl = '/users';
    const method = isEditMode ? 'put' : 'post';
    
    const shops: ShopData[] = shopTargetKeys.map((shopId) => ({
      shopId: Number(shopId),
      accountIds: shopAccountMap[Number(shopId)] || []
    }));

    const body = isEditMode 
      ? { id: userId, ...values, roles: roleTargetKeys, shops } 
      : { ...values, roles: roleTargetKeys, shops };

    try {
      const response = await api[method](apiUrl, body);
      if (response.data.code === 1) {
        message.success('用户信息提交成功');
        onClose(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('提交失败，请重试');
    }
  };

  const handleSearch: TransferProps['onSearch'] = (dir, value) => {
    console.log('search:', dir, value);
  };

  const filterOption = (inputValue: string, option: RecordType) => option.description.indexOf(inputValue) > -1;

  const handleRoleChange = (newTargetKeys: Key[]) => {
    setRoleTargetKeys(newTargetKeys);
  };

  const handleShopChange = (newTargetKeys: Key[]) => {
    setShopTargetKeys(newTargetKeys);

    const newShopAccountMap = { ...shopAccountMap };
    Object.keys(newShopAccountMap).forEach(shopId => {
      if (!newTargetKeys.includes(Number(shopId))) {
        delete newShopAccountMap[Number(shopId)];
      }
    });
    setShopAccountMap(newShopAccountMap);

    const newAccountTargetKeys = accountTargetKeys.filter(accountId => 
      accountDataSource.find(account => 
        account.key === accountId && newTargetKeys.includes(account.shopId!)
      )
    );
    setAccountTargetKeys(newAccountTargetKeys);
  };

  const handleAccountChange = (newTargetKeys: Key[]) => {
    setAccountTargetKeys(newTargetKeys);
    
    const newShopAccountMap: { [key: number]: number[] } = {};
    newTargetKeys.forEach(accountId => {
      const account = accountDataSource.find(a => a.key === accountId);
      if (account && account.shopId) {
        if (!newShopAccountMap[account.shopId]) {
          newShopAccountMap[account.shopId] = [];
        }
        newShopAccountMap[account.shopId].push(Number(accountId));
      }
    });
    setShopAccountMap(newShopAccountMap);
  };

  return (
    <Modal
      title={isEditMode ? "编辑用户" : "新增用户"}
      open={visible}
      width={800}
      maskClosable={false}
      keyboard={false}
      onCancel={() => {
        resetForm();
        onClose(false);
      }}
      footer={[
        <Button key="cancel" onClick={() => {
          resetForm();
          onClose(false);
        }}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          提交
        </Button>,
      ]}
    >
      <Form
        className='add-user-modal'
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="用户名"
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gender"
              label="性别"
              rules={[{ required: true, message: '请选择性别' }]}
            >
              <Select>
                <Select.Option value={0}>女</Select.Option>
                <Select.Option value={1}>男</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="手机号"
              rules={[{ required: true, message: '请输入手机号' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="密码"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPwd"
              label="确认密码"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="角色"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              required
              rules={[{ required: true, message: '请选择角色' }]}
            >
              <Transfer
                titles={['可选角色', '已选角色']}
                dataSource={roleDataSource}
                showSearch
                filterOption={filterOption}
                targetKeys={roleTargetKeys}
                onChange={handleRoleChange}
                onSearch={handleSearch}
                render={(item) => item.title}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="店铺"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              required
              rules={[{ required: true, message: '请选择至少一个店铺' }]}
            >
              <Transfer
                titles={['可选店铺', '已选店铺']}
                dataSource={shopDataSource}
                showSearch
                filterOption={filterOption}
                targetKeys={shopTargetKeys}
                onChange={handleShopChange}
                onSearch={handleSearch}
                render={(item) => item.title}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="抖音号"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              required
              rules={[{ required: true, message: '请选择至少一个抖音号' }]}
            >
              <Transfer
                titles={['可选抖音号', '已选抖音号']}
                dataSource={accountDataSource.filter(account => shopTargetKeys.includes(account.shopId!))}
                showSearch
                filterOption={filterOption}
                targetKeys={accountTargetKeys}
                onChange={handleAccountChange}
                onSearch={handleSearch}
                render={(item) => item.title}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddUserModal;