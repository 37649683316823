import React, { useState } from 'react';
import HeaderModule from '../../components/HeaderModule/HeaderModule';
import './HeaderLayout.css';
interface LayoutProps {
  children: React.ReactNode;
}

const NavLayout = ({ children }: LayoutProps) => {

  return (
    <div className="header-layout">
        <HeaderModule onlyHeader={true} />
        <main className='main-content'>{children}</main>
    </div>
  );
};

export default NavLayout;