import { useEffect, useState } from 'react';
import SimpleLayout from '../../layout/SimpleLayout/SimpleLayout';
import logo from '../../assets/imgs/logo-detail.png';
import './Login.css';
import { Form, Input, Button, message, Tabs } from 'antd';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [accountForm] = Form.useForm();
  const [phoneForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('account');
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
      document.title = '登录';
  }, []);

  // 获取验证码
  const handleGetCode = async () => {
    try {
      const values = await phoneForm.validateFields(['phone']);
      const response = await api.get(`/sms/phone/code?phone=${values.phone}`);
      if (response.data.code === 1) {
        messageApi.success('验证码已发送');
        setCountdown(60);
        const timer = setInterval(() => {
          setCountdown(prev => {
            if (prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        messageApi.error(response.data.message || '发送验证码失败');
      }
    } catch (error) {
      if (error instanceof Error) {
        messageApi.error(error.message);
      }
    }
  };

  // 手机号登录
  const handlePhoneLogin = async (values: { phone: string; code: string }) => {
    try {
      const response = await api.post('/login/phone', values);
      if (response.data.code === 1) {
        messageApi.success('登录成功');
        document.cookie = `live_polit_token=${response.data.data.token}; path=/`;
        
        if (response.data.data.shops && response.data.data.shops.length > 1) {
          navigate('/shops');
        } else {
          navigate('/dashboard');
        }
      } else {
        messageApi.error(response.data.message || '登录失败');
      }
    } catch (error) {
      if (error instanceof Error) {
        messageApi.error(error.message || '登录失败');
      } else {
        messageApi.error('登录失败');
      }
    }
  };

  const handleLogin = async (values: { username: string; password: string }) => {
    try {
      const response = await api.post('/login', values);
      if (response.data.code === 1) {
        messageApi.success('登录成功');
        document.cookie = `live_polit_token=${response.data.data.token}; path=/`;
        
        // 直接根据登录响应中的 shops 数据进行跳转
        if (response.data.data.shops && response.data.data.shops.length > 1) {
          navigate('/shops');
        } else {
          navigate('/dashboard');
        }
      } else {
        console.log(response.data)
        messageApi.error(response.data.message || '登录失败');
      }
    } catch (error) {
      if (error instanceof Error) {
        messageApi.error(error.message || '登录失败');
      } else {
        messageApi.error('登录失败');
      }
    }
  };

  return (
    <SimpleLayout>
      {contextHolder}
      <div className='login-container'>
        <img className='login-logo' src={logo} alt="logo" style={{ marginBottom: '20px' }} />
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          centered
          items={[
            {
              key: 'account',
              label: '账号密码登录',
              children: (
                <Form
                  form={accountForm}
                  name="loginForm"
                  className='login-form'
                  onFinish={handleLogin}
                  style={{ width: '300px' }}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: '请输入用户名!' }]}
                  >
                    <Input placeholder="用户名" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码!' }]}
                  >
                    <Input.Password placeholder="密码" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              ),
            },
            {
              key: 'phone',
              label: '手机号登录',
              children: (
                <Form
                  form={phoneForm}
                  className='login-form'
                  name="phoneLoginForm"
                  onFinish={handlePhoneLogin}
                  style={{ width: '300px' }}
                >
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: '请输入手机号!' },
                      { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号!' }
                    ]}
                  >
                    <Input placeholder="手机号" />
                  </Form.Item>
                  <Form.Item
                    name="code"
                    rules={[{ required: true, message: '请输入验证码!' }]}
                  >
                    <Input
                      placeholder="验证码"
                      suffix={
                        <Button
                          type="link"
                          disabled={countdown > 0}
                          onClick={handleGetCode}
                        >
                          {countdown > 0 ? `${countdown}s后重新获取` : '获取验证码'}
                        </Button>
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              ),
            },
          ]}
        />
      </div>
    </SimpleLayout>
  );
};

export default Login;
