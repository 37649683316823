import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Row, Col, Tree } from 'antd';
import api from '../../../utils/api';
import { Key } from 'antd/es/table/interface';

interface MenuData {
  menuId: number | string;
  name: string;
  key?: number | string;
  title?: string;
  children?: MenuData[];
}

interface SelectedMenu {
  menuId: string;
  action: string;
}

const AddRoleModal = ({ visible, onClose, roleId }: { visible: boolean; onClose: () => void; roleId?: string | number }) => {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [menuTree, setMenuTree] = useState<MenuData[]>([]);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<Key[]>([]);

  useEffect(() => {
    if (visible) {
      fetchMenuTree();
      if (roleId) {
        setIsEditMode(true);
        fetchRoleDetails(roleId);
      } else {
        setIsEditMode(false);
        form.resetFields();
        setSelectedMenuKeys([]);
      }
    }
  }, [roleId, visible, form]);

  const fetchMenuTree = async () => {
    try {
      const response = await api.get('/menus');
      const transformedMenuTree = transformMenuTree(response.data.data);
      setMenuTree(transformedMenuTree);
    } catch (error) {
      console.error('Error fetching menu tree:', error);
    }
  };

  const transformMenuTree = (menuData: MenuData[]): MenuData[] => {
    return menuData.map(menu => ({
      ...menu,
      children: [
        { menuId: `${menu.menuId}-1`, name: '查看', isLeaf: true },
        { menuId: `${menu.menuId}-2`, name: '编辑', isLeaf: true },
        ...(menu.children ? transformMenuTree(menu.children) : [])
      ]
    }));
  };

  const fetchRoleDetails = async (id: string | number) => {
    try {
      const response = await api.get(`/roles/${id}`);
      const roleData = response.data.data;
      form.setFieldsValue({
        name: roleData.name,
        code: roleData.code,
      });
      const selectedKeys = roleData.menus.flatMap((menu: SelectedMenu) => {
        const keys = [menu.menuId];
        if (menu.action) {
          keys.push(...menu.action.split(',').map(action => `${menu.menuId}-${action}`));
        }
        return keys;
      });
      setSelectedMenuKeys(selectedKeys);
    } catch (error) {
      console.error('Error fetching role details:', error);
    }
  };

  const handleSubmit = async (values: any) => {
    const apiUrl = `/roles`;
    const method = isEditMode ? 'put' : 'post';
    const menus = getSelectedMenus();
    const body = {
      ...(isEditMode && { id: roleId }),
      ...values,
      menus,
    };

    try {
      await api[method](apiUrl, body);
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const getSelectedMenus = (): SelectedMenu[] => {
    const menus: SelectedMenu[] = [];
    selectedMenuKeys.forEach(key => {
      const [menuId, action] = String(key).split('-');
      const existingMenu = menus.find(m => m.menuId === menuId);
      if (existingMenu) {
        if (action) {
          existingMenu.action += existingMenu.action ? `,${action}` : action;
        }
      } else {
        menus.push({ menuId, action: action || '' });
      }
    });
    return menus;
  };

  const onMenuCheck = (checked: Key[] | { checked: Key[]; halfChecked: Key[] }) => {
    if (Array.isArray(checked)) {
      setSelectedMenuKeys(checked);
    } else {
      setSelectedMenuKeys(checked.checked);
    }
  };

  return (
    <Modal
      title={isEditMode ? "编辑角色" : "新增角色"}
      open={visible}
      width={700}
      maskClosable={false}
      keyboard={false}
      onCancel={onClose}
      footer={null}
    >
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="角色名"
              rules={[{ required: true, message: '请输入角色名' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="code"
              label="Code"
              rules={[{ required: true, message: '请输入Code' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="菜单权限"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
            >
              <Tree
                checkable
                checkedKeys={selectedMenuKeys}
                onCheck={onMenuCheck}
                treeData={menuTree as any}
                fieldNames={{
                  key: 'menuId',
                  title: 'name',
                  children: 'children',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">提交</Button>
            <Button onClick={onClose} style={{ marginLeft: 8 }}>取消</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddRoleModal;