import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Avatar, Typography, message, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import api from '../../utils/api';
import './Shops.scss';

const { Title, Text } = Typography;

interface Shop {
  shopId: number;
  shopName: string;
  shopAvatar?: string;
  accountIds?: number[];
  selected: number;
}

const Shops: React.FC = () => {
  const { user, setUser } = useUser();
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.shops && user.shops.length > 0) {
      const initialSelectedShop = user.shops.find(shop => shop.selected === 1);
      setSelectedShop(initialSelectedShop || null);
    }
  }, [user]);

  const handleShopSelect = async (shop: Shop) => {
    try {
      await api.put(`/login/shop/${shop.shopId}`);
      setSelectedShop(shop);
      if (user && user.shops) {
        const updatedShops = user.shops.map(s => ({
          ...s,
          selected: s.shopId === shop.shopId ? 1 : 0
        }));
        setUser({ ...user, shops: updatedShops });
      }
      message.success(`已切换到店铺：${shop.shopName}`);
    } catch (error) {
      console.error('Failed to switch shop:', error);
      message.error('切换店铺失败，请重试');
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="shops-container">
      <Title level={2}>Hi, {user.username}</Title>
      <Title level={3} style={{ fontSize: 20 }}>请选择店铺</Title>
      <Row gutter={[16, 16]}>
        {user.shops && user.shops.map((shop: Shop) => (
          <Col key={shop.shopId} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              onClick={() => handleShopSelect(shop)}
              className={shop.selected === 1 ? 'selected-shop' : ''}
            >
              <Card.Meta
                avatar={<Avatar src={shop.shopAvatar} size={64} />}
                title={shop.shopName}
              />
            </Card>
          </Col>
        ))}
      </Row>
      {selectedShop && (
        <div>
          <Text strong className="current-shop">
            当前选择店铺：{selectedShop.shopName}
          </Text>
          <Button type="primary" style={{ marginTop: 20 }} onClick={() => navigate('/dashboard')}>
            进入系统
          </Button>
        </div>
      )}
    </div>
  );
};

export default Shops;
