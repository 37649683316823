const Ticket: React.FC = () => {

  return (
    <div className="ticket-container">
      
    </div>
  );
};

export default Ticket;
