import axios from 'axios';
import { message } from 'antd';

const api = axios.create({
  baseURL: '/api', // 基础 URL
  timeout: 60000, // 请求超时
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    const token = document.cookie.split('; ').find(row => row.startsWith('live_polit_token='));
    if (token) {
      config.headers['token'] = `${token.split('=')[1]}`; // 添加 token
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response:any) => {
    // 如果响应数据是 Blob 类型，直接返回响应
    if (response.data instanceof Blob) {
      return response;
    }
    if (response.data.code === 10401) {
      // 清除 token
      message.error(response.data.message);
      document.cookie = 'live_polit_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      // 重定向到登录页面
      setTimeout(() => {
        window.location.href = '/#/login';
      }, 1000);
      return
    }
    if (response.data.code !== 1) {
      // 显示错误消息
      message.error(response.data.message);
      return response;
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // 处理未授权的情况
      window.location.href = '/#/login'; // 重定向到登录页面
    }
    return Promise.reject(error);
  }
);

export default api;