import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Select, Button, Space, Radio, message, Upload } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DownloadOutlined, UploadOutlined, ExportOutlined } from '@ant-design/icons';
import { createStyles } from 'antd-style';
import api from '../../../utils/api';
import './TargetReview.sass';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

const useStyle = createStyles(({ css, token }: any) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});
interface Account {
  id: number;
  name: string;
}
interface SalesPromotion {
  id: string;
  name: string;
  start: string;
  end: string;
}
interface TargetReviewData {
  id: string;
  date: string;
  shopName: string;
  planConsumption: number;
  actualConsumption: number;
  consumptionGap: number;
  targetROI: number;
  actualROI: number;
  targetSales: number;
  actualSales: number;
  salesGap: number;
  conversionRate: string;
  unitPrice: number;
}


const TargetReview: React.FC = () => {
  const [data, setData] = useState<TargetReviewData[]>([]);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs()
  ]);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [timeQuickValue, setTimeQuickValue] = useState('7');
  const [salesPromotions, setSalesPromotions] = useState<SalesPromotion[]>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(null);
  const isMounted = useRef(false);
  const fetchedRef = useRef(false);
  const fetchControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    isMounted.current = true;
    if (!fetchedRef.current) {
      fetchAccounts();
      fetchData();
      fetchSalesPromotions()
      fetchedRef.current = true;
    }
    return () => {
      isMounted.current = false;
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts/shop');
      if (response.data.code === 1) {
        setAccounts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedAccount(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const fetchData = async (page = currentPage, size = pageSize) => {
    setLoading(true);
    try {
      const response = await api.get(`/shopdaydata/page/${page}/size/${size}`, {
        params: {
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
          accountId: selectedAccount,
        },
      });
      setData(response.data.data.records);
      setTotal(response.data.data.total);
      setCurrentPage(response.data.data.current);
      setPageSize(response.data.data.size);
    } catch (error) {
      console.error('Failed to fetch target review data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSalesPromotions = async () => {
    try {
      const response = await api.get('/salespromotion');
      if (response.data.code === 1) {
        setSalesPromotions(response.data.data);
      }
    } catch (error) {
      console.error('Failed to fetch sales promotions:', error);
    }
  };

  const onAccountChange = (value: number) => {
    setSelectedAccount(value);
  };

  const onTimeQuickChange = (e: any) => {
    const value = e.target.value;
    setTimeQuickValue(value);
    const endDate = dayjs();
    let startDate;
    
    switch (value) {
      case '7':
        startDate = dayjs().subtract(7, 'day');
        break;
      case '30':
        startDate = dayjs().subtract(30, 'day');
        break;
      case 'week':
        startDate = dayjs().startOf('week');
        break;
      case 'month':
        startDate = dayjs().startOf('month');
        break;
      default:
        startDate = dayjs().subtract(7, 'day');
    }
    
    setDateRange([startDate, endDate]);
    setSelectedPromotion(null);
  };

  const onSaleChange = (value: string) => {
    const selectedPromo = salesPromotions.find(promo => promo.id === value);
    if (selectedPromo) {
      setSelectedPromotion(value);
      setDateRange([dayjs(selectedPromo.start), dayjs(selectedPromo.end)]);
      setTimeQuickValue('');
    }
  };

  const exportData = async () => {
    try {
      const response = await api.post('/shopdaydata/export', {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
        accountId: selectedAccount
      }, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '目标文件.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('导出成功');
    } catch (error) {
      console.error('Failed to export data:', error);
      message.error('导出失败');
    }
  };

  const downloadTemplate = async () => {
    try {
      const response = await api.get('/shopdaydata/template', {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '目标模板.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('模板下载成功');
    } catch (error) {
      console.error('Failed to download template:', error);
      message.error('模板下载失败');
    }
  };

  const importPerformanceTarget = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/shopdaydata/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.code === 1) {
        message.success('导入绩效目标成功');
        fetchData(); // 重新加载数据
      }
    } catch (error) {
      console.error('Failed to import performance target:', error);
      message.error('导入绩效目标失败');
    }
  };

  const columns: ColumnsType<TargetReviewData> = [
    {
      title: '日期',
      dataIndex: 'dayDate',
      key: 'dayDate',
      fixed: 'left',
      width: 120
    },
    {
      title: '抖音号',
      dataIndex: 'dyAccountName',
      key: 'dyAccountName'
    },
    {
      title: '千川预估消耗(元)',
      dataIndex: 'qcEstimateConsumption',
      key: 'qcEstimateConsumption'
    },
    {
      title: '千川实际消耗（元）',
      dataIndex: 'qcRealConsumption',
      key: 'qcRealConsumption'
    },
    {
      title: '消耗GAP(元)',
      dataIndex: 'consumptionGap',
      key: 'consumptionGap'
    },
    {
      title: '目标ROI',
      dataIndex: 'targetRoi',
      key: 'targetRoi'
    },
    {
      title: '实际ROI',
      dataIndex: 'realRoi',
      key: 'realRoi'
    },
    {
      title: '目标销售额(元)',
      dataIndex: 'targetSalesAmount',
      key: 'targetSalesAmount'
    },
    {
      title: '实际销售额(元)',
      dataIndex: 'realSalesAmoun',
      key: 'realSalesAmoun'
    },
    {
      title: '销售GAP(元)',
      dataIndex: 'salesGap',
      key: 'salesGap'
    },
    {
      title: '当日完成率',
      dataIndex: 'dayComplementRate',
      key: 'dayComplementRate',
      render: (text: string) => `${text}%`
    }
  ];

  const { styles } = useStyle();

  return (
    <div className="target-review">
      <div className="filters" style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: 15}}>
          <div style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
            <span style={{marginRight: 5, minWidth: '50px'}}>抖音号</span>
            <Select 
              placeholder="请选择" 
              style={{ minWidth: 150 }} 
              value={selectedAccount}
              onChange={onAccountChange}
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.name}</Option>
              ))}
            </Select>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
            <span style={{minWidth: '35px'}}>时间</span>
            <RangePicker 
              value={dateRange}
              style={{width: '230px', minWidth: '230px'}}
              onChange={(dates) => {
                if (dates) {
                  setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
                  setTimeQuickValue('');
                }
              }} 
            />
          </div>
          <Radio.Group 
            options={[
              { label: '近7天', value: '7' },
              { label: '近30天', value: '30' },
              { label: '自然周', value: 'week' },
              { label: '自然月', value: 'month' },
            ]} 
            onChange={onTimeQuickChange} 
            value={timeQuickValue} 
            optionType="button" 
            buttonStyle="solid"
            style={{marginRight: 16}}
          />
          <Select
            style={{ width: 120, marginRight: 16 }}
            placeholder="大促"
            value={selectedPromotion}
            onChange={onSaleChange}
            options={salesPromotions.map(promotion => ({
              value: promotion.id,
              label: promotion.name
            }))}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
          <Button type="primary" onClick={() => fetchData(1, pageSize)} style={{marginRight: 8, marginBottom: 8}}>
            查询
          </Button>
          <Button icon={<ExportOutlined />} style={{marginRight: 8, marginBottom: 8}} onClick={exportData}>导出</Button>
          <Button icon={<DownloadOutlined />} style={{marginRight: 8, marginBottom: 8}} onClick={downloadTemplate}>下载目标模板</Button>
          <Upload
            accept=".xlsx,.xls"
            showUploadList={false}
            beforeUpload={(file) => {
              importPerformanceTarget(file);
              return false;
            }}
          >
            <Button type="primary" icon={<UploadOutlined />} style={{marginBottom: 8}}>导入绩效目标</Button>
          </Upload>
        </div>
      </div>
      <Table<TargetReviewData>
          bordered
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="id"
          className={styles.customTable}
          scroll={{ x: 'max-content' }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: (page, pageSize) => fetchData(page, pageSize),
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条`,
        }}
      />
    </div>
  );
};

export default TargetReview;
